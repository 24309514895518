import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {TABLE} from "../common/model/tables";
import {BookingDetails, Contract} from "../common/model/model";

@Injectable()
export class ContractService {

    constructor(private supabaseService: SupabaseService) {
    }

    public async generateContract(bookingDetails: BookingDetails, paymentInstructions: string,
                                  additionalInformation: string, termsAndConditions: string) {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.CONTRACTS)
            .insert({
                booking_id: bookingDetails.booking.id,
                agency_id: bookingDetails.booking.agency_id,
                contract_number: bookingDetails.booking.event_name,
                days_to_expire: 30,
                payment_instructions: paymentInstructions,
                additional_information: additionalInformation,
                terms_conditions: termsAndConditions,
                signature_agency: 'Signature agency',
                signature_customer: 'Signature customer',
                status: 'DRAFT'
            });

        if (error) {
            throw new Error("Failed to generate contract, because: " + error.message);
        }

        return data;
    }

    public async getContract(retrievedToken: string, retrievedEmail: string) {
        const { data, error } = await this.supabaseService.supabaseClient.functions.invoke('contract-function', {
            body: {
                action: 'get_contract',
                token: retrievedToken,
                email: retrievedEmail
            }
        });

        if (error) {
            throw new Error("Failed to retrieve contract, because: " + error.message);
        }

        return data;
    }

    public async saveContract(contract: Contract) {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.CONTRACTS)
            .insert(contract)
            .select('*');

        if (error) {
            throw new Error("Failed to save contract, because: " + error.message);
        }

        return data;
    }

    public async updateContract(contract: Contract) {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.CONTRACTS)
            .update(contract)
            .eq('id', contract.id)
            .select('*');

        if (error) {
            throw new Error("Failed to update contract, because: " + error.message);
        }

        return data;
    }
}
