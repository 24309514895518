<mat-card class="mb-25 phobos-card all-projects-box">
    <mat-card-header>
        <h5 class="mb-0">Upcoming Bookings</h5>
        <button type="button" mat-button class="position-relative btn bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
            Status
        </button>
        <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
            <button mat-menu-item type="button" class="bg-transparent border-none">Advancing</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">Option</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">Confirmed</button>
        </mat-menu>
    </mat-card-header>
    <mat-card-content>
        <div class="all-projects-table table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th class="fw-semibold main-color">Event Name</th>
                    <th class="fw-semibold main-color">Event Date</th>
                    <th class="fw-semibold main-color">Status</th>
                    <th class="fw-semibold main-color">Location</th>
                    <th class="fw-semibold main-color">Cost</th>
                    <th class="fw-semibold main-color">Performers</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><h4 class="mb-0"><a >KLM Company Event</a></h4></td>
                    <td>Saturday, 08 June 2025</td>
                    <td class="gray-color">
                        <span class="badge confirmed fw-medium">CONFIRMED</span>
                    </td>
                    <td>Beurs van Berlage</td>
                    <td>$14,000</td>
                    <td class="members-list">
                        <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                        <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                    </td>
                </tr>
                <tr>
                    <td><h4 class="mb-0"><a >Q-Dance</a></h4></td>
                    <td>Friday, 12 August 2025</td>
                    <td class="gray-color">
                        <span class="badge advancing fw-medium">ADVANCING</span>
                    </td>
                    <td>Amsterdamse Bos</td>
                    <td>$34.3245</td>
                    <td class="members-list">
                        <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                        <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                    </td>
                </tr>
                <tr>
                    <td><h4 class="mb-0"><a >Shockers RAI</a></h4></td>
                    <td>Friday, 12 September 2025</td>
                    <td>
                        <span class="badge option fw-medium">OPTION</span>
                    </td>
                    <td>Amsterdam RAI</td>
                    <td class="gray-color">$64,000</td>
                    <td class="members-list">
                        <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                        <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                        <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                    </td>
                </tr>
                <tr>
                    <td><h4 class="mb-0"><a >Shell Company Event</a></h4></td>
                    <td>Thursday, 23 September 2025</td>
                    <td>
                        <span class="badge advancing fw-medium">ADVANCING</span>
                    </td>
                    <td>Hotel Oranje</td>
                    <td class="gray-color">$614,000</td>
                    <td class="members-list">
                        <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                        <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                        <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                    </td>
                </tr>
                <tr>
                    <td><h4 class="mb-0"><a >Private Event Paradiso</a></h4></td>
                    <td>Sunday, 3 October 2025</td>
                    <td>
                        <span class="badge confirmed fw-medium">CONFIRMED</span>
                    </td>
                    <td>Paradiso</td>
                    <td class="gray-color">$4,000</td>
                    <td class="members-list">
                        <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                        <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                        <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                    </td>
                </tr>
                <tr>
                    <td><h4 class="mb-0"><a >Private Event Melkweg</a></h4></td>
                    <td>Thursday, 23 September 2025</td>
                    <td>
                        <span class="badge option fw-medium">OPTION</span>
                    </td>
                    <td>Melkweg</td>
                    <td class="gray-color">$4,000</td>
                    <td class="members-list">
                        <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                        <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                        <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>
