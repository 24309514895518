<header
    class="header-area bg-white border-bottom-radius box-shadow mb-25"
    [ngClass]="{'active': isToggled, 'sticky': isSticky}"
    [class.component-dark-theme]="themeService.isDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
    [class.dark-header]="themeService.isHeaderDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-md-flex align-items-center justify-content-space-between">
        <div class="header-left-side d-flex align-items-center">
            <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
            <form class="search-box position-relative">
                <form class="search-box position-relative">
                    <input type="text" class="input-search d-block" placeholder="Search for events, performers, locations.." (input)="onSearchChange($event)">
<!--                    <button type="submit"><i class="ri-search-line"></i></button>-->
                </form>
<!--                <button type="submit"><i class="ri-search-line"></i></button>-->
            </form>
        </div>
        <ul class="header-right-side d-flex align-items-center mt-0 mb-0">
            <li>
                <button mat-icon-button [matMenuTriggerFor]="currencyMenu" class="btn p-0 border-none rounded-circle position-relative">
                    <img *ngIf="selectedCurrency" [src]="selectedCurrency.image" [alt]="selectedCurrency.description">

                </button>
                <mat-menu class="language-menu-dropdown" #currencyMenu="matMenu" xPosition="after">
                    <ul class="m-0">
                        <li *ngFor="let currency of currencies">
                            <button type="button" class="d-flex align-items-center border-none" (click)="onSelect(currency)">
                                <img [src]="currency.image" [alt]="currency.description">
                                {{ currency.description }}
                            </button>
                        </li>
                    </ul>
                </mat-menu>
            </li>
            <li>
                <button mat-icon-button [matMenuTriggerFor]="languageMenu" class="btn p-0 border-none rounded-circle position-relative">
                    <img src="assets/img/flag/usa.png" alt="usa">
                </button>
                <mat-menu class="language-menu-dropdown" #languageMenu="matMenu" xPosition="after">
                    <ul class="m-0">
                        <li>
                            <button type="button" class="d-flex align-items-center border-none">
                                <img src="assets/img/flag/usa.png" alt="usa">
                                English
                            </button>
                        </li>
                        <li>
                            <button type="button" class="d-fle align-items-center border-none">
                                <img src="assets/img/flag/spain.png" alt="spain">
                                Spanish
                            </button>
                        </li>
                        <li>
                            <button type="button" class="d-fle align-items-center border-none">
                                <img src="assets/img/flag/germany.png" alt="germany">
                                German
                            </button>
                        </li>
                    </ul>
                </mat-menu>
            </li>
            <li *ngIf="isPerformer">
                <button mat-icon-button [matMenuTriggerFor]="notificationMenu" class="btn p-0 border-none rounded-circle position-relative"
                        [class.notification-btn]="invitations.length > 0">
                    <i class="flaticon-bell-2"></i>
                </button>
                <mat-menu class="menu-dropdown" #notificationMenu="matMenu" xPosition="before">
                    <div class="menu-header d-flex align-items-center justify-content-space-between">
                        <h5 class="m-0">Notifications</h5>
                    </div>
                    <ul class="menu-body">
                        <li class="position-relative" *ngFor="let invitation of invitations">
                            <div class="d-flex">
                                <i class="flaticon-user-1 icon"></i>
                                <div class="title">
                                    <h6>Invitation from {{ invitation.agency_name }}</h6>
                                    <span><i class="flaticon-timer"></i> {{ invitation.agency_email }}</span>
                                </div>
                            </div>
                            <a routerLink="/invitations" class="link-btn"></a>
                        </li>
                    </ul>
                    <div class="menu-footer">
                        <a routerLink="/invitations" class="fw-medium position-relative">View All <i class="flaticon-chevron"></i></a>
                    </div>
                </mat-menu>
            </li>
            <li class="d-none d-lg-block">
                <div class="date-btn main-color fw-semibold position-relative">
                    <i class="flaticon-calendar"></i> {{ formattedDate }}
                </div>
            </li>
            <li>
                <button mat-icon-button [matMenuTriggerFor]="profileMenu" class="profile-btn main-color border-none bg-transparent position-relative">
                    <img src="assets/img/admin.jpg" class="rounded-circle" alt="admin">
                    {{currentUser.email}}
                    <span class="fw-semibold">{{ currentUser.user_metadata.name }}</span>
                </button>
                <mat-menu class="profile-menu-dropdown" #profileMenu="matMenu" xPosition="before">
                    <ul class="m-0">
                        <li>
                            <a routerLink="/profile">
                                <i class="ri-user-3-line"></i> Profile
                            </a>
                        </li>
                        <li>
                            <a routerLink="/account">
                                <i class="ri-settings-3-line"></i> Settings
                            </a>
                        </li>
                        <li>
                            <a (click)="logout()">
                                <i class="ri-shut-down-line"></i> Logout
                            </a>
                        </li>
                    </ul>
                </mat-menu>
            </li>
        </ul>
    </div>
</header>
