import {Injectable} from "@angular/core";
import {
    Agency,
    Booking,
    Booking_Attachment,
    Booking_Contact, Booking_Guest,
    Booking_Performer, Booking_Performer_Flight, Booking_Performer_Hotel,
    Booking_Product, BookingDetails, BookingSummary
} from "../common/model/model";
import {SupabaseService} from "./supabase.service";
import {TABLE} from "../common/model/tables";

@Injectable({
    providedIn: 'root'
})
export class BookingService {

    public bookingAttachments: Booking_Attachment[] = [];

    constructor(private supabaseService: SupabaseService) {
    }

    public async testBooking() {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKINGS)
            .select('*');

        if (error) {
            throw new Error("Failed to retrieve bookings, because: " + error.message);
        }

        return data;
    }

    public async testLocations() {

        const { data, error } = await this.supabaseService.supabaseClient
            .from('bookings')
            .select(`
                id,
                location:locations(*),
                customer:customers(*)
            `)
            .eq('id', 'da8d74d5-8811-45a5-b626-7a55a15c4862')
            .single();

        if (error) {
            throw new Error("Failed to retrieve locations, because: " + error.message);
        }

        return data;
    }

    public async testHotels() {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PERFORMER_HOTELS)
            .select('*');

        if (error) {
            throw new Error("Failed to retrieve hotels, because: " + error.message);
        }

        return data;
    }

    public async testFlights() {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PERFORMER_FLIGHTS)
            .select('*');

        if (error) {
            throw new Error("Failed to retrieve hotels, because: " + error.message);
        }

        return data;
    }

    public async getSingleBookingForPerformer(bookingId: string) {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKINGS)
            .select('*')
            .eq('id', bookingId);

        if (error) {
            throw new Error("Failed to retrieve bookings, because: " + error.message);
        }

        return data;
    }

    public async getBookingsForPerformer() {
        const { data, error } = await this.supabaseService.supabaseClient
            .rpc('get_performer_bookings');

        if (error) {
            throw new Error("Failed to retrieve bookings for Performer, because: " + error.message);
        }

        return data;
    }


    public async getAllBookings(agency: Agency): Promise<Booking[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKINGS)
            .select('*')
            .eq('agency_id', agency.id);

        if(error) {
            throw new Error("Failed to retrieve bookings, because: " + error.message);
        }
        return data as Booking[];
    }

    public async getAllBookingDetails(agency: Agency): Promise<BookingDetails[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .rpc('get_bookings_with_details', { p_agency_id: agency.id});

        if (error) {
            console.error('Error fetching booking details:', error.message);
            throw error;
        }

        return data.map((item: any) => ({
            booking: item.booking,
            contacts: item.contacts || [],
            attachments: item.attachments || [],
            performers: item.performers || [],
            performer_details: item.performer_details || [],
            location: item.location,
            customer: item.customer,
            booking_performer_flights: item.booking_performer_flights || [],
            booking_performer_hotels: item.booking_performer_hotels || []
        }));
    }

    public async addBooking(booking: Booking, bookingPerformers: Booking_Performer[],
                            bookingContacts: Booking_Contact[],
                            bookingProducts: Booking_Product[],
                            bookingPerformerFlights: Booking_Performer_Flight[],
                            bookingPerformerHotel: Booking_Performer_Hotel[],
                            bookingGuest: Booking_Guest[]): Promise<Booking[]> {
        const {data, error } = await this.supabaseService.supabaseClient.rpc('add_booking', {
            booking: booking,
            booking_contacts: bookingContacts,
            booking_performers: bookingPerformers,
            booking_products: bookingProducts,
            booking_performer_flights: bookingPerformerFlights,
            booking_performer_hotels: bookingPerformerHotel,
            booking_guests: bookingGuest
        });

        if(error) {
            console.log(error.message);
            throw new Error("Failed to add booking, because: " + error.message);
        }
        return data as Booking[];
    }

    public async updateBooking(booking: Booking) {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKINGS)
            .update(booking)
            .eq('id', booking.id)
            .select('*');

        if (error) {
            throw new Error("Failed to update booking, because: " + error.message);
        }

        return data;
    }

    public async getSingleBooking(bookingId: string): Promise<BookingDetails> {
        const { data, error } = await this.supabaseService.supabaseClient
            .rpc('get_single_booking_details', { p_booking_id: bookingId });

        if (error) {
            throw new Error("Failed to retrieve single booking details, because: " + error.message);
        }

        return data[0] as BookingDetails;
    }

    public async uploadBookingAttachments(
        files: File[],
        bookingOrBookings: Booking | Booking[],
        agencyOrId: Agency | string
    ): Promise<Booking_Attachment[]> {
        const agencyId = typeof agencyOrId === 'string' ? agencyOrId : agencyOrId.id;
        const bookings = Array.isArray(bookingOrBookings) ? bookingOrBookings : [bookingOrBookings];

        const bookingAttachments = await Promise.all(files.map(async file => {
            let filePath = `${agencyId}/${bookings[0].id}/${file.name}`;
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop();
            const baseName = fileName.substring(0, fileName.lastIndexOf('.'));

            // Check if the file exists and modify the name if necessary
            let counter = 1;
            let uniqueFilePath = filePath;

            while (true) {
                const { data, error } = await this.supabaseService.supabaseClient
                    .storage
                    .from(TABLE.BOOKING_ATTACHMENTS)
                    .list(`${agencyId}/${bookings[0].id}`, { search: fileName });

                if (error) {
                    throw new Error("Failed to check existing files, because: " + error.message);
                }

                const fileExists = data.some(existingFile => existingFile.name === uniqueFilePath.split('/').pop());
                if (!fileExists) break;

                uniqueFilePath = `${agencyId}/${bookings[0].id}/${baseName}_${counter}.${fileExtension}`;
                counter++;
            }

            const { data, error } = await this.supabaseService.supabaseClient
                .storage
                .from(TABLE.BOOKING_ATTACHMENTS)
                .upload(uniqueFilePath, file, { upsert: false });

            if (error) {
                throw new Error("Failed to upload file, because: " + error.message);
            }

            // Create a Booking_Attachments object with the URL of the uploaded file
            const bookingAttachment: Booking_Attachment = {
                file_key: data.path,
                booking_id: bookings[0].id,
                file_name: file.name,
                mime_type: file.type,
                uploaded_date: new Date()
            };
            return bookingAttachment;
        }));

        this.bookingAttachments.push(...bookingAttachments);
        return this.bookingAttachments;
    }

    public async deleteBookingAttachment(attachment: Booking_Attachment): Promise<boolean> {
        if (!attachment.file_key) {
            throw new Error("File key is missing from the attachment object");
        }

        // Step 1: Delete the file from Supabase storage
        const { data, error } = await this.supabaseService.supabaseClient
            .storage
            .from(TABLE.BOOKING_ATTACHMENTS)
            .remove([attachment.file_key]);

        if (error) {
            throw new Error(`Failed to delete file from storage: ${error.message}`);
        }

        // Step 2: Remove the attachment from the local array
        const index = this.bookingAttachments.findIndex(a => a.file_key === attachment.file_key);
        if (index !== -1) {
            this.bookingAttachments.splice(index, 1);
        }

        // Step 3: Delete the attachment record from the database
        if (attachment.id) {
            const { error: dbError } = await this.supabaseService.supabaseClient
                .from(TABLE.BOOKING_ATTACHMENTS)
                .delete()
                .eq('id', attachment.id);

            if (dbError) {
                throw new Error(`Failed to delete attachment record from database: ${dbError.message}`);
            }
        } else {
            throw new Error('No attachment id provided. Skipping database record deletion.');
        }

        return true;
    }

    public async insertBookingAttachment(bookingAttachments: Booking_Attachment[]): Promise<boolean>{
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_ATTACHMENTS)
            .insert(bookingAttachments);

        if (error) {
            throw new Error("Failed to insert booking attachments, because: " + error.message);
        }
        return true;
    }

    public async getPerformerBookingStatuses() {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PERFORMERS_STATUSES)
            .select('*');

        if (error) {
            throw new Error("Failed to retrieve booking performer statuses, because: " + error.message);
        }

        return data.map((row: { status: string }) => row.status);
    }

    public async getPerformerBookings(performerId: string | undefined) {
        const { data, error } = await this.supabaseService.supabaseClient
            .rpc('get_bookings_by_performer', { retrieved_performer_id: performerId });

        if (error) {
            throw new Error("Failed to retrieve performer bookings, because: " + error.message);
        }

        return data as BookingSummary[];
    }

    public async addBookingProduct(bookingProduct: Booking_Product): Promise<Booking_Product> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PRODUCTS)
            .insert(bookingProduct)
            .single();

        if (error) {
            throw new Error("Failed to add booking product, because: " + error.message);
        }

        return data as Booking_Product;
    }

    public async deleteBookingProduct(bookingProduct: Booking_Product): Promise<boolean> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PRODUCTS)
            .delete()
            .eq('id', bookingProduct.id);

        if (error) {
            throw new Error("Failed to delete booking product, because: " + error.message);
        }

        return true;
    }

    public async getBookingProducts(bookingId: string): Promise<Booking_Product[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PRODUCTS)
            .select('*')
            .eq('booking_id', bookingId);

        if (error) {
            throw new Error("Failed to retrieve booking products, because: " + error.message);
        }

        return data as Booking_Product[];
    }

    public async deleteBookingProductPerformer(bookingPerformer: Booking_Performer): Promise<boolean> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PRODUCTS)
            .delete()
            .eq('performer_id', bookingPerformer.performer_id);

        if (error) {
            throw new Error("Failed to delete booking product, because: " + error.message);
        }
        return true;
    }

    public async updateBookingProduct(bookingProduct: Booking_Product):Promise<Booking_Product[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PRODUCTS)
            .update(bookingProduct)
            .eq('id', bookingProduct.id)
            .select("*");

        if (error) {
            throw new Error("Failed to update booking product, because: " + error.message);
        }

        return data as Booking_Product[];
    }

    public async addBookingGuest(bookingGuest: Booking_Guest): Promise<Booking_Guest[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_GUESTS)
            .insert(bookingGuest)
            .select("*");

        if (error) {
            throw new Error("Failed to add booking guest, because: " + error.message);
        }

        return data as Booking_Guest[];
    }

    public async deleteBookingGuest(bookingGuest: Booking_Guest): Promise<boolean> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_GUESTS)
            .delete()
            .eq('id', bookingGuest.id);

        if (error) {
            throw new Error("Failed to delete booking guest, because: " + error.message);
        }

        return true;
    }

    public async updateBookingGuest(bookingGuest: Booking_Guest): Promise<Booking_Guest[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_GUESTS)
            .update(bookingGuest)
            .eq('id', bookingGuest.id)
            .select("*");

        if (error) {
            throw new Error("Failed to update booking guest, because: " + error.message);
        }

        return data as Booking_Guest[];
    }

    public async addBookingContact(bookingContact: Booking_Contact): Promise<Booking_Contact[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_CONTACTS)
            .insert(bookingContact)
            .select("*");

        if (error) {
            throw new Error("Failed to add booking contact, because: " + error.message);
        }

        return data as Booking_Contact[];
    }

    public async updateBookingContact(bookingContact: Booking_Contact): Promise<Booking_Contact[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_CONTACTS)
            .update(bookingContact)
            .eq('id', bookingContact.id)
            .select("*");

        if (error) {
            throw new Error("Failed to update booking contact, because: " + error.message);
        }

        return data as Booking_Contact[];
    }

    public async deleteBookingContact(bookingContact: Booking_Contact): Promise<boolean> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_CONTACTS)
            .delete()
            .eq('id', bookingContact.id);

        if (error) {
            throw new Error("Failed to delete booking contact, because: " + error.message);
        }

        return true;
    }

    public async addBookingPerformerFlight(bookingPerformerFlight: Booking_Performer_Flight): Promise<Booking_Performer_Flight[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PERFORMER_FLIGHTS)
            .insert(bookingPerformerFlight)
            .select("*");

        if (error) {
            throw new Error("Failed to add booking performer flight, because: " + error.message);
        }
        return data as Booking_Performer_Flight[];
    }

    public async updateBookingPerformerFlight(bookingPerformerFlight: Booking_Performer_Flight): Promise<Booking_Performer_Flight[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PERFORMER_FLIGHTS)
            .update(bookingPerformerFlight)
            .eq('id', bookingPerformerFlight.id)
            .select("*");

        if (error) {
            throw new Error("Failed to update booking performer flight, because: " + error.message);
        }

        return data as Booking_Performer_Flight[];
    }

    public async deleteBookingPerformerFlight(bookingPerformerFlight: Booking_Performer_Flight): Promise<boolean> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PERFORMER_FLIGHTS)
            .delete()
            .eq('id', bookingPerformerFlight.id);

        if (error) {
            throw new Error("Failed to delete booking performer flight, because: " + error.message);
        }

        return true;
    }

    public async addBookingPerformerHotel(bookingPerformerHotel: Booking_Performer_Hotel): Promise<Booking_Performer_Hotel[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PERFORMER_HOTELS)
            .insert(bookingPerformerHotel)
            .select("*");

        if (error) {
            throw new Error("Failed to add booking performer hotel, because: " + error.message);
        }

        return data as Booking_Performer_Hotel[];
    }

    public async updateBookingPerformerHotel(bookingPerformerHotel: Booking_Performer_Hotel): Promise<Booking_Performer_Hotel[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PERFORMER_HOTELS)
            .update(bookingPerformerHotel)
            .eq('id', bookingPerformerHotel.id)
            .select("*");

        if (error) {
            throw new Error("Failed to update booking performer hotel, because: " + error.message);
        }

        return data as Booking_Performer_Hotel[];
    }

    public async deleteBookingPerformerHotel(bookingPerformerHotel: Booking_Performer_Hotel): Promise<boolean> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PERFORMER_HOTELS)
            .delete()
            .eq('id', bookingPerformerHotel.id);

        if (error) {
            throw new Error("Failed to delete booking performer hotel, because: " + error.message);
        }

        return true;
    }

    public async addBookingPerformer(bookingPerformer: Booking_Performer): Promise<Booking_Performer[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PERFORMERS)
            .insert(bookingPerformer)
            .select("*");

        if (error) {
            throw new Error("Failed to add booking performer, because: " + error.message);
        }

        return data as Booking_Performer[];
    }

    public async updateBookingPerformer(bookingPerformer: Booking_Performer): Promise<Booking_Performer[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PERFORMERS)
            .update(bookingPerformer)
            .eq('booking_id', bookingPerformer.booking_id)
            .eq('performer_id', bookingPerformer.performer_id)
            .select("*");

        if (error) {
            throw new Error("Failed to update booking performer, because: " + error.message);
        }

        return data as Booking_Performer[];
    }

    public async deleteBookingPerformer(bookingPerformer: Booking_Performer): Promise<boolean> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.BOOKING_PERFORMERS)
            .delete()
            .eq('booking_id', bookingPerformer.booking_id)
            .eq('performer_id', bookingPerformer.performer_id);

        if (error) {
            throw new Error("Failed to delete booking performer, because: " + error.message);
        }

        return true;
    }
}
