import {Injectable} from "@angular/core";
import {Booking_Financials, Booking_Performer, BookingDetails, BumaFee, Performer} from "../common/model/model";

@Injectable()
export class FinanceService {
    constructor() {
    }

    public calculateBookingFinancialTotals(bookingDetails: BookingDetails,
                                           combinedPerformers: (Booking_Performer & Partial<Performer>)[]): Booking_Financials {
        let financialsTotal: number = 0;
        let financialsTotalWithVAT: number = 0;
        let financialsVATRate: number = 21;
        let actualVATAmount = 0;
        let bumaFees: BumaFee[] = [];

        let financials: Booking_Financials = {
            financialsTotal: 0,
            financialsTotalWithVAT: 0,
            financialsVATRate: financialsVATRate,
            actualVATAmount: 0,
            bumaFees: []
        };

        if(bookingDetails && bookingDetails.products) {

            //Calculate the total price of the products
            bookingDetails.products.forEach(product => {
                financialsTotal += product.price;
            });

            combinedPerformers.forEach(performer => {
                if(performer.price) {
                    if(performer.buma_fee) {
                        //calculate the buma fee
                        let bumaFee = performer.price * performer.buma_fee / 100;

                        if(performer.currency_code && performer.currency_symbol) {
                            let bumaFeeObject: BumaFee = {
                                bumaFee: bumaFee,
                                performerName: performer.name + ' - Buma Fee (7%)',
                                currencySymbol: performer.currency_symbol,
                                currencyCode: performer.currency_code
                            };
                            bumaFees.push(bumaFeeObject);
                        }

                        financialsTotal += bumaFee;
                    }
                    financialsTotal += performer.price;
                }
            });

            financialsTotalWithVAT = financialsTotal + (financialsTotal * financialsVATRate / 100);
            actualVATAmount = financialsTotal * financialsVATRate / 100;

            financials = {
                financialsTotal: financialsTotal,
                financialsTotalWithVAT: financialsTotalWithVAT,
                financialsVATRate: financialsVATRate,
                actualVATAmount: actualVATAmount,
                bumaFees: bumaFees
            };


        }
        return financials;
    }
}
