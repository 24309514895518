import {Component, Input, OnInit} from "@angular/core";
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";
import {CalendarOptions} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import multiMonthPlugin from "@fullcalendar/multimonth";
import {Observable, Subscription} from "rxjs";
import {BookingDetails, Performer_Booking_Details} from "../../../../common/model/model";
import {Router} from "@angular/router";
import tippy from "tippy.js";

@Component({
    selector: 'performer-bookings-calendar-component',
    templateUrl: './performer-bookings-calendar.component.html',
    styleUrls: ['./performer-bookings-calendar.component.scss']
})
export class PerformerBookingsCalendarComponent implements OnInit {

    @Input() bookingPerformerDetails: Observable<Performer_Booking_Details[]>;
    public performerBookingsDetailsSubscription: Subscription;

    public calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        events: [],
        plugins: [dayGridPlugin,interactionPlugin, timeGridPlugin, listPlugin, multiMonthPlugin],
        eventClick: this.handleEventClick.bind(this),
        eventDidMount: this.handleEventDidMount.bind(this)
    };

    constructor(public themeService: CustomizerSettingsService,
                private router: Router) {
    }

    ngOnInit() {
        this.subscribeToEvents();
    }

    public subscribeToEvents() {
        this.performerBookingsDetailsSubscription = this.bookingPerformerDetails
            .subscribe((bookings: Performer_Booking_Details[]) => {
                const events = bookings.map((detail: Performer_Booking_Details) => ({
                    id: detail.booking.id,
                    title: detail.booking.event_name,
                    name: detail.booking.event_name,
                    date: detail.booking.event_date,
                    extendedProps: {
                        booking: detail.booking,
                        location: detail.location,
                        customer: detail.customer
                    }
                    //textColor: 'black',
                }));
                //this.updateCalendarEvents(events);
                this.calendarOptions.events = events;
                this.calendarOptions = { ...this.calendarOptions, events };
            });
    }

    public handleEventDidMount(info: any) {
        tippy(info.el, {
            content: `<strong>Event: ${info.event.title}</strong>` +
                `<br>Location: ${info.event.extendedProps.location.name}` +
                `<br>Customer: ${info.event.extendedProps.customer.name}`,
            allowHTML: true
        });
    }

    handleEventClick(arg: any) {
        let performerBookingDetails: Performer_Booking_Details = arg.event.extendedProps as Performer_Booking_Details;
        this.router.navigate(['performer-booking-details', performerBookingDetails.booking.id]);
    }
}
