import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'contract-check-dialog',
    templateUrl: './contract-check-dialog.component.html',
    styleUrls: ['./contract-check-dialog.component.scss']
})
export class ContractCheckDialogComponent implements OnInit {

    public contractCheckFormGroup: FormGroup
    public contractCheckSubmitted = false;

    constructor(private contractCheckFormBuilder: FormBuilder,
                public dialogRef: MatDialogRef<ContractCheckDialogComponent>) {
        this.enableContractCheckForm();
    }

    ngOnInit() {

    }

    get contractCheckFormGroupControls() {
        return this.contractCheckFormGroup.controls;
    }

    public enableContractCheckForm() {
        this.contractCheckFormGroup = this.contractCheckFormBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    public close() {
        this.dialogRef.close(true);
    }

    public verifyEmail() {
        this.contractCheckSubmitted = true;

        if (this.contractCheckFormGroup.valid) {
            const email = this.contractCheckFormGroup.controls['email'].value;
            this.dialogRef.close(email);
        }
    }

    public onSubmit(event: Event) {
        event.preventDefault();  // Prevent default form submission
        this.verifyEmail();
    }
}
