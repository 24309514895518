<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Verify E-Mail</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <mat-card class="mb-25 create-project-card phobos-card">
        <form [formGroup]="contractCheckFormGroup">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">E-Mail Address</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': contractCheckFormGroupControls['email'].errors && contractCheckSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (contractCheckFormGroup.get('email')?.hasError('required') && contractCheckSubmitted) ? '#d73a49' : 'inherit'}">
                                    E-Mail Address
                                </mat-label>
                                <input matInput formControlName="email">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                    <div class="text-end">
                        <button type="button" mat-flat-button class="gray" (click)="close()">Cancel</button>
                        <button type="submit" mat-flat-button class="phobos" (click)="verifyEmail()">Verify E-Mail</button>
                    </div>
                </div>
            </div>
        </form>
    </mat-card>
</div>
