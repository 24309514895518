<mat-card
        class="mb-25 phobos-card"
        [class.component-dark-theme]="themeService.isDark()"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-content>
        <div class="mat-elevation-z8 phobos-mat-table">
            <div *ngIf="(bookingPerformerDetails | async)?.length === 0">
                There are no bookings yet
            </div>
        </div>
        <div class="mat-elevation-z8 phobos-mat-table">
            <table mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Booking ID</th>
                    <td mat-cell *matCellDef="let element" class="fw-semibold text-start">{{element.position}}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Name</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <a (click)="openPerformerBookingDetails(element)" class="d-flex align-items-center product-info cursor-pointer">
                            <img src="assets/img/icon/booking.png" alt="performer">
                            {{element.booking.event_name}}
                        </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let element">{{element.booking.event_date}}</td>
                </ng-container>

                <ng-container matColumnDef="performers">
                    <th mat-header-cell *matHeaderCellDef>Performers</th>
                    <td class="members-list" mat-cell *matCellDef="let element">
                        <img *ngFor="let performer of element.performer_details"
                             [src]="'assets/img/user/user22.jpg'"
                             class="rounded-circle"
                             matTooltip="{{performer.name}}"
                             [alt]="performer.name">
                    </td>
                </ng-container>

                <ng-container matColumnDef="customer">
                    <th mat-header-cell *matHeaderCellDef>Customer</th>
                    <td mat-cell *matCellDef="let element">{{element.customer.name}}</td>
                </ng-container>

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef>Location</th>
                    <td mat-cell *matCellDef="let element">{{element.location.name}}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge Draft" *ngIf="element.booking.status == 'DRAFT'">{{element.booking.status}}</span>
                        <span class="badge Optional" *ngIf="element.booking.status == 'OPTIONAL'">{{element.booking.status}}</span>
                        <span class="badge Confirmed" *ngIf="element.booking.status == 'CONFIRMED'">{{element.booking.status}}</span>
                        <span class="badge Pending" *ngIf="element.booking.status == 'PENDING'">{{element.booking.status}}</span>
                        <span class="badge Advancing" *ngIf="element.booking.status == 'ADVANCING'">{{element.booking.status}}</span>
                        <span class="badge Canceled" *ngIf="element.booking.status == 'CANCELED'">{{element.booking.status}}</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator
                    [pageSizeOptions]="[5, 10, 20]"
                    showFirstLastButtons
            >
            </mat-paginator>
        </div>

    </mat-card-content>
</mat-card>
