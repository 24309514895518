import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {CustomizerSettingsService} from "../../customizer-settings/customizer-settings.service";
import {NotificationService} from "../../services/notification.service";
import {Router} from "@angular/router";
import {Agency, Category, Performer, Performer_With_Categories} from "../../common/model/model";
import {PerformerService} from "../../services/performer.service";
import {AgencyService} from "../../services/agency.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {SearchService} from "../../services/search.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'performers-component',
    templateUrl: './performers.component.html',
    styleUrls: ['./performers.component.scss']
})
export class PerformersComponent implements OnInit, AfterViewInit, OnDestroy {


    public performersWithCategories: Performer_With_Categories[] = [];
    public selectedAgency: Agency;
    dataSource = new MatTableDataSource<Performer_With_Categories>(this.performersWithCategories);
    displayedColumns: string[] = ['name','categories','price', 'cost', 'playtimes', 'actions'];

    private searchSubscription: Subscription

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public themeService: CustomizerSettingsService,
                private notificationService: NotificationService,
                private performersService: PerformerService,
                private agencyService: AgencyService,
                private searchService: SearchService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.agencyService.selectedAgency$.subscribe(agency => {
            if(agency) {
                this.selectedAgency = agency;
                //this.getPerformers(agency);
                this.getPerformersWithCategories(agency);
            }
        });

        this.searchSubscription = this.searchService.searchObservable.subscribe(searchTerm => {
            this.applyFilter(searchTerm);
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy(): void {
        this.searchSubscription.unsubscribe();
    }

    public applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    public addPerformer() {
        this.agencyService.selectedAgency$.subscribe(agency => {
           if(agency) {
               this.router.navigate(['/addperformer']);
           } else {
               this.notificationService.openFailureNotitication("Please add an Agency first.");
           }
        });
    }

    public openPerformerDetails(performer: Performer_With_Categories) {
        this.router.navigate(['/performer-details', performer.performer.id]);
    }

    public getCategoryNames(categories: Category[]): string {
        return categories?.length ? categories.map(cat => cat.name).join(', ') : 'N/A';
    }

    public getPerformersWithCategories(agency: Agency) {
        this.performersService.getPerformerWithCategories(agency).then(performersWithCategories => {
            this.performersWithCategories = performersWithCategories;
            this.dataSource = new MatTableDataSource<Performer_With_Categories>(this.performersWithCategories);
        });
    }
}
