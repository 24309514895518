import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {File, FileTypes, Performer, PerformerFile} from "../../../../common/model/model";
import {PerformerService} from "../../../../services/performer.service";

@Component({
    selector: 'performerattachments-info-component',
    templateUrl: './performerattachments-info.component.html',
    styleUrls: ['./performerattachments-info.component.scss']

})
export class PerformerAttachmentsInfoComponent implements OnChanges{
    @Input() performer: Performer;
    public performerFiles: PerformerFile[] = [];
    public isLoading = false;

    constructor(private performerService: PerformerService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['performer'] && changes['performer'].currentValue) {
            this.getPerformerFiles();
        }
    }

    public getPerformerFiles() {
        Promise.all([
            this.isLoading = true,
            //Retrieve Rider
            this.performerService.getPerformerFile(this.performer.agency_id, this.performer.id, FileTypes.performer_rider).then(files => {
                let fileName = files[0];

                this.performerService.getPerformerFileMetadata(this.performer.agency_id, this.performer.id, FileTypes.performer_rider, fileName).then(metadata => {
                    let fileRider: PerformerFile = {
                        name: fileName,
                        type: metadata.metadata.mimetype,
                        size: metadata.metadata.size,
                        lastModified: metadata.metadata.lastModified,
                        performerFileType: "RIDER"
                    };
                    this.performerFiles.push(fileRider);
                });
            }),

            //Retrieve VAR
            this.performerService.getPerformerFile(this.performer.agency_id, this.performer.id, FileTypes.performer_var).then(files => {
                let fileName = files[0];

                this.performerService.getPerformerFileMetadata(this.performer.agency_id, this.performer.id, FileTypes.performer_var, fileName).then(metadata => {
                    let fileRider: PerformerFile = {
                        name: fileName,
                        type: metadata.metadata.mimetype,
                        size: metadata.metadata.size,
                        lastModified: metadata.metadata.lastModified,
                        performerFileType: "VAR"
                    };
                    this.performerFiles.push(fileRider);
                });
            }),

            this.performerService.getPerformerFile(this.performer.agency_id, this.performer.id, FileTypes.performer_presskit).then(files => {
                let fileName = files[0];

                this.performerService.getPerformerFileMetadata(this.performer.agency_id, this.performer.id, FileTypes.performer_presskit, fileName).then(metadata => {
                    let fileRider: PerformerFile = {
                        name: fileName,
                        type: metadata.metadata.mimetype,
                        size: metadata.metadata.size,
                        lastModified: metadata.metadata.lastModified,
                        performerFileType: "PressKit"
                    };
                    this.performerFiles.push(fileRider);
                });
            })
        ]).finally(() => {
            this.isLoading = false;
        });


    }
}
