import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {Agency} from "../../../common/model/model";
import {AgencyService} from "../../../services/agency.service";

@Component({
    selector: 'add-agency',
    templateUrl: './add-agency-dialog.component.html',
    styleUrls: ['./add-agency-dialog.component.scss']
})
export class AddAgencyDialogComponent implements OnInit {

    public addAgencyFormGroup: FormGroup;
    public addAgencySubmitted = false;
    public logo: File[] = [];

    constructor(public dialogref: MatDialogRef<AddAgencyDialogComponent>,
                private addAgencyFormBuilder: FormBuilder,
                private agencyService: AgencyService) {}

    ngOnInit() {
        this.enableAddAgencyDialogForm();
    }

    get addAgencyFormGroupControls() {
        return this.addAgencyFormGroup.controls;
    }

    public enableAddAgencyDialogForm() {
        this.addAgencyFormGroup = this.addAgencyFormBuilder.group({
            name: ['', Validators.required],
            address: ['', Validators.required],
            postcode: ['', Validators.required],
            city: ['', Validators.required],
            country: ['', Validators.required],
            phone: ['', Validators.required],
            website: ['', Validators.required],
            email: ['', Validators.required],
            logo: [null, Validators.required],
            bank_number: ['', Validators.required],
            bic_code: ['', Validators.required],
            chamber_of_commerce: ['', Validators.required],
            vat_number: ['', Validators.required],
        });
    }

    public close() {
        this.dialogref.close(true);
    }

    public onSelect(event:any) {
        this.logo = [];

        this.logo.push(event.addedFiles[0]);

        this.addAgencyFormGroup.controls['logo'].setValue(this.logo[0]);
    }

    public saveAgency() {
        this.addAgencySubmitted = true;
        if(this.addAgencyFormGroup.invalid) {
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(this.logo[0]);
            reader.onload = () => {
                if(reader.result) {
                    let agency: Agency = {
                        name: this.addAgencyFormGroup.controls['name'].value,
                        address: this.addAgencyFormGroup.controls['address'].value,
                        postcode: this.addAgencyFormGroup.controls['postcode'].value,
                        city: this.addAgencyFormGroup.controls['city'].value,
                        country: this.addAgencyFormGroup.controls['country'].value,
                        website: this.addAgencyFormGroup.controls['website'].value,
                        email: this.addAgencyFormGroup.controls['email'].value,
                        phone: this.addAgencyFormGroup.controls['phone'].value,
                        logo: reader.result.toString(),
                        bank_number: this.addAgencyFormGroup.controls['bank_number'].value,
                        bic_code: this.addAgencyFormGroup.controls['bic_code'].value,
                        chamber_of_commerce: this.addAgencyFormGroup.controls['chamber_of_commerce'].value,
                        vat_number: this.addAgencyFormGroup.controls['vat_number'].value,
                    }

                    this.agencyService.addAgency(agency).then(agency => {
                        this.dialogref.close(agency[0]);
                    }).catch(error => {
                        //TODO handle error if it doesn't go well
                        console.error('Failed to add agency:', error);
                    });
                }
            }
        }
    }
}
