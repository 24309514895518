import { Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { CustomizerSettingsService } from '../app/components/customizer-settings/customizer-settings.service';
import { ToggleService } from '../app/components/common/header/toggle.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    title = 'SIGHTGIG';

    isToggled = false;

    constructor(
        public router: Router,
        private toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        private activatedRoute: ActivatedRoute
    ) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    toggleRightSidebarTheme() {
        this.themeService.toggleRightSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    showSidebarHeader(): boolean {
        const currentRoute = this.router.url;
        const routesWithoutSidebar = ['/', '/login', '/register', '/forgot-password', '/confirm-email', '/contract'];

        // Check if the current route is '/register' with a token parameter
        if (currentRoute.startsWith('/register')) {
            const token = this.activatedRoute.snapshot.queryParamMap.get('token');
            if (token) {
                return false;
            }
        }
        // Check specifically for the 'contract' route with a dynamic parameter
        if (currentRoute.startsWith('/contract/')) {
            return false;
        }

        return !routesWithoutSidebar.includes(currentRoute);
    }
}
