import {Component, OnInit} from "@angular/core";
import {Performer_Booking_Details} from "../../../../common/model/model";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'performer-booking-details',
    templateUrl: './performer-booking-details.html',
    styleUrls: ['./performer-booking-details.scss']
})
export class PerformerBookingDetails implements OnInit {
    public selectedTabIndex = 0;

    public performerBookingDetails: Performer_Booking_Details;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router) {

        const navigation = this.router.getCurrentNavigation();
        if (navigation?.extras.state) {
            this.performerBookingDetails = navigation.extras.state['performerBookingDetails'] as Performer_Booking_Details;
            //console.log('performerBookingDetails: ' + JSON.stringify(this.performerBookingDetails));
        }
    }

    ngOnInit() {
    }

    public onTabChange(index: number) {
        this.selectedTabIndex = index;
    }
}
