<mat-card *ngIf="autosave" class="mb-25 phobos-card autosave-banner">
    <mat-card-content>
        <div class="course-details-header mb-25 border-radius">
<!--            <label class="d-block mb-12 fw-semibold text-white">Autosaved Booking</label>-->
            <div class="d-md-flex mb-12 align-items-center">
                <h4 class="mb-0 text-white">There is an autosaved booking draft, would you like to continue editing it?</h4>
            </div>
            <div class="title" *ngIf="autosave.booking.event_name">
                <h6 class="mb-125 text-white">Event Name: {{ autosave.booking.event_name ? autosave.booking.event_name : 'No event name selected' }}</h6>
                <span class="d-block text-white">Date: {{ (autosave.booking.event_date && autosave.booking.event_date.getTime() > 0) ? (autosave.booking.event_date | date:'EEEE, d MMMM yyyy') : 'No date selected' }}</span>
                <span class="d-block text-white">Location: {{ autosave.location.name ? autosave.location.name : 'No location selected' }}</span>
                <span class="d-block text-white">Customer: {{ autosave.customer.name ? autosave.customer.name : 'No customer selected' }}</span>
            </div>
            <br>
            <a mat-raised-button class="tagus mt-10" style="margin-right: 10px" (click)="continueEditing()">Continue Editing</a>
            <a mat-raised-button color="warn" class="tagus mt-10" (click)="removeAutosave()">Remove Draft</a>
        </div>
    </mat-card-content>
</mat-card>

<mat-card
    class="mb-25 phobos-card"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-content>

        <div class="mat-elevation-z8 phobos-mat-table">
            <div *ngIf="(bookingsDetails | async)?.length === 0">
                There are no bookings yet
            </div>
        </div>
        <div class="mat-elevation-z8 phobos-mat-table">
            <table mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Booking ID</th>
                    <td mat-cell *matCellDef="let element" class="fw-semibold text-start">{{element.position}}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Name</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <a (click)="openBookingDetails(element)" class="d-flex align-items-center product-info cursor-pointer">
                            <img src="assets/img/icon/booking.png" alt="performer">
                            {{element.booking.event_name}}
                        </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let element">{{element.booking.event_date}}</td>
                </ng-container>

                <ng-container matColumnDef="performers">
                    <th mat-header-cell *matHeaderCellDef>Performers</th>
                    <td class="members-list" mat-cell *matCellDef="let element">
                        <img *ngFor="let performer of element.performer_details"
                             [src]="'assets/img/user/user22.jpg'"
                             class="rounded-circle"
                             matTooltip="{{performer.name}}"
                             [alt]="performer.name">
                    </td>
                </ng-container>

                <ng-container matColumnDef="customer">
                    <th mat-header-cell *matHeaderCellDef>Customer</th>
                    <td mat-cell *matCellDef="let element">{{element.customer.name}}</td>
                </ng-container>

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef>Location</th>
                    <td mat-cell *matCellDef="let element">{{element.location.name}}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge Draft" *ngIf="element.booking.status == 'DRAFT'">{{element.booking.status}}</span>
                        <span class="badge Optional" *ngIf="element.booking.status == 'OPTION'">{{element.booking.status}}</span>
                        <span class="badge Confirmed" *ngIf="element.booking.status == 'CONFIRMED'">{{element.booking.status}}</span>
                        <span class="badge Pending" *ngIf="element.booking.status == 'PENDING'">{{element.booking.status}}</span>
                        <span class="badge Advancing" *ngIf="element.booking.status == 'ADVANCING'">{{element.booking.status}}</span>
                        <span class="badge Canceled" *ngIf="element.booking.status == 'CANCELED'">{{element.booking.status}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                            <i class="ri-more-fill"></i>
                        </button>
                        <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="openBookingDetails(element)">
                                <i class="ri-edit-line"></i> Edit
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Delete
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
            >
            </mat-paginator>
        </div>

    </mat-card-content>
</mat-card>

