<div class="row">
    <div class="col-md-12 col-lg-4">
        <mat-card class="profile-card-box phobos-card mb-25">
            <div class="d-md-flex align-items-center justify-content-space-between">
                <div class="profile-box">
                    <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                    <h5 class="fw-semibold">Wolter Kroes</h5>
                    <span class="d-block">Most booked performer</span>
                </div>
                <div class="impression-box text-center">
                    <div class="chart">
                        <apx-chart
                                [series]="chartOptions.series!"
                                [chart]="chartOptions.chart!"
                                [plotOptions]="chartOptions.plotOptions!"
                                [colors]="chartOptions.colors!"
                                [labels]="chartOptions.labels!"
                                [fill]="chartOptions.fill!"
                        ></apx-chart>
                    </div>
                    <span class="d-block">Average</span>
                    <h5 class="mb-0 fw-semibold">Booking Share</h5>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-md-6 col-lg-4">
        <mat-card class="single-stats-box phobos-card mb-25">
            <div class="d-flex justify-content-space-between align-items-center">
                <div class="icon">
                    <i class="ri-bar-chart-fill"></i>
                </div>
                <div class="title text-end">
                    <h3>30</h3>
                    <span class="d-block">Current Bookings</span>
                </div>
            </div>
            <div class="mb-12 info d-flex justify-content-space-between align-items-center">
                <span class="d-block fw-semibold">Booking Target</span>
                <span class="d-block ">100</span>
            </div>
            <mat-progress-bar mode="determinate" value="34"></mat-progress-bar>
        </mat-card>
    </div>
    <div class="col-md-6 col-lg-4">
        <mat-card class="single-stats-box phobos-card mb-25">
            <div class="d-flex justify-content-space-between align-items-center">
                <div class="icon">
                    <i class="ri-money-euro-box-fill"></i>
                </div>
                <div class="title text-end">
                    <h3>$31,570</h3>
                    <span class="d-block">Current Revenue</span>
                </div>
            </div>
            <div class="mb-12 info d-flex justify-content-space-between align-items-center">
                <span class="d-block fw-semibold">Booking Revenue Target</span>
                <span class="d-block">$180548.00</span>
            </div>
            <mat-progress-bar mode="determinate" value="30"></mat-progress-bar>
        </mat-card>
    </div>
</div>

<div class="row">
    <div class="col-sm-6 col-xxl-3">
        <mat-card class="stats-box phobos-card mb-25">
            <div class="d-flex justify-content-space-between align-items-center">
                <div class="title">
                    <span class="d-block">New Booking Requests</span>
                    <h3>$ 11.452</h3>
                </div>
                <div class="icon">
                    <i class="ri-calendar-event-line"></i>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-sm-6 col-xxl-3">
        <mat-card class="stats-box phobos-card mb-25">
            <div class="d-flex justify-content-space-between align-items-center">
                <div class="title">
                    <span class="d-block">Confirmed Bookings</span>
                    <h3>$ 28.452</h3>
                </div>
                <div class="icon">
                    <i class="ri-calendar-event-line"></i>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-sm-6 col-xxl-3">
        <mat-card class="stats-box phobos-card mb-25">
            <div class="d-flex justify-content-space-between align-items-center">
                <div class="title">
                    <span class="d-block">Advancing Bookings</span>
                    <h3>$20.500</h3>
                </div>
                <div class="icon">
                    <i class="ri-calendar-event-line"></i>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-sm-6 col-xxl-3">
        <mat-card class="stats-box phobos-card mb-25">
            <div class="d-flex justify-content-space-between align-items-center">
                <div class="title">
                    <span class="d-block">Cancelled Bookings</span>
                    <h3>$ 17.805</h3>
                </div>
                <div class="icon">
                    <i class="ri-calendar-event-line"></i>
                </div>
            </div>

        </mat-card>
    </div>
</div>
