import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {TABLE} from "../common/model/tables";
import {Invitation, InvitationStatus, UserRole} from "../common/model/model";

@Injectable()
export class InvitationService {

    constructor(private supabaseService: SupabaseService) {
    }

    public async getInvitationByEmail(email: string) {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.INVITATIONS)
            .select('*')
            .eq('email', email)
            .eq('status', InvitationStatus.INVITED.toString());

        if (error) {
            throw new Error("Failed to get invitation by email, because: " + error.message);
        }

        return data;
    }

    public async acceptInvitation(invitation: Invitation) {
        const { data, error } = await this.supabaseService.supabaseClient
            .rpc('accept_invitation', {
                invitation_details: invitation
            });

        if (error) {
            throw new Error("Failed to accept invitation, because: " + error.message);
        }

        return data;
    }

    public async handlerTest() {
        const { data, error } = await this.supabaseService.supabaseClient.functions.invoke('invite-function', {
            body: {
                action: 'processinvitation',
                email: 'vahid.vafaei@icloud.com',
                token: '9287509q28375qwedsf',
                role: UserRole.PERFORMER.toString()
            }
        });

        if (error) {
            throw new Error("Failed to check user availability and register, because: " + error.message);
        }

        return data;
    }

    public async handlerTest2() {
        const { data, error } = await this.supabaseService.supabaseClient.rpc('process_invitations');

        if (error) {
            console.error("Failed to execute RPC: ", error);
            throw new Error("Failed to check user availability: " + error.message);
        }

        console.log("Function result: ", data);
        return data;
    }

    public async getInvitationByToken(token: string) {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.INVITATIONS)
            .select('*')
            .eq('token', token);

        if (error) {
            throw new Error("Failed to get invitation by token, because: " + error.message);
        }

        return data;
    }
}
