import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Customer} from "../../../common/model/model";
import {CustomerService} from "../../../services/customer.service";
import {NotificationService} from "../../../services/notification.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'add-customer',
    templateUrl: 'add-customer-dialog.component.html',
    styleUrls: ['add-customer-dialog.component.scss']
})
export class AddCustomerDialogComponent implements OnInit {

    public addCustomerFormGroup: FormGroup;
    public addCustomerSubmitted = false;
    public logo: File[] = [];
    public isLoading = false;

    public existingPicture: string | null = null;
    public updateDisabled: boolean = true;

    constructor(public dialogRef: MatDialogRef<AddCustomerDialogComponent>,
                private addCustomerFormBuilder: FormBuilder,
                private customerService: CustomerService,
                private notificationService: NotificationService,
                private sanitizer: DomSanitizer,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.enableAddCustomerDialogForm();

        if(this.isCustomer(this.data)) {
            this.populateForm(this.data);

            if(this.data.logo) {
                this.existingPicture = this.data.logo;
                this.addCustomerFormGroup.patchValue({
                    logo: this.existingPicture
                });
            }
        }
    }

    get addCustomerFormGroupControls() {
        return this.addCustomerFormGroup.controls;
    }

    public enableAddCustomerDialogForm() {
        this.addCustomerFormGroup = this.addCustomerFormBuilder.group({
            name: ['', Validators.required],
            email: ['',Validators.required],
            phone: ['', Validators.required],
            address: ['',Validators.required],
            postcode: ['',Validators.required],
            city: ['',Validators.required],
            country: ['',Validators.required],
            website: ['',Validators.required],
            vat_number: ['',Validators.required],
            bank_account: ['',Validators.required],
            logo: ['',Validators.required]
        });
    }

    public populateForm(customer: Customer) {
        this.addCustomerFormGroup.patchValue({
            name: customer.name,
            email: customer.email,
            phone: customer.phone,
            address: customer.address,
            postcode: customer.postcode,
            city: customer.city,
            country: customer.country,
            website: customer.website,
            vat_number: customer.vat_number,
            bank_account: customer.bank_account,
            logo: customer.logo,
            agency_id: customer.agency_id
        });
    }

    public close(){
        this.dialogRef.close(true);
    }

    public onSelect(event:any) {
        this.logo = [];
        this.existingPicture = null;

        if (event.addedFiles.length > 0) {
            this.logo.push(event.addedFiles[0]);
        }

        this.addCustomerFormGroup.controls['logo'].setValue(this.logo[0]);
        this.markFormAsDirty();
    }

    private isCustomer(obj: any): obj is Customer {
        return obj &&
            typeof obj === 'object' &&
            'name' in obj &&
            'email' in obj &&
            'phone' in obj &&
            'address' in obj &&
            'postcode' in obj &&
            'city' in obj &&
            'country' in obj &&
            'website' in obj &&
            'vat_number' in obj &&
            'bank_account' in obj &&
            'logo' in obj &&
            'agency_id' in obj;
    }

    public getSafeUrl(base64String: string) {
        return this.sanitizer.bypassSecurityTrustUrl(base64String);
    }

    public onRemove() {
        this.existingPicture = null;
        this.addCustomerFormGroup.controls['logo'].setValue(null);
        this.markFormAsDirty();
    }

    private markFormAsDirty() {
        this.addCustomerFormGroup.markAsDirty();
        this.updateDisabled = false;
    }

    public saveOrUpdateCustomer() {
        if(this.isCustomer(this.data)) {
            console.log('updating');
            this.updateCustomer();
        } else {
            console.log('saving');
            this.saveCustomer()
        }
    }

    public updateCustomer() {
        this.addCustomerSubmitted = true;
        if(this.addCustomerFormGroup.invalid) {

        } else {
            this.isLoading = true;
            let customer = this.data as Customer;

            let name = this.addCustomerFormGroup.controls['name'].value;
            let email = this.addCustomerFormGroup.controls['email'].value;
            let phone = this.addCustomerFormGroup.controls['phone'].value;
            let address = this.addCustomerFormGroup.controls['address'].value;
            let postcode = this.addCustomerFormGroup.controls['postcode'].value;
            let city = this.addCustomerFormGroup.controls['city'].value;
            let country = this.addCustomerFormGroup.controls['country'].value;
            let website = this.addCustomerFormGroup.controls['website'].value;
            let vat_number = this.addCustomerFormGroup.controls['vat_number'].value;
            let bank_account = this.addCustomerFormGroup.controls['bank_account'].value;

            const updateCustomer = () => {
                customer.name = name;
                customer.email = email;
                customer.phone = phone;
                customer.address = address;
                customer.postcode = postcode;
                customer.city = city;
                customer.country = country;
                customer.website = website;
                customer.vat_number = vat_number;
                customer.bank_account = bank_account;

                this.customerService.updateCustomer(customer).then(customer => {
                    this.dialogRef.close(customer);
                }).catch(error => {
                    this.notificationService.openFailureNotitication(error.message);
                }).finally(() => {
                    this.isLoading = false;
                });
            }

            if(this.logo.length > 0) {
                const reader = new FileReader();
                reader.readAsDataURL(this.logo[0]);
                reader.onload = () => {
                    if (reader.result) {
                        customer.logo = reader.result.toString();
                        updateCustomer();
                    }
                };
            } else if(this.existingPicture) {
                customer.logo = this.existingPicture;
                updateCustomer();
            } else {
                customer.logo = '';
                updateCustomer();
            }
        }
    }

    public saveCustomer() {
        this.addCustomerSubmitted = true;
        if(this.addCustomerFormGroup.invalid) {
        } else {
            this.isLoading = true;
            const reader = new FileReader();
            reader.readAsDataURL(this.logo[0]);
            reader.onload = () => {
                if(reader.result) {
                    let customer: Customer = {
                        name: this.addCustomerFormGroup.controls['name'].value,
                        email: this.addCustomerFormGroup.controls['email'].value,
                        phone: this.addCustomerFormGroup.controls['phone'].value,
                        address: this.addCustomerFormGroup.controls['address'].value,
                        postcode: this.addCustomerFormGroup.controls['postcode'].value,
                        city: this.addCustomerFormGroup.controls['city'].value,
                        country: this.addCustomerFormGroup.controls['country'].value,
                        website: this.addCustomerFormGroup.controls['website'].value,
                        vat_number: this.addCustomerFormGroup.controls['vat_number'].value,
                        bank_account: this.addCustomerFormGroup.controls['bank_account'].value,
                        logo: reader.result.toString(),
                        agency_id: this.data
                    }

                    this.customerService.addCustomer(customer).then(customer => {
                        this.notificationService.openSuccessfulNotification("Successfully added Customer: " + customer[0].name);
                        this.dialogRef.close(customer[0]);
                    }).catch(error => {
                        this.notificationService.openFailureNotitication("Failed to add customer, because: " + error.message);
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            }
        }
    }
}
