import {Component, HostListener, OnInit} from '@angular/core';
import {ToggleService} from './toggle.service';
import {DatePipe} from '@angular/common';
import {CustomizerSettingsService} from '../../customizer-settings/customizer-settings.service';
import {Router} from "@angular/router";
import {Agency, Currency, Invitation} from "../model/model";
import {CurrencyService} from "../../services/currency.service";
import {SupabaseAuthService} from "../../services/supabase.auth.service";
import {SearchService} from "../../services/search.service";
import {NotificationService} from "../../services/notification.service";
import {InvitationService} from "../../services/invitation.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public agencies: Agency[] = [];
    public currencies: Currency[] = [];
    public selectedCurrency: Currency = this.currencies[0];
    public currentUser: any;
    public invitations: Invitation[] = [];
    public isPerformer: boolean = false;

    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    isToggled = false;

    constructor(private toggleService: ToggleService,
                private datePipe: DatePipe,
                public themeService: CustomizerSettingsService,
                private currencyService: CurrencyService,
                private supabaseAuthService: SupabaseAuthService,
                private searchService: SearchService,
                private router: Router,
                private notificationService: NotificationService,
                private invitationService: InvitationService) {

        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    public ngOnInit() {
        this.getCurrencies();
        this.getCurrenUser();
        this.getInvitations();
        this.checkIfPerformer();
    }

    public checkIfPerformer() {
        const userInfo = this.supabaseAuthService.getCurrentUserInfo();
        this.isPerformer = userInfo?.user_metadata['role'] === 'Performer';
    }

    public onSelect(currency: Currency): void {
        this.selectedCurrency = currency;
        this.currencyService.setCurrentCurrency(this.selectedCurrency);
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggle() {
        this.toggleService.toggle();
    }

    toggleSidebarTheme() {
        this.themeService.toggleSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleHeaderTheme() {
        this.themeService.toggleHeaderTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    public getCurrenUser() {
        this.supabaseAuthService.getCurrentUser().subscribe(user => {
            if(user) {
                this.currentUser = user;
            }
        });
    }

    // public onSearchChange(searchValue: string) {
    //     this.searchService.updateSearchTerm(searchValue);
    // }

    public onSearchChange(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const searchTerm = inputElement.value;
        this.searchService.updateSearchTerm(searchTerm);
    }

    public logout() {
        this.supabaseAuthService.logout().then(() => {
            this.router.navigate(['/']);
        }).catch((error) => {
            console.error('Failed to logout:', error);
        });
    }

    public getCurrencies() {
        this.currencyService.getCurrencies().then((currencies) => {
            this.currencies = currencies;
            if (this.currencies.length > 0) {
                this.selectedCurrency = this.currencies[0];
                this.currencyService.setCurrentCurrency(this.selectedCurrency);
            }
        }).catch((error) => {
            this.notificationService.openFailureNotitication(error.message);
        });
    }

    public getInvitations() {
        let email = this.supabaseAuthService.getCurrentUserInfo().email;
        if(!email) {
            throw new Error('User email is not set');
        } else {
            this.invitationService.getInvitationByEmail(email).then(invitations => {
                this.invitations = invitations;
            });
        }
    }

    currentDate: Date = new Date();
    formattedDate: any = this.datePipe.transform(this.currentDate, 'dd MMMM yyyy');
}
