import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {User} from "@supabase/supabase-js";
import {BehaviorSubject, Observable} from "rxjs";
import {TABLE} from "../common/model/tables";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SupabaseAuthService {

    private currentUser = new BehaviorSubject<User | null>(null);
    public currentUser$ = this.currentUser.asObservable();
    public initialLoadComplete$ = new BehaviorSubject<boolean>(false);

    constructor(private supabaseService: SupabaseService) {
        this.loadUser();

        this.supabaseService.supabaseClient.auth.onAuthStateChange((event, session) => {
            if(event === 'SIGNED_IN') {
                this.currentUser.next(session?.user || null);
            } else if(event === 'SIGNED_OUT') {
                this.currentUser.next(null);
            }
        });
    }

    public async login(email: string, password: string): Promise<User | null> {
        const {error, data} = await this.supabaseService.supabaseClient.auth.signInWithPassword({
            email: email,
            password: password});

        if(error) {
            throw new Error(error.message)
        }

        return data.user;
    }

    public getCurrentUserInfo() {
        if(!this.currentUser.value) {
            throw new Error('Current user is not set');
        } else {
            return this.currentUser.value;
        }
    }

    public getCurrentUserInfoTest(): Promise<User> {
        return new Promise((resolve, reject) => {
            if (this.currentUser.value) {
                resolve(this.currentUser.value);
            } else {
                this.initialLoadComplete$.subscribe((isComplete) => {
                    if (isComplete) {
                        if (this.currentUser.value) {
                            resolve(this.currentUser.value);
                        } else {
                            reject(new Error('User not logged in'));
                        }
                    }
                });
            }
        });
    }

    public async loadUser() {
        if (this.currentUser.value) {
            // User is already set, no need to do anything else
            this.initialLoadComplete$.next(true);
            return;
        }

        const user = await this.supabaseService.supabaseClient.auth.getUser();

        if (user.data.user) {
            this.currentUser.next(user.data.user);
        } else {
            this.currentUser.next(null);
        }
        this.initialLoadComplete$.next(true);
    }

    public async logout(): Promise<void> {
        this.currentUser.next(null);
        const { error } = await this.supabaseService.supabaseClient.auth.signOut();

        if(error) {
            throw new Error(error.message);
        }

        return;
    }

    public getCurrentUser(): Observable<User | null> {
        return this.currentUser.asObservable()
    }

    public async signUpRegister(email: string, password: string, name: string, role: string): Promise<User | null> {

        const {error, data} = await this.supabaseService.supabaseClient.auth.signUp({
            email: email,
            password: password,
            options: {
                emailRedirectTo: environment.siteUrl,
                data: {
                    name: name,
                    role: role
                }
            }
        });

        if(error) {
            throw new Error(error.message)
        }

        return data.user;
    }

    public async checkUserAvailableAlreadyRegistered(email: string) {
        const {data: existingUser, error} =await this.supabaseService.supabaseClient
            .from(TABLE.AGENCY_USERS)
            .select('user_email')
            .eq('user_email', email);

        if(error){
            throw new Error('Failed to check if user is already registered, because: ' + error.message);
        }

        return existingUser;
    }

    public async assignOwnerRoleAfterStartup(userId: string, ownerRoleId: string) {
        const{data, error } = await this.supabaseService.supabaseClient
            .schema('rbac')
            .from('user_role')
            .insert([{user_id: userId, role_id: ownerRoleId}]);

        if(error) {
            throw new Error('Failed to assign owner role to user, because: ' + error.message);
        }

        return data;
    }

    public async getOwnerRole() {
        const{data, error } = await this.supabaseService.supabaseClient
            .schema('rbac')
            .from('role')
            .select('id');

        if(error) {
            throw new Error('Failed to retrieve roles, because: ' + error.message);
        }

        return data;
    }

    public async checkUserInPublicUsers(authId: string) {
        const { data: user, error } = await this.supabaseService.supabaseClient
            .from('users')
            .select('authid')
            .eq('authid', authId);

        if (error) {
            throw new Error('Failed to check if user exists in public.users, because: ' + error.message);
        }

        return user;
    }

    public async getSpecificUserRoles() {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.SPECIFIC_ROLES)
            .select('*');

        if (error) {
            throw new Error("Failed to retrieve specific user roles, because: " + error.message);
        }

        return data;
    }
}
