import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ContractService} from "../../services/contract.service";
import {MatDialog} from "@angular/material/dialog";
import {ContractCheckDialogComponent} from "./contract-check/contract-check-dialog.component";
import {NotificationService} from "../../services/notification.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {
    Booking_Financials,
    Booking_Performer,
    BookingDetails,
    Performer,
    Verified_Contract
} from "../../common/model/model";
import {FinanceService} from "../../services/finance.service";
import SignaturePad from "signature_pad";

@Component({
    selector: 'contracts-component',
    templateUrl: './contracts.component.html',
    styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit, AfterViewInit {

    public contractToken: string;
    public clientEmail: string;
    public contractFormGroup: FormGroup;
    public verifiedContract: Verified_Contract;
    public bookingFinancials: Booking_Financials;
    //test link -> http://localhost:4200/contract/20241110T19053-b88cfe08-3681-4889-9285-3d90d52b7906

    @ViewChild('signaturePadClient') signaturePadElementClient!: ElementRef;
    @ViewChild('signaturePadContractor') signaturePadElementContractor!: ElementRef;

    signaturePadClient!: SignaturePad;
    signaturePadContractor!: SignaturePad;


    constructor(private activatedRoute: ActivatedRoute,
                private contractService: ContractService,
                private matDialog: MatDialog,
                private notificationService: NotificationService,
                private financeService: FinanceService,
                private contractFormBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.contractToken = this.activatedRoute.snapshot.paramMap.get('token') || '';
        console.log('token: ' + this.contractToken);
        //this.openContractCheckDialog('300ms', '100ms');
        this.checkEmail('klm@klm.nl')
        window.addEventListener('resize', this.handleResize.bind(this));

    }

    private handleResize() {
        if (this.signaturePadElementClient && this.signaturePadElementContractor) {
            this.setCanvasDimensions(this.signaturePadElementClient.nativeElement);
            this.setCanvasDimensions(this.signaturePadElementContractor.nativeElement);
        }
    }

    private initializeSignaturePads() {
        // Get the parent width for responsive sizing
        const clientCanvas = this.signaturePadElementClient.nativeElement;
        const contractorCanvas = this.signaturePadElementContractor.nativeElement;

        // Set initial dimensions
        this.setCanvasDimensions(clientCanvas);
        this.setCanvasDimensions(contractorCanvas);

        // Initialize signature pads
        this.signaturePadClient = new SignaturePad(clientCanvas, {
            backgroundColor: 'rgb(255, 255, 255)',
            penColor: 'rgb(0, 0, 0)',
            velocityFilterWeight: 0.7,
            minWidth: 0.5,
            maxWidth: 2.5,
            throttle: 16
        });

        this.signaturePadContractor = new SignaturePad(contractorCanvas, {
            backgroundColor: 'rgb(255, 255, 255)',
            penColor: 'rgb(0, 0, 0)',
            velocityFilterWeight: 0.7,
            minWidth: 0.5,
            maxWidth: 2.5,
            throttle: 16
        });

        // Add window resize listener
        window.addEventListener('resize', () => {
            this.setCanvasDimensions(clientCanvas);
            this.setCanvasDimensions(contractorCanvas);
        });
    }

    private setCanvasDimensions(canvas: HTMLCanvasElement) {
        if (!canvas || !canvas.parentElement) {
            return;
        }

        const parentWidth = canvas.parentElement.clientWidth;
        const isMobile = window.innerWidth <= 768;
        const isTablet = window.innerWidth <= 991;

        let width = Math.min(parentWidth, 500);
        let height = width / 2; // Maintain 2:1 aspect ratio

        if (isMobile) {
            height = Math.min(150, height);
        } else if (isTablet) {
            height = Math.min(180, height);
        } else {
            height = Math.min(200, height);
        }

        canvas.width = width;
        canvas.height = height;

        // Maintain signature data if it exists
        const pad = canvas === this.signaturePadElementClient.nativeElement
            ? this.signaturePadClient
            : this.signaturePadContractor;

        if (pad) {
            const data = pad.toData();
            pad.clear();
            if (data.length) {
                pad.fromData(data);
            }
        }
    }

    ngAfterViewInit() {
        window.removeEventListener('resize', this.handleResize.bind(this));
    }

    public clearClientSignature() {
        this.signaturePadClient.clear();
    }

    public clearContractorSignature() {
        this.signaturePadContractor.clear();
    }

    public saveClientSignature() {
        if(this.signaturePadClient.isEmpty()) {
            this.notificationService.openFailureNotitication('Please sign the client agreement');
            return;
        }
        let clientSignature = this.signaturePadClient.toDataURL();
        console.log('client signature: ' + clientSignature);
    }

    public saveContractorSignature() {
        if(this.signaturePadContractor.isEmpty()) {
            this.notificationService.openFailureNotitication('Please sign the contractor agreement');
            return;
        }
        let contractorSignature = this.signaturePadContractor.toDataURL();
        console.log('contractor signature: ' + contractorSignature);
    }

    public checkEmail(clientEmail: string) {
        this.contractService.getContract(this.contractToken, clientEmail).then(contract => {
            this.verifiedContract = contract as Verified_Contract;
            this.bookingFinancials = this.financeService.calculateBookingFinancialTotals(this.verifiedContract.bookingDetails,
                this.combinePerformers(this.verifiedContract.bookingDetails));
            setTimeout(() => {
                this.initializeSignaturePads();
            }, 0);

        }).catch(error => {
            this.notificationService.openFailureNotitication("Contract not available or this contract doesn't belong to you");
        });
    }

    public combinePerformers(bookingDetails: BookingDetails): (Booking_Performer & Partial<Performer>)[] {
        if (!bookingDetails || !bookingDetails.performers || !bookingDetails.performer_details) {
            return [];
        }

        return bookingDetails.performers.map(performer => {
            const details = bookingDetails.performer_details.find(detail => detail.id === performer.performer_id);
            return {
                ...performer,
                ...details
            };
        });
    }

    public openContractCheckDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        const contractCheckDialogRef = this.matDialog.open(ContractCheckDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            disableClose: true
        });

        contractCheckDialogRef.afterClosed().subscribe(result => {
            console.log('result: ' + JSON.stringify(result));
            if (result && typeof result === 'string') {
                this.clientEmail = result;
                this.checkEmail(this.clientEmail);
            } else {
                this.notificationService.openFailureNotitication('No valid e-mail address provided');
            }
        });
    }
}
