<app-target-dashboard></app-target-dashboard>

<div class="row">
    <div class="col-lg-8">
        <app-upcoming-dashboard></app-upcoming-dashboard>
    </div>

    <div class="col-lg-4">
        <app-upcoming-dashboard-calendar></app-upcoming-dashboard-calendar>
    </div>
</div>
