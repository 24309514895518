import {Component, OnInit} from "@angular/core";
import {CustomizerSettingsService} from "../../../customizer-settings/customizer-settings.service";
import {InvitationService} from "../../../services/invitation.service";
import {Invitation} from "../../../common/model/model";
import {SupabaseAuthService} from "../../../services/supabase.auth.service";

@Component({
    selector: 'invitations-component',
    templateUrl: './invitations.component.html',
    styleUrls: ['./invitations.component.scss']

})
export class InvitationsComponent implements OnInit {

    public invitations: Invitation[] = [];

    constructor(public themeService: CustomizerSettingsService,
                private invitationService: InvitationService,
                private supabaseAuthService: SupabaseAuthService) {
    }

    ngOnInit(): void {
        this.getInvitations();
    }

    public getInvitations() {
        let email = this.supabaseAuthService.getCurrentUserInfo().email;
        if(!email) {
            throw new Error('User email is not set');
        } else {
            this.invitationService.getInvitationByEmail(email).then(invitations => {
                this.invitations = invitations;
            });
        }
    }

    public acceptInvitation(invitation: Invitation) {
        this.invitationService.acceptInvitation(invitation).then(result => {
            this.getInvitations();
        })
    }
}
