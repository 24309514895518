<div *ngIf="performerBookingDetails">
    <mat-card class="mb-25 create-project-card phobos-card">
        <form [formGroup]="performerBookingFormGroup">
            <div class="row mt-20">
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="phobos-form-group">
                        <label class="d-block mb-12 fw-semibold">Event Name</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-book-line"></i>
                            <input formControlName="eventname" matInput class="custom-disabled">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="d-block mb-12 fw-semibold">Event Date</label>
                    <mat-form-field appearance="fill" class="date-input">
                        <mat-label>MM/DD/YYYY</mat-label>
                        <input matInput formControlName="eventdate" [matDatepicker]="eventDatePicker" class="custom-disabled">
                        <mat-datepicker-toggle matIconSuffix [for]="eventDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #eventDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                    <div class="phobos-form-group">
                        <label class="d-block mb-12 fw-semibold">Booking Status</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-book-line"></i>
                            <input formControlName="bookingstatus" matInput class="custom-disabled">
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-2 col-md-2 col-sm-2">
                    <label class="d-block mb-12 fw-semibold">Location</label>
                    <mat-form-field appearance="fill">
                        <i class="ri-book-line"></i>
                        <input formControlName="location" matInput class="custom-disabled">
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                    <label class="d-block mb-12 fw-semibold">Customer</label>
                    <mat-form-field appearance="fill">
                        <i class="ri-book-line"></i>
                        <input formControlName="customer" matInput class="custom-disabled">
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-card>
    <mat-card class="mb-25 phobos-card" *ngIf="performerBookingDetails.performers">
        <mat-card-header class="border-bottom">
            <h5 class="mb-0">Performance(s)</h5>
        </mat-card-header>
        <mat-card-content>
            <div class="all-projects-table table-responsive">
                <table class="table" *ngIf="performerBookingDetails.performers.length >= 1">
                    <thead >
                    <tr>
                        <th class="fw-semibold main-color">Performer</th>
                        <th class="fw-semibold main-color">Status</th>
                        <th class="fw-semibold main-color">Start Time</th>
                        <th class="fw-semibold main-color">End Time</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let performer of performerBookingDetails.performers">
                        <td>
                            <div class="projects-name d-flex align-items-center">
                                <div class="img">
                                    <img src="assets/img/icon/performer.png" alt="icon1">
                                </div>
                                <h5 class="fw-semibold mb-0">{{ performer.name }}</h5>
                            </div>
                        </td>
                        <td>
                            <span class="badge fw-medium"
                                  [ngClass]="{
                                  'confirmed': performer.status === 'Confirmed',
                                  'option': performer.status === 'Option',
                                  'draft': performer.status === 'Draft'
                                  }">
                                {{performer.status}}
                            </span>
                        </td>
                        <td>{{performer.start_time}}</td>
                        <td>{{performer.end_time}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="mb-25 phobos-card" [class.component-dark-theme]="themeService.isDark()"
              [class.component-rtl-enabled]="themeService.isRTLEnabled()">
        <mat-card-header class="border-bottom">
            <h5 class="mb-0">Booking Info</h5>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="performerBookingFormGroup">
                <div class="row mt-20">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold ">Event Website</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-pages-line"></i>
                                <mat-label>Website of the event/organization</mat-label>
                                <input formControlName="eventwebsite" matInput class="custom-disabled">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Opening Time</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <input formControlName="openingtime" matInput type="time" class="custom-disabled">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Begin Time</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <mat-label>Time the event starts</mat-label>
                                <input formControlName="begintime" matInput type="time" class="custom-disabled">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">End Time</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <mat-label>Time the event ends</mat-label>
                                <input formControlName="endtime" matInput type="time" class="custom-disabled">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Visitor Expectation</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <mat-label>Expected amount of visitors</mat-label>
                                <input formControlName="visitorexpectation" matInput class="custom-disabled">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Sound Check Time</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <mat-label>Start of sound check</mat-label>
                                <input formControlName="soundchecktime" type="time" matInput class="custom-disabled">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Booking Description</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Enter additional booking description here</mat-label>
                                <textarea formControlName="bookingdescription" matInput rows="6" class="custom-disabled"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Line-Up Event</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Line-up of the event</mat-label>
                                <textarea formControlName="lineupevent" matInput rows="6" class="custom-disabled"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Call Sheet Memo</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Relevant information regarding call sheet</mat-label>
                                <textarea formControlName="callsheetmemo" matInput rows="6" class="custom-disabled"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>

</div>
