import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from "@angular/forms";
import {
    Agency,
    Booking,
    Booking_Contact,
    Booking_Guest,
    Booking_Performer,
    Booking_Performer_Flight,
    Booking_Performer_Hotel,
    Booking_Product,
    BookingStatus,
    Currency,
    Customer,
    Location,
    Performer,
    Performer_Product,
    Performer_Product_With_Performer,
    Product_Category,
    Product_Subcategory
} from "../../../common/model/model";
import {MatDialog} from "@angular/material/dialog";
import {interval, Observable, Subscription} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {AddLocationDialogComponent} from "../../locations/add-location/add-location-dialog.component";
import {AgencyService} from "../../../services/agency.service";
import {PerformerService} from "../../../services/performer.service";
import {LocationService} from "../../../services/location.service";
import {AttachmentService} from "../../../services/attachment.service";
import {BookingService} from "../../../services/booking.service";
import {Router} from "@angular/router";
import {MatStepper} from "@angular/material/stepper";
import {CurrencyService} from "../../../services/currency.service";
import {CustomerService} from "../../../services/customer.service";
import {AddCustomerDialogComponent} from "../../customers/add-customer/add-customer-dialog.component";
import {NotificationService} from "../../../services/notification.service";
import {MatSelect, MatSelectChange} from "@angular/material/select";
import {EditBookingPerformerDialogComponent} from "./edit-bookingperformer/edit-booking-performer-dialog.component";
import {EditBookingProductDialogComponent} from "./edit-bookingproduct/edit-booking-product-dialog.component";
import {AddPerformerFlightComponentDialog} from "./add-performerflight/add-performer-flight-component-dialog.component";
import {AddPerformerHotelDialogComponent} from "./add-performerhotel/add-performer-hotel-dialog.component";
import {AddPerformerProductDialogComponent} from "./add-performerproduct/add-performer-product-dialog.component";
import {AddProductCategoriesDialogComponent} from "./add-productcategories/add-product-categories-dialog.component";
import {ProductService} from "../../../services/product.service";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {AddContactDialogComponent} from "../booking-details/add-contact/add-contact-dialog.component";

@Component({
    selector: 'addbooking-component',
    templateUrl: './add-booking.component.html',
    styleUrls: ['./add-booking.component.scss']
})
export class AddBookingComponent implements OnInit, OnDestroy {

    @ViewChild('performerSelect') performerSelect: MatSelect;
    @ViewChild('statusSelect') statusSelect: MatSelect;

    public addBookingInformationFormGroup: FormGroup;
    public addBookingProductFormGroup: FormGroup;
    public addPerformersFormGroup: FormGroup;
    public addContactPersonFormGroup: FormGroup;
    public addGuestFormGroup: FormGroup;
    public addAttachmentsFormGroup: FormGroup;

    protected readonly BookingStatus = BookingStatus;

    public addBookingSubmitted = false;
    public addPerformerSubmitted = false;
    public addContactPersonSubmitted = false;
    public addGuestSubmitted = false;
    public addBookingProductSubmitted = false;

    public locationFormControl = new FormControl<Location | null>(null, [Validators.required, this.locationValidator()]);
    public customerFormControl = new FormControl<Customer | null>(null, Validators.required);
    public performersFormControl= new FormControl();

    public selectedAgency: Agency;
    public performers: Performer[] = [];
    public locations: Location[] = [];
    public customers: Customer[] = [];
    public selectedPerformer: Performer | null = null;
    public selectedBookingPerformerStatus: string;
    public selectedLocation: Location;
    public selectedCurrency: Currency;
    public selectedCustomer: Customer;
    public selectedProductCategory: Product_Category;
    public selectedProductSubCategory: Product_Subcategory | null = null;

    public isLoading = false;

    //The performers that are added to the booking
    public bookingPerformers: Booking_Performer[] = [];

    //The content persons that are added to the booking
    public bookingContacts: Booking_Contact[] = [];

    //The guests that are added to the booking
    public bookingGuests: Booking_Guest[] = [];

    //The attachments that are added to the booking
    public selectedFiles: File[] = [];

    //Products added to the booking
    public bookingProducts: Booking_Product[] = [];

    //Products of the selected performers
    public performerProducts: Performer_Product[] = [];

    //Performer flights per Performer
    public bookingPerformerFlights: Booking_Performer_Flight[] = [];

    //Performer hotels per Performer
    public bookingPerformerHotels: Booking_Performer_Hotel[] = [];

    //Product categories per agency
    public productCategories: Product_Category[] = [];

    //Product subcategories per product category
    public productSubCategories: Product_Subcategory[] = [];

    //Performer Booking Statuses
    public bookingPerformerStatuses: string[] = [];

    //Booking products grouped per sub category and category
    public groupedProducts: { category: string, subcategories: { subcategory: string, products: Booking_Product[] }[] }[] = [];

    public bookingTotalPrice: number = 0;
    public bookingTotalCost: number = 0;

    @ViewChild('bookingStepper') stepper: MatStepper;

    members = new FormControl('');
    membersList: string[] = [
        'Alvarado Turner', 'Evangelina Mcclain', 'Candice Munoz', 'Bernard Langley', 'Kristie Hall', 'Bolton Obrien'
    ];

    filteredLocations: Observable<Location[]>;

    private autosaveSubscription!: Subscription;
    private readonly AUTOSAVE_INTERVAL = 3000; // Autosave every 60 seconds
    public lastAutosaveTime: Date | null = null;

    constructor(public matDialog: MatDialog,
                private agencyService: AgencyService,
                private performerService: PerformerService,
                private locationService: LocationService,
                private attachmentService: AttachmentService,
                private bookingService: BookingService,
                private addBookingFormBuilder: FormBuilder,
                private currencyService: CurrencyService,
                private customerService: CustomerService,
                private productService: ProductService,
                private notificationService: NotificationService,
                private router: Router,
                private changeDetectorRef: ChangeDetectorRef) {

        this.performersFormControl = new FormControl(
            [],
            [this.minArrayLengthValidator(1)],
        );
    }

    ngOnInit(): void {
        this.enableFilterLocations();

        this.customerFormControl.valueChanges.subscribe(value => {

            if(value !== null && value !== undefined) {
                this.selectedCustomer = value;
            }
        });

        this.agencyService.selectedAgency$.subscribe(agency => {
            if(agency) {
                this.selectedAgency = agency;
                this.getAllPerformers(this.selectedAgency);
                this.getAllLocations(agency).then(() => {
                    this.enableFilterLocations();
                });

                this.getAllCustomers(agency).then(() => {
                    this.loadAutosavedData();
                });

                this.getAllProductCategories(agency);
                //this.getAllProductSubCategories(agency);
            }
        });

        this.enableAddPerformersForm();
        this.enableAddBoookingInformationForm();
        this.enableAddContactPersonForm();
        this.enableAddGuestForm();
        this.listenToSelectedCurrency();
        this.getBookingPerformerStatus();
        this.enableBookingProductFormGroup();

        //this.loadAutosavedData();
        this.startAutosave();
    }

    ngOnDestroy() {
        this.stopAutosave();
    }

    private startAutosave() {
        this.autosaveSubscription = interval(this.AUTOSAVE_INTERVAL).subscribe(() => {
            this.autosaveFormData();
        });
    }

    private stopAutosave() {
        if (this.autosaveSubscription) {
            this.autosaveSubscription.unsubscribe();
        }
    }

    private autosaveFormData() {
        const formData = {
            ...this.addBookingInformationFormGroup.value,
            customer: this.selectedCustomer,
            location: this.selectedLocation,
            bookingPerformers: this.bookingPerformers,
            bookingContacts: this.bookingContacts,
            bookingGuests: this.bookingGuests,
            selectedFiles: this.selectedFiles.map(file => ({
                name: file.name,
                type: file.type,
                size: file.size,
                lastModified: file.lastModified
            })),
            bookingProducts: this.bookingProducts,
            performerProducts: this.performerProducts,
            bookingPerformerFlights: this.bookingPerformerFlights,
            bookingPerformerHotels: this.bookingPerformerHotels,
            productCategories: this.productCategories,
            productSubCategories: this.productSubCategories,
            lastAutosaveTime: new Date()
        };

        localStorage.setItem('autosavedBooking', JSON.stringify(formData));
        this.lastAutosaveTime = new Date();
        this.changeDetectorRef.detectChanges();
    }

    private loadAutosavedData() {
        const savedData = localStorage.getItem('autosavedBooking');
        if (savedData) {
            const parsedData = JSON.parse(savedData);

            this.addBookingInformationFormGroup.patchValue(parsedData);

            if (parsedData.customer) {
                const matchingCustomer = this.customers.find(c => c.id === parsedData.customer.id);
                if (matchingCustomer) {
                    this.selectedCustomer = matchingCustomer;
                    this.customerFormControl.setValue(this.selectedCustomer);
                    this.changeDetectorRef.detectChanges();
                }
            }

            if(parsedData.location) {
                const matchingLocation = this.locations.find(l => l.id === parsedData.location.id);
                if (matchingLocation) {
                    this.selectedLocation = matchingLocation;
                    this.locationFormControl.setValue(this.selectedLocation);
                    this.changeDetectorRef.detectChanges();
                }
            }

            this.addBookingInformationFormGroup.patchValue(parsedData);

            this.bookingPerformers = parsedData.bookingPerformers || [];
            this.bookingContacts = parsedData.bookingContacts || [];
            this.bookingGuests = parsedData.bookingGuests || [];
            this.selectedFiles = parsedData.selectedFiles.map((fileData: any) => new File([fileData], fileData.name)) || [];
            this.bookingProducts = parsedData.bookingProducts || [];
            this.performerProducts = parsedData.performerProducts || [];
            this.bookingPerformerFlights = parsedData.bookingPerformerFlights || [];
            this.bookingPerformerHotels = parsedData.bookingPerformerHotels || [];
            this.productCategories = parsedData.productCategories || [];
            this.productSubCategories = parsedData.productSubCategories || [];

            this.lastAutosaveTime = new Date(parsedData.lastAutosaveTime);
        }
    }

    private clearAutosave() {
        localStorage.removeItem('autosavedBooking');
        this.lastAutosaveTime = null;
    }

    public enableAddPerformersForm() {
        this.addPerformersFormGroup = this.addBookingFormBuilder.group({
            performer: ['', Validators.required],
            starttime: ['',Validators.required],
            endtime: ['',Validators.required],
            performer_status: ['',Validators.required],
            buma_fee: ['']
        });
    }

    get addPerformersFormGroupControls() {
        return this.addPerformersFormGroup.controls;
    }

    //Step 2: Add Booking info form controls
    public enableAddBoookingInformationForm() {
        this.addBookingInformationFormGroup = this.addBookingFormBuilder.group({
            //booking information
            event_name: ['', Validators.required],
            event_website: [''],
            event_budget: [''],
            event_date: ['',Validators.required],
            opening_time: [''],
            begin_time: [''],
            end_time: [''],
            booking_description: [''],
            line_up_event: [''],
            internal_notes: [''],
            construction_start_time: [''],
            sound_check_time: [''],
            podium_ready_time: [''],
            performance_floor: [''],
            distance_to_stage: [''],
            elevator_availability: [''],
            call_sheet_memo: [''],
            //performers: this.performersFormControl,
            performers: new FormControl([], [Validators.required, this.minArrayLengthValidator(1)]),
            performer_starttime: [''],
            performer_endtime: [''],
            performer_status: [''],
            visitor_expectation: [''],
            available_tickets: [''],
            //Performer information
            product_name: [''],
            product_description: [''],
            product_price: [''],
            product_cost: [''],
            product_category: [''],
            product_subcategory: [{value: '', disabled: true}],
            product_bookingsfee: [''],
            product_bookingsfeevat: [''],
            customer: this.customerFormControl,
            location: this.locationFormControl,
        });
    }

    get addBookingFormGroupControls() {
        return this.addBookingInformationFormGroup.controls;
    }

    //Step 3: Add Contact Person form controls
    public enableAddContactPersonForm() {
        this.addContactPersonFormGroup = this.addBookingFormBuilder.group({
            contact_name: ['', Validators.required],
            contact_role: ['', Validators.required],
            contact_phone: ['', Validators.required],
            contact_email: ['', Validators.required],
            contact_company: ['', Validators.required]
        });
    }

    get addContactPersonFormGroupControls() {
        return this.addContactPersonFormGroup.controls;
    }

    public enableAddGuestForm() {
        this.addGuestFormGroup = this.addBookingFormBuilder.group({
            guestname: ['', Validators.required],
            guestemail: ['', Validators.required],
            guestdescription: ['', Validators.required]
        });
    }

    get addGuestFormGroupControls() {
        return this.addGuestFormGroup.controls;
    }

    public enableBookingProductFormGroup() {
        this.addBookingProductFormGroup = this.addBookingFormBuilder.group({
            product_name: ['', Validators.required],
            product_description: ['', Validators.required],
            product_price: ['', Validators.required],
            product_cost: ['', Validators.required],
            product_category: ['', Validators.required],
            product_subcategory: [{value: '', disabled: true}, Validators.required],
        });
    }

    get addBookingProductFormGroupControls() {
        return this.addBookingProductFormGroup.controls;
    }

    public enableFilterLocations() {
        this.filteredLocations = this.locationFormControl.valueChanges.pipe(
            startWith(''),
            map(state => {
                if (typeof state === 'string') {
                    this.locationFormControl.updateValueAndValidity();
                }
                return state ? this._filteredLocations(state) : this.locations.slice();
            }),
        );
    }

    private _filteredLocations(value: string | Location): Location[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else if (value && typeof value === 'object' && value.name) {
            filterValue = value.name.toLowerCase();
        }
        return this.locations.filter(location => location.name.toLowerCase().includes(filterValue));
    }

    displayFn(location: Location | string): string {
        if (typeof location === 'string') {
            return location;
        }
        return location && location.name ? location.name : '';
    }

    public onLocationSelected(event: MatAutocompleteSelectedEvent) {
        this.selectedLocation = event.option.value;
        this.locationFormControl.markAsTouched();
    }

    public onCustomerSelected(event: MatSelectChange) {
        this.selectedCustomer = event.value;
    }

    public onFileSelected(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.files) {
            this.selectedFiles = Array.from(target.files);
            target.value = '';
        }
    }

    //Transform playtimes array to string with more spacing
    public transformPlaytimes(): string {
        if (!this.selectedPerformer || !this.selectedPerformer.playtimes || this.selectedPerformer.playtimes.length === 0) {
            return '';
        }
        // Join the array with comma, space before and after each comma
        return this.selectedPerformer.playtimes.join(' , ');
    }

    public removeFile(index: number) {
        this.selectedFiles.splice(index, 1);
    }

    public getAllPerformers(agency: Agency) {
        this.performerService.getAllPerformers(this.selectedAgency).then((performers) => {
            this.performers = performers;
        });
    }

    public getAllLocations(agency: Agency): Promise<any> {
        return this.locationService.getAllLocations(agency).then(locations => {
            this.locations = locations;
        });
    }

    public async getAllCustomers(agency: Agency) {
        return this.customers = await this.customerService.getAllCustomers(agency);
    }

    public getAllProductCategories(agency: Agency) {
        this.productService.getAllProductCategories(agency).then(categories => {
            this.productCategories = categories;
        });
    }

    public getAllProductSubCategories(agency: Agency, category: Product_Category, newlyAdded: boolean) {
        return this.productService.getAllProductSubCategories(agency, category).then(subcategories => {
            const selectedSubCategoryId = this.selectedProductSubCategory?.id;

            // Merge new subcategories with existing ones, preserving the selected subcategory

            if(newlyAdded) {
                this.productSubCategories = [
                    ...subcategories,
                    ...(this.selectedProductSubCategory && !subcategories.some(sub => sub.id === selectedSubCategoryId)
                        ? [this.selectedProductSubCategory]
                        : [])
                ];
            } else {
                this.productSubCategories = subcategories;
            }


            // Ensure the selected subcategory is set after updating the list
            if (selectedSubCategoryId) {
                const subcategoryToSelect = this.productSubCategories.find(sub => sub.id === selectedSubCategoryId);
                if (subcategoryToSelect) {
                    if(newlyAdded) {
                        this.addBookingProductFormGroup.get('product_subcategory')?.setValue(subcategoryToSelect, { emitEvent: false });
                    }

                }
            }

            this.changeDetectorRef.detectChanges();
        });
    }

    public listenToSelectedCurrency() {
        this.currencyService.selectedCurrency$.subscribe(currency => {
            if (currency) {
                this.selectedCurrency = currency;
            }
        });
    }

    public isBumaFeeSelected(): boolean {
        return this.addPerformersFormGroup.controls['buma_fee'].value;
    }

    public addPerformerBooking() {
        this.addPerformerSubmitted = true;
        if(this.addPerformersFormGroup.invalid) {

        } else {
            if(this.selectedPerformer) {
                let performerStartTime = this.addPerformersFormGroup.controls['starttime'].value;
                let performerEndTime = this.addPerformersFormGroup.controls['endtime'].value;

                let bookingPerformer: Booking_Performer = {
                    name: this.selectedPerformer.name,
                    performer_id: this.selectedPerformer.id,
                    start_time: this.addPerformersFormGroup.controls['starttime'].value,
                    end_time: this.addPerformersFormGroup.controls['endtime'].value,
                    status: this.selectedBookingPerformerStatus,
                    playtimes: this.selectedPerformer.playtimes
                };

                if(this.isBumaFeeSelected()) {
                    bookingPerformer.buma_fee = 7;
                }

                //Check if performer is already added or not
                const performerExists = this.bookingPerformers
                    .some(performer => performer.performer_id === bookingPerformer.performer_id);

                if (performerExists) {
                    this.notificationService.openFailureNotitication(this.selectedPerformer.name + ' is already added to booking');
                } else {
                    //Make sure that performance start and end time are filled in
                    if(performerStartTime && performerEndTime) {
                        this.bookingPerformers.push(bookingPerformer);
                        this.addBookingInformationFormGroup.controls['performers'].setValue(this.bookingPerformers);
                        this.addBookingInformationFormGroup.controls['performers'].updateValueAndValidity();

                        this.resetPerformerForm();

                        this.addPerformerSubmitted = false;

                    } else {
                        this.notificationService.openFailureNotitication('Please select a start and end time for the performer');
                    }
                }
            }
        }
    }

    private locationValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (typeof value === 'string') {
                // If the value is a string, check if it matches any location name
                const matchingLocation = this.locations.find(loc => loc.name.toLowerCase() === value.toLowerCase());
                return matchingLocation ? null : { invalidLocation: true };
            }
            // If the value is an object (a selected location), it's valid
            return null;
        };
    }

    private resetPerformerForm() {
        // Reset the form
        this.addPerformersFormGroup.reset({
            performer: null,
            starttime: null,
            endtime: null,
            performer_status: null
        });

        // Reset selectedPerformer and selectedBookingPerformerStatus
        this.selectedPerformer = null;
        this.selectedBookingPerformerStatus = '';

        // Manually trigger change detection
        this.changeDetectorRef.detectChanges();

        // Reset select inputs after change detection
        setTimeout(() => {
            if (this.performerSelect) {
                this.performerSelect.writeValue(null);
            }
            if (this.statusSelect) {
                this.statusSelect.writeValue(null);
            }
        });
    }

    public addContactPerson() {
        this.addContactPersonSubmitted = true;

        if(this.addContactPersonFormGroup.invalid) {
        } else {
            let bookingContact: Booking_Contact = {
                contact_name: this.addContactPersonFormGroup.controls['contact_name'].value,
                contact_role: this.addContactPersonFormGroup.controls['contact_role'].value,
                contact_phone: this.addContactPersonFormGroup.controls['contact_phone'].value,
                contact_email: this.addContactPersonFormGroup.controls['contact_email'].value,
                contact_company: this.addContactPersonFormGroup.controls['contact_company'].value
            };
            this.bookingContacts.push(bookingContact);

        }
    }

    public addGuest() {
        this.addGuestSubmitted = true;

        if(this.addGuestFormGroup.invalid) {

        } else {
            let bookingGuest: Booking_Guest = {
                name: this.addGuestFormGroup.controls['guestname'].value,
                email: this.addGuestFormGroup.controls['guestemail'].value,
                description: this.addGuestFormGroup.controls['guestdescription'].value
            };

            this.bookingGuests.push(bookingGuest);
        }
    }

    public openAddPerformerFlightDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        if(this.bookingPerformers.length > 0) {
            const addPerformerFlightDialogRef = this.matDialog.open(AddPerformerFlightComponentDialog, {
                width: '600px',
                enterAnimationDuration,
                exitAnimationDuration,
                data: {
                    performers: this.bookingPerformers
                }
            });

            addPerformerFlightDialogRef.afterClosed().subscribe(result => {
                if(result != null && typeof result === 'object') {
                    this.bookingPerformerFlights.push(result);
                }
            });
        } else {
            this.notificationService.openFailureNotitication('Please add a performer to the booking first');
            this.stepper.selectedIndex = 0;
        }
    }

    public openAddPerformerHotelDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        if(this.bookingPerformers.length > 0) {
            const addPerformerHotelDialogRef = this.matDialog.open(AddPerformerHotelDialogComponent, {
                width: '600px',
                enterAnimationDuration,
                exitAnimationDuration,
                data: this.bookingPerformers
            });

            addPerformerHotelDialogRef.afterClosed().subscribe(result => {
                if(result != null && typeof result === 'object') {
                    this.bookingPerformerHotels.push(result);
                }
            });
        } else {
            this.notificationService.openFailureNotitication('Please add a performer to the booking first');
            this.stepper.selectedIndex = 0;
        }
    }

    public openEditBookingPerformerDialog(enterAnimationDuration: string, exitAnimationDuration: string, bookingPerformer: Booking_Performer) {
        const editBookingDialogRef = this.matDialog.open(EditBookingPerformerDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: bookingPerformer
        });

        editBookingDialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                //Update the booking performer in the array
                this.bookingPerformers = this.bookingPerformers.map(performer => {
                    if(performer.performer_id === result.performer_id) {
                        performer.start_time = result.start_time;
                        performer.end_time = result.end_time;
                    }
                    return performer;
                });
            }
        });
    }

    public openEditBookingProductDialog(enterAnimationDuration: string, exitAnimationDuration: string, bookingProduct: Booking_Product) {
        const editBookingProductDialogRef = this.matDialog.open(EditBookingProductDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: bookingProduct
        });

        editBookingProductDialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                this.bookingProducts = this.bookingProducts.map(product => {
                    if(product.name === result.name) {
                        product = result;
                    }
                    this.updateGroupedProducts();
                    this.calculateTotals();
                    return product;
                });
            }
        });
    }

    public openAddLocationDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
        const locationDialogRef = this.matDialog.open(AddLocationDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
        });

        locationDialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                // Add the new location to the locations array
                this.locations.push(result);

                // Update the filteredLocations observable
                this.filteredLocations = this.locationFormControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filteredLocations(value || ''))
                );

                // Set the new location as the selected value
                this.locationFormControl.setValue(result);

                // Update the selectedLocation
                this.selectedLocation = result;

                // Trigger change detection
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    public openAddCustomerDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        let agencyId: string = '';

        if(this.selectedAgency) {
            agencyId = this.selectedAgency.id ?? '';
        }

        const customerDialogRef = this.matDialog.open(AddCustomerDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: agencyId
        });

        customerDialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                this.customers.push(result);
                this.customerFormControl.setValue(result); // Set the newly added customer as the selected value
                this.selectedCustomer = result; // Update the selectedCustomer property
                this.onCustomerSelected({ value: result } as MatSelectChange); // Trigger the selection change event
            }
        });
    }

    public openAddCategoriesDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        const addCategoriesDialogRef = this.matDialog.open(AddProductCategoriesDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: this.selectedAgency
        });

        addCategoriesDialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (this.isNewSubCategoryResult(result)) {
                    this.handleNewSubcategoryResult(result, true);
                } else if (this.isProductCategory(result)) {
                    this.handleNewCategory(result as Product_Category);
                } else if (this.isProductSubcategory(result)) {
                    this.handleNewSubcategory(result as Product_Subcategory);
                }

                this.changeDetectorRef.detectChanges();
            }
        });
    }

    private handleNewSubcategoryResult(result: { newSubCategory: Product_Subcategory, selectedCategory: Product_Category }, newlyAdded: boolean) {
        // Check if category already exists, if not, add it
        const existingCategory = this.productCategories.find(cat => cat.id === result.selectedCategory.id);
        if (!existingCategory) {
            this.productCategories = [...this.productCategories, result.selectedCategory];
        }

        // Use the existing category or the new one
        const categoryToUse = existingCategory || result.selectedCategory;

        // Add new subcategory
        this.productSubCategories = [...this.productSubCategories, result.newSubCategory];

        // Set selected values
        this.selectedProductCategory = categoryToUse;
        this.selectedProductSubCategory = result.newSubCategory;

        // Update form controls
        this.addBookingInformationFormGroup.patchValue({
            product_category: categoryToUse,
            product_subcategory: result.newSubCategory
        });

        // Enable subcategory control
        this.addBookingInformationFormGroup.get('product_subcategory')?.enable();

        // Load subcategories for the selected category
        this.loadSubcategoriesForCategory(categoryToUse, newlyAdded);

        this.addBookingProductFormGroup.controls['product_subcategory'].setValue(this.selectedProductSubCategory);
        //this.addBookingInformationFormGroup.get('product_subcategory')?.setValue(result.newSubCategory, { emitEvent: false });
    }

    private handleNewCategory(category: Product_Category) {
        this.productCategories = [...this.productCategories, category];
        this.selectedProductCategory = category;
        this.addBookingInformationFormGroup.patchValue({
            product_category: this.selectedProductCategory
        });

        this.addBookingProductFormGroup.get('product_subcategory')?.enable();
        this.addBookingProductFormGroup.controls['product_subcategory'].setValue('');
    }

    private handleNewSubcategory(subcategory: Product_Subcategory) {
        this.productSubCategories = [...this.productSubCategories, subcategory];
        this.selectedProductSubCategory = subcategory;
        this.addBookingProductFormGroup.patchValue({
            product_subcategory: this.selectedProductSubCategory
        });
    }

    public openAddPerformerProductDialogComponent(enterAnimationDuration: string, exitAnimationDuration: string) {

        if(this.bookingPerformers.length === 0) {
            this.stepper.selectedIndex = 0;
            this.notificationService.openFailureNotitication('Please add a performer to the booking first');
        } else {
            const performerProductDialog = this.matDialog.open(AddPerformerProductDialogComponent, {
                width: '1100px',
                enterAnimationDuration,
                exitAnimationDuration,
                data: this.bookingPerformers
            });

            performerProductDialog.afterClosed().subscribe(result => {
                let performerProducts: Performer_Product_With_Performer[] = result as Performer_Product_With_Performer[];
                performerProducts.forEach(performerProduct => {

                    if(this.isPerformerProductAdded(performerProduct)) {
                        this.notificationService.openFailureNotitication('Performer product: ' + performerProduct.performer_product.name + ' is already added');
                        return;
                    } else {
                        let bookingProduct: Booking_Product = {
                            name: performerProduct.performer_product.name,
                            description: performerProduct.performer_product.description,
                            price: performerProduct.performer_product.price,
                            cost: performerProduct.performer_product.cost,
                            category: "Performer Product",
                            subcategory: performerProduct.performer.name,
                            currency_code: this.selectedCurrency.currency_code,
                            currency_symbol: this.selectedCurrency.symbol
                        };

                        this.bookingProducts.push(bookingProduct);
                        this.updateGroupedProducts();
                        this.calculateTotals();
                    }
                });
            });
        }
    }

    public openEditContactDialog(enterAnimationDuration: string, exitAnimationDuration: string, contact: Booking_Contact) {
        const editContactDialogRef = this.matDialog.open(AddContactDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                bookingContact: contact
            }
        });

        editContactDialogRef.afterClosed().subscribe((result: Booking_Contact) => {
            if(result != null && typeof result === 'object') {
                console.log('result tester: ' + JSON.stringify(result));

                const contactIndex = this.bookingContacts.findIndex(existingContact =>
                    existingContact.contact_name === contact.contact_name &&
                    existingContact.contact_email === contact.contact_email
                );

                // If found, update the contact at that index
                if (contactIndex !== -1) {
                    this.bookingContacts[contactIndex] = result;
                }
            }
        });
    }

    public isPerformerProductAdded(performerProduct: Performer_Product_With_Performer): boolean {
        return this.bookingProducts.some(product => product.name === performerProduct.performer_product.name);
    }

    public addBookingProduct() {
        this.addBookingProductSubmitted = true;
        if(this.addBookingProductFormGroup.invalid) {

        } else {
            let bookingProduct: Booking_Product = {
                name: this.addBookingProductFormGroup.controls['product_name'].value,
                description: this.addBookingProductFormGroup.controls['product_description'].value,
                price: this.addBookingProductFormGroup.controls['product_price'].value,
                cost: this.addBookingProductFormGroup.controls['product_cost'].value,
                category: this.addBookingProductFormGroup.controls['product_category'].value.name,
                subcategory: this.addBookingProductFormGroup.controls['product_subcategory'].value.name,
                currency_code: this.selectedCurrency.currency_code,
                currency_symbol: this.selectedCurrency.symbol
            };

            // Add the booking product to the array only if the fields are all filled in
            this.bookingProducts.push(bookingProduct);
            this.updateGroupedProducts();
            this.calculateTotals();

            // Reset the form fields
            this.resetBoookingProductFormWithoutValidation();
        }
    }

    private resetBoookingProductFormWithoutValidation() {
        // Reset the form
        this.addBookingProductFormGroup.reset();

        // Mark the form and all its controls as pristine
        this.addBookingProductFormGroup.markAsPristine();
        Object.keys(this.addBookingProductFormGroup.controls).forEach(key => {
            const control = this.addBookingProductFormGroup.get(key);
            control?.markAsPristine();
            control?.markAsUntouched();
        });

        // Reset the submission flag
        this.addBookingProductSubmitted = false;
    }

    public goToProductsOverview() {
        this.stepper.selectedIndex = 1;
    }

    public goToSaveStep() {
        this.stepper.selectedIndex = 7;
    }

    private updateGroupedProducts() {
        this.groupedProducts = this.groupProductsByCategoryAndSubcategory(this.bookingProducts);
    }

    public minArrayLengthValidator(minLength: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value;
            return Array.isArray(value) && value.length >= minLength ? null : { minArrayLength: { value: value } };
        };
    }

    public minPlayTimesValidator(min: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const performers = control.value;
            return Array.isArray(performers) && performers.length >= min ? null : { minPerformers: { requiredMin: min } };
        };
    }

    public groupProductsByCategoryAndSubcategory(products: Booking_Product[]):
        { category: string, subcategories: { subcategory: string, products: Booking_Product[] }[] }[] {

        const groupedByCategory = products.reduce((groups, product) => {
            const existingGroupIndex = groups.findIndex(group => group.category === product.category);
            if (existingGroupIndex !== -1) {
                const existingSubcategoryIndex =
                    groups[existingGroupIndex].subcategories.findIndex(subcategory => subcategory.subcategory === product.subcategory);
                if (existingSubcategoryIndex !== -1) {
                    groups[existingGroupIndex].subcategories[existingSubcategoryIndex].products.push(product);
                } else {
                    groups[existingGroupIndex].subcategories.push({ subcategory: product.subcategory, products: [product] });
                }
            } else {
                groups.push({ category: product.category, subcategories: [{ subcategory: product.subcategory, products: [product] }] });
            }
            return groups;
        }, [] as { category: string, subcategories: { subcategory: string, products: Booking_Product[] }[] }[]);

        // Sort subcategories within each category
        groupedByCategory.forEach(group => {
            group.subcategories.sort((a, b) => a.subcategory.localeCompare(b.subcategory));
        });

        return groupedByCategory;
    }

    public calculateTotals() {
        // Reset totals
        this.bookingTotalPrice = 0;
        this.bookingTotalCost = 0;

        // Iterate over bookingProducts array
        this.bookingProducts.forEach(product => {
            // Add price and cost of each product to respective totals
            this.bookingTotalPrice += product.price;
            this.bookingTotalCost += product.cost;
        });
    }


    public setBookingObject(status: string): Booking {
        if (!this.selectedAgency || !this.selectedAgency.id) {
            throw new Error('Selected agency or its ID is undefined');
        }

        if (!this.selectedLocation || !this.selectedLocation.id) {
            throw new Error('Selected location or its ID is undefined');
        }

        if (!this.selectedCustomer || !this.selectedCustomer.id) {
            throw new Error('Selected customer or its ID is undefined');
        }

        let booking: Booking = {
            event_name: this.addBookingInformationFormGroup.controls['event_name'].value,
            event_website: this.handleEmptyString(this.addBookingInformationFormGroup.controls['event_website'].value),
            event_budget: this.handleEmptyString(this.addBookingInformationFormGroup.controls['event_budget'].value),
            event_date: this.addBookingInformationFormGroup.controls['event_date'].value,
            opening_time: this.handleEmptyString(this.addBookingInformationFormGroup.controls['opening_time'].value),
            begin_time: this.handleEmptyString(this.addBookingInformationFormGroup.controls['begin_time'].value),
            end_time: this.handleEmptyString(this.addBookingInformationFormGroup.controls['end_time'].value),
            booking_description: this.handleEmptyString(this.addBookingInformationFormGroup.controls['booking_description'].value),
            line_up_event: this.handleEmptyString(this.addBookingInformationFormGroup.controls['line_up_event'].value),
            internal_notes: this.handleEmptyString(this.addBookingInformationFormGroup.controls['internal_notes'].value),
            construction_start_time: this.handleEmptyString(this.addBookingInformationFormGroup.controls['construction_start_time'].value),
            sound_check_time: this.handleEmptyString(this.addBookingInformationFormGroup.controls['sound_check_time'].value),
            podium_ready_time: this.handleEmptyString(this.addBookingInformationFormGroup.controls['podium_ready_time'].value),
            performance_floor: this.handleEmptyString(this.addBookingInformationFormGroup.controls['performance_floor'].value),
            distance_to_stage: this.handleEmptyString(this.addBookingInformationFormGroup.controls['distance_to_stage'].value),
            elevator_availability: this.handleEmptyString(this.addBookingInformationFormGroup.controls['elevator_availability'].value),
            call_sheet_memo: this.handleEmptyString(this.addBookingInformationFormGroup.controls['call_sheet_memo'].value),
            visitor_expectation: this.handleEmptyString(this.addBookingInformationFormGroup.controls['visitor_expectation'].value),
            available_tickets: this.handleEmptyString(this.addBookingInformationFormGroup.controls['available_tickets'].value),
            status: status,
            agency_id: this.selectedAgency.id,
            team_lead_id: '33441b6a-c4ac-4122-81bb-f907336aa143',
            location_id: this.selectedLocation.id,
            customer_id: this.selectedCustomer.id,
            currency_code: this.selectedCurrency.currency_code,
            currency_symbol: this.selectedCurrency.symbol
        };

        return booking;
    }

    public handleEmptyString(value: any): any {
        return value === '' ? null : value;
    }

    public addBooking(bookingStatus: BookingStatus) {
        this.addBookingSubmitted = true;
        this.addBookingInformationFormGroup.markAllAsTouched();

        this.customerFormControl.markAsTouched();

        if(this.addBookingInformationFormGroup.invalid || this.bookingPerformers.length === 0) {
            if (this.bookingPerformers.length === 0) {
                this.notificationService.openFailureNotitication('You must add at least one performer.');
            }
            this.stepper.selectedIndex = 0;
        } else  {
            this.isLoading = true;

            let booking: Booking = this.setBookingObject(bookingStatus);

            this.bookingService.addBooking(booking,
                this.bookingPerformers,
                this.bookingContacts,
                this.bookingProducts,
                this.bookingPerformerFlights,
                this.bookingPerformerHotels,
                this.bookingGuests).then((booking) => {
                    //Check if there are any files added to the booking by the user
                    if(this.selectedFiles.length > 0) {
                        //Upload the physical files to the storage
                        this.bookingService.uploadBookingAttachments(this.selectedFiles, booking, this.selectedAgency)
                            .then((attachments) => {
                                //Insert the attachments to the database and link them to the booking
                                this.bookingService.insertBookingAttachment(attachments)
                                    .then((attachmentsAdded) => {
                                        this.goToBookingDetails(booking);
                                        this.notificationService
                                            .openSuccessfulNotification('Successfully added booking: ' + booking[0].event_name);
                                    }).catch(error => {
                                        this.notificationService
                                            .openFailureNotitication(error.message);
                                    });
                            }).catch(error => {
                                this.notificationService
                                    .openFailureNotitication(error.message);
                            });
                    } else {
                        this.goToBookingDetails(booking);
                        this.notificationService
                            .openSuccessfulNotification('Successfully added booking: ' + booking[0].event_name);
                        this.clearAutosave();
                    }
                }).catch(error => {
                    this.notificationService.openFailureNotitication(error.message);
                }).finally(() => {
                    this.isLoading = false;
                });
        }
    }

    public editBookingProduct(bookingProduct: Booking_Product) {
        this.openEditBookingProductDialog('300ms', '300ms', bookingProduct);
    }

    public removeBookingProduct(bookingProduct: Booking_Product) {
        this.bookingProducts = this.bookingProducts.filter(product => product.name !== bookingProduct.name);
        this.updateGroupedProducts();
        this.calculateTotals();
    }

    public editAddedBookingPerformer(bookingPerformer: Booking_Performer) {
        this.openEditBookingPerformerDialog('300ms', '300ms', bookingPerformer);
    }

    public removeAddedBookingPerformer(bookingPerformer: Booking_Performer) {
        this.bookingPerformers = this.bookingPerformers.filter(performer => performer.performer_id !== bookingPerformer.performer_id);
        this.addBookingInformationFormGroup.controls['performers'].setValue(this.bookingPerformers);
        this.addBookingInformationFormGroup.controls['performers'].updateValueAndValidity();
    }

    public compareCustomers(c1: Customer, c2: Customer): boolean {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }

    public removeAddedContactPerson(index: number) {
        if (index > -1 && index < this.bookingContacts.length) {
            this.bookingContacts.splice(index, 1);
        }
    }

    public removeAddedGuest(index: number) {
        if (index > -1 && index < this.bookingGuests.length) {
            this.bookingGuests.splice(index, 1);
        }
    }

    public removeAddedHotel(index: number) {
        if (index > -1 && index < this.bookingPerformerHotels.length) {
            this.bookingPerformerHotels.splice(index, 1);
        }
    }

    public removeAddedFlight(index: number) {
        if (index > -1 && index < this.bookingPerformerFlights.length) {
            this.bookingPerformerFlights.splice(index, 1);
        }
    }

    public goToBookingDetails(booking: Booking[]){
        this.router.navigate(['/booking-details', booking[0].id]);
    }

    public getPerformerName(performerId: string): string {
        const performer = this.performers.find(p => p.id === performerId);
        return performer ? performer.name : 'Not found';
    }

    public getBookingPerformerStatus() {
        this.bookingService.getPerformerBookingStatuses().then((statuses: string[]) => {
            this.bookingPerformerStatuses = statuses;
        });
    }

    private isProductCategory(obj: any): obj is Product_Category {
        return (
            typeof obj === 'object' &&
            'name' in obj &&
            'description' in obj &&
            'agency_id' in obj &&
            !('product_category_id' in obj)
        );
    }

    private isProductSubcategory(obj: any): obj is Product_Subcategory {
        return (
            typeof obj === 'object' &&
            'name' in obj &&
            'description' in obj &&
            'agency_id' in obj &&
            'product_category_id' in obj
        );
    }

    private isNewSubCategoryResult(result: any): result is { newSubCategory: Product_Subcategory, selectedCategory: Product_Category } {
        return result
            && typeof result === 'object'
            && 'newSubCategory' in result
            && 'selectedCategory' in result;
    }

    public onCategoryChange() {
        const category = this.addBookingProductFormGroup.get('product_category')?.value;
        this.addBookingProductFormGroup.get('product_subcategory')?.setValue(null);
        if (category) {
            this.loadSubcategoriesForCategory(category);
            this.addBookingProductFormGroup.get('product_subcategory')?.enable();
        } else {
            this.productSubCategories = [];
            this.addBookingProductFormGroup.get('product_subcategory')?.disable();
        }

        this.addBookingProductFormGroup.controls['product_subcategory'].setValue('');
    }

    private loadSubcategoriesForCategory(category: Product_Category, newlyAdded: boolean = false) {
        this.getAllProductSubCategories(this.selectedAgency, category, newlyAdded);
    }
}
