import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {MatMenuModule} from '@angular/material/menu';
import {FullCalendarModule} from '@fullcalendar/angular';
import {HttpClientModule} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {NgxEditorModule} from 'ngx-editor';
import {MatCardModule} from '@angular/material/card';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {NgApexchartsModule} from "ng-apexcharts";
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {NgxEchartsModule} from 'ngx-echarts';
import {NgxGaugeModule} from 'ngx-gauge';
import {NgChartsModule} from 'ng2-charts';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import {QuillModule} from 'ngx-quill';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgxDropzoneModule} from 'ngx-dropzone';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SidebarComponent} from './components/common/sidebar/sidebar.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {HeaderComponent} from './components/common/header/header.component';
import {PoStatsComponent} from './components/pages/ecommerce/products-orders/po-stats/po-stats.component';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {PersonalInfoComponent} from './components/pages/profile/personal-info/personal-info.component';
import {ActivityTimelineComponent} from './components/pages/profile/activity-timeline/activity-timeline.component';
import {StatsComponent} from './components/pages/profile/stats/stats.component';
import {OverviewComponent} from './components/pages/profile/overview/overview.component';
import {TasksComponent} from './components/pages/profile/tasks/tasks.component';
import {AccountComponent} from './components/pages/account/account.component';
import {SecurityComponent} from './components/pages/security/security.component';
import {ConnectionsComponent} from './components/pages/connections/connections.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';
import {TermsConditionsComponent} from './components/pages/terms-conditions/terms-conditions.component';
import {NotFoundComponent} from './components/common/not-found/not-found.component';
import {InternalErrorComponent} from './components/common/internal-error/internal-error.component';
import {ResetPasswordComponent} from './components/authentication/reset-password/reset-password.component';
import {ForgotPasswordComponent} from './components/authentication/forgot-password/forgot-password.component';
import {LoginComponent} from './components/authentication/login/login.component';
import {RegisterComponent} from './components/authentication/register/register.component';
import {SigninSignupComponent} from './components/authentication/signin-signup/signin-signup.component';
import {LogoutComponent} from './components/authentication/logout/logout.component';
import {ConfirmMailComponent} from './components/authentication/confirm-mail/confirm-mail.component';
import {LockScreenComponent} from './components/authentication/lock-screen/lock-screen.component';
import {CustomizerSettingsComponent} from './components/customizer-settings/customizer-settings.component';
import {AuthGuardService} from "./components/guards/auth.guard";
import {SupabaseAuthService} from "./components/services/supabase.auth.service";
import {BookingsComponent} from "./components/pages/bookings/bookings-overview/bookings.component";
import {AddBookingComponent} from "./components/pages/bookings/add-booking/add-booking.component";
import {CategoryService} from "./components/services/category.service";
import {AddCategoryDialogComponent} from "./components/pages/categories/add-category/add-category-dialog.component";
import {AddLocationDialogComponent} from "./components/pages/locations/add-location/add-location-dialog.component";
import {AddAgencyDialogComponent} from "./components/pages/agency/add-agency/add-agency-dialog.component";
import {LocationsComponent} from "./components/pages/locations/locations.component";
import {PerformersComponent} from "./components/pages/performers/performers.component";
import {NotificationService} from "./components/services/notification.service";
import {ToastrModule} from "ngx-toastr";
import {AddPerformerComponent} from "./components/pages/performers/add-performer/add-performer.component";
import {
    AddPerformerMemberDialogComponent
} from "./components/pages/performers/add-performer-member/add-performer-member-dialog.component";
import {PerformerService} from "./components/services/performer.service";
import {BookingService} from "./components/services/booking.service";
import {AttachmentService} from "./components/services/attachment.service";
import {LocationService} from "./components/services/location.service";
import {BookingDetailsComponent} from "./components/pages/bookings/booking-details/booking-details.component";
import {BookingInfoComponent} from "./components/pages/bookings/booking-details/booking-info/booking-info.component";
import {
    ContactsInfoComponents
} from "./components/pages/bookings/booking-details/contacts-info/contacts-info.components";
import {
    FinancialInfoComponent
} from "./components/pages/bookings/booking-details/financial-info/financial-info.component";
import {AddProductDialogComponent} from "./components/pages/product/add-product/add-product-dialog.component";
import {PerformerDetailsComponent} from "./components/pages/performers/performer-details/performer-details.component";
import {
    PerformerInfoComponent
} from "./components/pages/performers/performer-details/performer-info/performer-info.component";
import {
    PerformerProductsInfoComponent
} from "./components/pages/performers/performer-details/performerproducts-info/performerproducts-info.component";
import {CustomersComponent} from "./components/pages/customers/customers.component";
import {AddCustomerDialogComponent} from "./components/pages/customers/add-customer/add-customer-dialog.component";
import {CustomerService} from "./components/services/customer.service";
import {ngxLoadingAnimationTypes, NgxLoadingModule} from "ngx-loading";
import {
    ProductSummaryComponent
} from "./components/pages/bookings/add-booking/product-summary/product-summary.component";
import {
    BookingsListComponent
} from "./components/pages/bookings/bookings-overview/bookings-list/bookings-list.component";
import {
    BookingsCalendarComponent
} from "./components/pages/bookings/bookings-overview/bookings-calendar/bookings-calendar.component";
import {SearchService} from "./components/services/search.service";
import {ProductsInfoComponent} from "./components/pages/bookings/booking-details/products-info/products-info.component";
import {
    AttachmentsInfoComponent
} from "./components/pages/bookings/booking-details/attachments-info/attachments-info.component";
import {
    EditBookingPerformerDialogComponent
} from "./components/pages/bookings/add-booking/edit-bookingperformer/edit-booking-performer-dialog.component";
import {
    EditBookingProductDialogComponent
} from "./components/pages/bookings/add-booking/edit-bookingproduct/edit-booking-product-dialog.component";
import {
    AddPerformerFlightComponentDialog
} from "./components/pages/bookings/add-booking/add-performerflight/add-performer-flight-component-dialog.component";
import {
    AddPerformerHotelDialogComponent
} from "./components/pages/bookings/add-booking/add-performerhotel/add-performer-hotel-dialog.component";
import {TravelInfoComponent} from "./components/pages/bookings/booking-details/travel-info/travel-info.component";
import {
    AddAgencyExternalDialogComponent
} from "./components/pages/agency/add-agency-external/add-agency-external-dialog.component";
import {AgencyGuardService} from "./components/guards/agency.guard";
import {
    PerformerAttachmentsInfoComponent
} from "./components/pages/performers/performer-details/performerattachments-info/performerattachments-info.component";
import {
    PerformerBookingsInfoComponent
} from "./components/pages/performers/performer-details/performerbookings-info/performerbookings-info.component";
import {CdkMenuModule} from "@angular/cdk/menu";
import {
    AddPerformerProductDialogComponent
} from "./components/pages/bookings/add-booking/add-performerproduct/add-performer-product-dialog.component";
import {
    AddProductCategoriesDialogComponent
} from "./components/pages/bookings/add-booking/add-productcategories/add-product-categories-dialog.component";
import {ProductService} from "./components/services/product.service";
import {GuestInfoComponents} from "./components/pages/bookings/booking-details/guest-info/guest-info.components";
import {
    AddInvoiceDialogComponent
} from "./components/pages/bookings/booking-details/add-invoice/add-invoice-dialog.component";
import {InvoiceService} from "./components/services/invoice.service";
import {InvoicesComponent} from "./components/pages/invoices/invoices-overview/invoices.component";
import {
    AddBookingProductDialogComponent
} from "./components/pages/bookings/booking-details/add-product/add-booking-product-dialog.component";
import {
    AddGuestDialogComponent
} from "./components/pages/bookings/booking-details/add-guest/add-guest-dialog.component";
import {
    AddContactDialogComponent
} from "./components/pages/bookings/booking-details/add-contact/add-contact-dialog.component";
import {
    AddPerformerDialogComponent
} from "./components/pages/bookings/booking-details/add-performer/add-performer-dialog.component";
import {BookingCommunicationService} from "./components/services/booking-communication.service";
import {
    AddAttachmentDialogComponent
} from "./components/pages/bookings/booking-details/add-attachment/add-attachment-dialog.component";
import {EmailService} from "./components/services/email.service";
import {InvitationService} from "./components/services/invitation.service";
import {InvitationsComponent} from "./components/pages/invitations/invitations-overview/invitations.component";
import {PerformerBookingsComponent} from "./components/pages/bookings/performer-bookings/performer-bookings.component";
import {
    PerformerBookingsListComponent
} from "./components/pages/bookings/performer-bookings/performer-bookings-list/performer-bookings-list.component";
import {
    PerformerBookingsCalendarComponent
} from "./components/pages/bookings/performer-bookings/performer-bookings-calendar/performer-bookings-calendar.component";
import {
    PerformerBookingDetails
} from "./components/pages/bookings/performer-bookings/performer-booking-details/performer-booking-details";
import {
    PerformerBookingInfo
} from "./components/pages/bookings/performer-bookings/performer-booking-info/performer-booking-info";
import {
    PerformerBookingTravel
} from "./components/pages/bookings/performer-bookings/performer-booking-travel/performer-booking-travel";
import {
    ContractsInfoComponent
} from "./components/pages/bookings/booking-details/contracts-info/contracts-info.component";
import {ContractService} from "./components/services/contract.service";
import {FinanceService} from "./components/services/finance.service";
import {ContractsComponent} from "./components/pages/contracts/contracts.component";
import {
    ContractCheckDialogComponent
} from "./components/pages/contracts/contract-check/contract-check-dialog.component";
import {TargetDashboardComponent} from "./components/dashboard/target-dashboard-components/target-dashboard.component";
import {
    UpcomingDashboardComponent
} from "./components/dashboard/upcoming-dashboard-component/upcoming-dashboard.component";
import {
    UpcomingDashboardCalendarComponent
} from "./components/dashboard/upcoming-dashboard-calendar/upcoming-dashboard-calendar.component";

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        SidebarComponent,
        FooterComponent,
        HeaderComponent,
        PoStatsComponent,
        ProfileComponent,
        PersonalInfoComponent,
        ActivityTimelineComponent,
        StatsComponent,
        OverviewComponent,
        TasksComponent,
        AccountComponent,
        SecurityComponent,
        ConnectionsComponent,
        PrivacyPolicyComponent,
        TermsConditionsComponent,
        NotFoundComponent,
        InternalErrorComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        LoginComponent,
        RegisterComponent,
        SigninSignupComponent,
        LogoutComponent,
        ConfirmMailComponent,
        LockScreenComponent,
        CustomizerSettingsComponent,
        //Newly Added Components
        BookingsComponent,
        LocationsComponent,
        AddBookingComponent,
        AddCategoryDialogComponent,
        AddLocationDialogComponent,
        AddAgencyDialogComponent,
        PerformersComponent,
        AddPerformerComponent,
        AddPerformerMemberDialogComponent,
        BookingDetailsComponent,
        BookingInfoComponent,
        ContactsInfoComponents,
        FinancialInfoComponent,
        AddProductDialogComponent,
        PerformerDetailsComponent,
        PerformerInfoComponent,
        PerformerProductsInfoComponent,
        CustomersComponent,
        AddCustomerDialogComponent,
        ProductSummaryComponent,
        BookingsListComponent,
        BookingsCalendarComponent,
        ProductsInfoComponent,
        AttachmentsInfoComponent,
        EditBookingPerformerDialogComponent,
        EditBookingProductDialogComponent,
        AddPerformerFlightComponentDialog,
        AddPerformerHotelDialogComponent,
        TravelInfoComponent,
        AddAgencyExternalDialogComponent,
        PerformerAttachmentsInfoComponent,
        PerformerBookingsInfoComponent,
        AddPerformerProductDialogComponent,
        AddProductCategoriesDialogComponent,
        GuestInfoComponents,
        AddInvoiceDialogComponent,
        InvoicesComponent,
        AddBookingProductDialogComponent,
        AddGuestDialogComponent,
        AddContactDialogComponent,
        AddPerformerDialogComponent,
        AddAttachmentDialogComponent,
        InvitationsComponent,
        PerformerBookingsComponent,
        PerformerBookingsListComponent,
        PerformerBookingsCalendarComponent,
        PerformerBookingDetails,
        PerformerBookingInfo,
        PerformerBookingTravel,
        ContractsInfoComponent,
        ContractsComponent,
        ContractCheckDialogComponent,
        TargetDashboardComponent,
        UpcomingDashboardComponent,
        UpcomingDashboardCalendarComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatMenuModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        BrowserAnimationsModule,
        NgApexchartsModule,
        MatProgressBarModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        NgScrollbarModule,
        FormsModule,
        FullCalendarModule,
        MatNativeDateModule ,
        ReactiveFormsModule,
        NgxEditorModule,
        DragDropModule,
        HttpClientModule,
        CdkAccordionModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        NgxGaugeModule,
        CdkMenuModule,
        NgChartsModule,
        NgxMatTimepickerModule,
        QuillModule.forRoot(),
        ColorPickerModule,
        NgxDropzoneModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circleSwish,
            backdropBackgroundColour: "rgba(0,0,0,0.5)",
            backdropBorderRadius: "4px",
            primaryColour: "#ffffff",
            secondaryColour: "#ffffff",
            tertiaryColour: "#ffffff",
        })

    ],
    providers: [
        DatePipe,
        AuthGuardService,
        AgencyGuardService,
        SupabaseAuthService,
        CategoryService,
        NotificationService,
        PerformerService,
        BookingService,
        AttachmentService,
        LocationService,
        CustomerService,
        SearchService,
        ProductService,
        InvoiceService,
        BookingCommunicationService,
        EmailService,
        InvitationService,
        ContractService,
        FinanceService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
