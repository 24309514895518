import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {AddLocationDialogComponent} from "./add-location/add-location-dialog.component";
import {CustomizerSettingsService} from "../../customizer-settings/customizer-settings.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {Agency, Location} from "../../common/model/model";
import {LocationService} from "../../services/location.service";
import {AgencyService} from "../../services/agency.service";

@Component({
    selector: 'locations-component',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements  OnInit, AfterViewInit {

    public selectedAgency: Agency;
    public locations: Location[] = [];
    dataSource = new MatTableDataSource<Location>(this.locations);
    displayedColumns: string[] = ['name', 'address', 'city', 'country', 'occupation', 'website', 'phone', 'actions'];

    pending = true;
    outOfStock = true;
    delivered = true;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public themeService: CustomizerSettingsService,
                public matDialog: MatDialog,
                public locationService: LocationService,
                private agencyService: AgencyService) {
    }

    ngOnInit(): void {
        this.agencyService.selectedAgency$.subscribe(agency => {
            if(agency) {
                this.selectedAgency = agency;
                this.getLocations(agency);
            }
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    public getLocations(agency: Agency) {
        this.locationService.getAllLocations(agency).then(locations => {
           this.locations = locations;
           this.dataSource = new MatTableDataSource<Location>(this.locations);
           if(this.paginator) {
               this.dataSource.paginator = this.paginator;
           }
        });
    }

    public openAddLocationDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
        const locationDialogRef = this.matDialog.open(AddLocationDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration
        });

        locationDialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                this.locations.push(result);
                this.dataSource.data = [...this.locations];
                this.dataSource.paginator = this.paginator;
            }
        });
    }

    public editAddLocationDialog(enterAnimationDuration: string, exitAnimationDuration: string, location: Location): void {
        const locationDialogRef = this.matDialog.open(AddLocationDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: location
        });

        locationDialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                console.log('result: ' + JSON.stringify(result));
                this.locations = this.locations.map(location => {
                    if(location.id === result.id) {
                        location = result;
                    }
                    return location;
                });
                this.dataSource.data = [...this.locations];
                this.dataSource.paginator = this.paginator;
            }
        });
    }

    public deleteLocation(location: Location) {
        this.locationService.deleteLocation(location).then(retrievedLocation => {
            this.locations = this.locations.filter(l => l.id !== location.id);
            this.dataSource.data = [...this.locations];
            this.dataSource.paginator = this.paginator;
        });
    }
}
