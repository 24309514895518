<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Invitations</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Invitations</li>
    </ol>
</div>

<div *ngIf="invitations.length < 1">
    <h5 class="mb-15 mt-25">No invitations found</h5>
</div>

<div class="row" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <mat-card class="mb-25 text-center tagus-card" *ngFor="let invitation of invitations">
            <mat-card-content>
                <img [src]="invitation.agency_logo" class="rounded-circle mb-15" width="150" alt="agency logo">
                <h5 class="mb-5 fw-semibold">{{ invitation.agency_name }}</h5>
                <span class="d-block muted-color">{{ invitation.agency_email }}</span>
                <div class="btn-box">
                    <button mat-flat-button class="phobos" (click)="acceptInvitation(invitation)">Accept</button>
                    <button mat-flat-button class="gray">Decline</button>
                </div>
                <br>
            </mat-card-content>
        </mat-card>
    </div>
</div>
