import {Component, OnInit} from "@angular/core";

@Component({
    selector: 'app-upcoming-dashboard',
    templateUrl: './upcoming-dashboard.component.html',
    styleUrls: ['./upcoming-dashboard.component.scss']
})
export class UpcomingDashboardComponent implements OnInit {
    constructor() {

    }

    ngOnInit(): void {

    }
}
