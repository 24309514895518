<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Add New Location</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <mat-card class="mb-25 create-project-card phobos-card">
        <form [formGroup]="addLocationFormGroup">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Location Name</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addLocationFormGroupControls['name'].errors && addLocationSubmitted}">
                                <i class="ri-edit-2-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addLocationFormGroup.get('name')?.hasError('required') && addLocationSubmitted) ? '#d73a49' : 'inherit'}">
                                    Location Name
                                </mat-label>
                                <input matInput formControlName="name" placeholder="Location Name">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Address</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addLocationFormGroupControls['address'].errors && addLocationSubmitted}">
                                <i class="ri-map-pin-2-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addLocationFormGroup.get('address')?.hasError('required') && addLocationSubmitted) ? '#d73a49' : 'inherit'}">
                                    Address
                                </mat-label>
                                <input matInput formControlName="address" placeholder="Address">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Postcode</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addLocationFormGroupControls['postcode'].errors && addLocationSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addLocationFormGroup.get('postcode')?.hasError('required') && addLocationSubmitted) ? '#d73a49' : 'inherit'}">
                                    Postcode
                                </mat-label>
                                <input matInput formControlName="postcode" placeholder="Postcode">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">City</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addLocationFormGroupControls['city'].errors && addLocationSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addLocationFormGroup.get('city')?.hasError('required') && addLocationSubmitted) ? '#d73a49' : 'inherit'}">
                                    City
                                </mat-label>
                                <input matInput formControlName="city" placeholder="City">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Country</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addLocationFormGroupControls['country'].errors && addLocationSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addLocationFormGroup.get('country')?.hasError('required') && addLocationSubmitted) ? '#d73a49' : 'inherit'}">
                                    Country
                                </mat-label>
                                <input matInput formControlName="country" placeholder="Country">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Phone number</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addLocationFormGroupControls['phone'].errors && addLocationSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addLocationFormGroup.get('phone')?.hasError('required') && addLocationSubmitted) ? '#d73a49' : 'inherit'}">
                                    Phone number
                                </mat-label>
                                <input matInput formControlName="phone" placeholder="Phone number">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Occupation</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addLocationFormGroupControls['occupation'].errors && addLocationSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addLocationFormGroup.get('occupation')?.hasError('required') && addLocationSubmitted) ? '#d73a49' : 'inherit'}">
                                    Occupation
                                </mat-label>
                                <input matInput formControlName="occupation" placeholder="Occupation">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Website</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addLocationFormGroupControls['website'].errors && addLocationSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addLocationFormGroup.get('website')?.hasError('required') && addLocationSubmitted) ? '#d73a49' : 'inherit'}">
                                    Website
                                </mat-label>
                                <input matInput formControlName="website" placeholder="Website">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Notes</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addLocationFormGroupControls['notes'].errors && addLocationSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addLocationFormGroup.get('notes')?.hasError('required') && addLocationSubmitted) ? '#d73a49' : 'inherit'}">
                                    Notes
                                </mat-label>
                                <textarea matInput formControlName="notes" rows="3"></textarea>
                            </mat-form-field>
                        </mat-card-content>
                        <!--                    <textarea matInput formControlName="notes" placeholder="Notes" rows="4"-->
                        <!--                           [ngClass]="{ 'is-invalid': addLocationSubmitted && addLocationFormGroupControls['notes'].errors}"-->
                        <!--                              [ngStyle]="{'border-color': addLocationSubmitted && addLocationFormGroupControls['notes'].errors?.['required'] ? 'red' : 'grey'}">-->
                        <!--                    </textarea>-->
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <mat-card class="mb-25 phobos-card">
                        <mat-card-header>
                            <h5 class="mb-0">Location Logo</h5>
                        </mat-card-header>
                        <mat-card-content>
                            <ngx-dropzone (change)="onSelect($event)" class="centered-dropzone">
                                <ngx-dropzone-label class="fw-semibold" *ngIf="!existingPicture && !logo.length">Drop files here or click to upload.</ngx-dropzone-label>
                                <div *ngIf="existingPicture" class="custom-preview">
                                    <img [src]="getSafeUrl(existingPicture)" style="max-width: 190px; max-height: 100%; object-fit: contain;">
                                </div>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of logo" [file]="f" (removed)="onRemove()">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-image-preview>
                            </ngx-dropzone>
                        </mat-card-content>
                        <div class="align-items-center" *ngIf="addLocationSubmitted && addLocationFormGroupControls['logo'].errors" style="color:red;font-size:small">
                            <div *ngIf="addLocationFormGroupControls['logo'].errors?.['required']">Please add your agency logo</div>
                        </div>
                    </mat-card>
                </div>

            </div>

            <div class="text-end">
                <button mat-flat-button class="gray" (click)="close()">Cancel</button>
                <button mat-flat-button class="phobos" (click)="saveOrUpdateLocation()">Save</button>
            </div>
        </form>
    </mat-card>

</div>
