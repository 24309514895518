<div
    class="sidebar-area bg-white box-shadow"
    [ngClass]="{'active': isToggled}"
    [class.dark-sidebar]="themeService.isSidebarDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
>
    <div class="logo bg-white">
        <a routerLink="/" class="d-flex align-items-center">
            <img src="assets/img/sightgig2.png" alt="logo">
            <span class="sightgig-logo">SIGHTGIG</span>
        </a>
    </div>
    <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
    </div>
    <ng-scrollbar visibility="hover" style="height: 100vh;">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <form class="form-container">
                    <div class="row align-items-center" *ngIf="canAccessAgency$ | async">
                        <div class="phobos-form-group without-icon">
                            <!-- Wrap the select and button in a container div -->
                            <div class="full-width-container" *ngIf="agencies.length == 0">
                                <mat-label><b>Please create an Agency first</b></mat-label>
                                <br>
                            </div>
                            <div class="full-width-container" *ngIf="agencies.length > 0">
                                <mat-form-field appearance="fill" class="full-width">
                                    <mat-label *ngIf="latestSelectedAgency === null">Select Agency</mat-label>
                                    <mat-label *ngIf="latestSelectedAgency != null">Selected Agency</mat-label>
                                    <mat-select [formControl]="agenciesFormControl">
                                        <mat-option *ngFor="let agency of agencies" [value]="agency" (click)="onAgencySelected(agency)">{{agency.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                            <div class="full-width-container">
                                <button mat-stroked-button class="full-width phobos" (click)="openAddAgencyDialog('300ms', '100ms')">Add Agency</button>
                            </div>
                        </div>
                    </div>
                </form>
                <br>
                <mat-accordion>
                    <a *ngIf="canAccessAgency$ | async" routerLink="/dashboard" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="flaticon-menu-1"></i>
                        <span class="title">Dashboard</span>
                    </a>
                    <span class="sub-title">COMPONENTS</span>
                    <a *ngIf="canAccessAgency$ | async" routerLink="/bookings" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="flaticon-calendar"></i>
                        <span class="title">Bookings</span>
                    </a>
                    <a *ngIf="canAccessPerformer$ | async" routerLink="/performerbookings" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="flaticon-calendar"></i>
                        <span class="title">Bookings</span>
                    </a>
                    <a *ngIf="canAccessAgency$ | async" routerLink="/performers" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="flaticon-musical-note"></i>
                        <span class="title">Performers</span>
                    </a>
                    <a *ngIf="canAccessAgency$ | async" routerLink="/customers" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="flaticon-customer-service"></i>
                        <span class="title">Customers</span>
                    </a>
                    <a *ngIf="canAccessAgency$ | async" routerLink="/locations" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="flaticon-marker"></i>
                        <span class="title">Locations</span>
                    </a>
                    <a *ngIf="canAccessAgency$ | async" routerLink="/invoices" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="flaticon-credit-card"></i>
                        <span class="title">Invoices</span>
                    </a>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="flaticon-setting"></i>
                                <span class="title">Settings</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            <li class="sidemenu-item">
                                <a routerLink="/profile" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Profile
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a routerLink="/account" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Account
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a routerLink="/security" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Security
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a routerLink="/connections" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Connections
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a routerLink="/privacy-policy" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Privacy Policy
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a routerLink="/terms-conditions" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Terms & Conditions
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>
