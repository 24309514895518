<div *ngIf="bookingDetails">
    <mat-card class="mb-25 phobos-card">
        <mat-card-header>
            <h5 class="mb-0">Contract Financials</h5>
            <div class="button-group">
                <a *ngIf="bookingDetails.contract &&
                (bookingDetails.contract.status === 'GENERATED' || bookingDetails.contract.status === 'SENT')"
                   mat-stroked-button color="warn" style="margin-right: 5px"
                   class="default-btn phobos"
                   (click)="cancelContract()">
                    <i class="ri-add-fill"></i>Cancel Contract
                </a>
                <a mat-stroked-button class="default-btn phobos" (click)="saveContract()" style="margin-right: 5px">
                    <i class="ri-add-fill"></i>Save Details
                </a>
                <a mat-stroked-button
                   class="default-btn phobos"
                   (click)="generateContract()"
                   [disabled]="!bookingDetails.contract || bookingDetails.contract.status === 'GENERATED'">
                    <i class="ri-add-fill"></i>Generate Contract
                </a>
            </div>
        </mat-card-header>
        <mat-card-content>
            <br>
            <div class="course-details-desc">
                <div class="d-md-flex meta mb-25 pb-25 justify-content-space-between align-items-center">
                    <ul class="list mb-0 d-md-flex mt-0 ps-0">
                        <li class="gray-color fw-semibold">
                            Total Amount (excl. VAT)
                            <span class="d-block fw-semibold position-relative"><i class="ri-bar-chart-2-line"></i>
                                {{ bookingDetails.booking.currency_symbol}} {{ this.bookingFinancials.financialsTotal }} {{ bookingDetails.booking.currency_code}}
                            </span>
                        </li>
                        <li class="gray-color fw-semibold">
                            VAT Amount ({{this.bookingFinancials.financialsVATRate}}%)
                            <span class="d-block fw-semibold position-relative"><i class="ri-bill-line"></i>
                                {{ bookingDetails.booking.currency_symbol}} {{ this.bookingFinancials.actualVATAmount }} {{ bookingDetails.booking.currency_code}}
                            </span>
                        </li>
                        <li class="gray-color fw-semibold">
                            Total Amount (incl. VAT)
                            <span class="d-block fw-semibold position-relative"><i class="ri-bill-line"></i>
                                {{ bookingDetails.booking.currency_symbol}} {{ this.bookingFinancials.financialsTotalWithVAT }} {{ bookingDetails.booking.currency_code}}
                            </span>
                        </li>
                    </ul>
                    <div class="rating-box text-end">
                        <span class="d-block mb-10 fw-semibold gray-color">Status</span>
                        <div *ngIf="bookingDetails.contract" class="rating">
                            <span class="status-badge" [ngClass]="{
                                'status-draft': bookingDetails.contract.status === 'DRAFT',
                                'status-generated': bookingDetails.contract.status === 'GENERATED'
                            }">
                                {{ bookingDetails.contract.status }}
                            </span>
                        </div>
                        <div *ngIf="!bookingDetails.contract" class="rating">
                            <span class="status-badge status-notgenerated" >
                                NO CONTRACT
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="mb-25 phobos-card">
        <mat-card-header>
            <h5 class="mb-0">Contract Details</h5>
        </mat-card-header>
        <mat-card-content>
            <div class="d-flex justify-content-space-between">
                <div>
                    <h6 class="mb-15">Customer Details:</h6>
                    <span class="fw-semibold gray-color d-block mb-8">{{bookingDetails.customer.name}}</span>
                    <span class="gray-color d-block mb-8">{{bookingDetails.customer.address}}</span>
                    <span class="gray-color d-block mb-8">{{bookingDetails.customer.postcode}} {{bookingDetails.customer.city}} </span>
                    <span class="gray-color d-block mb-8">{{bookingDetails.customer.country}}</span>
                    <span class="gray-color d-block mb-8">{{bookingDetails.customer.email}}</span>
                    <h6 class="mb-15 mt-25">Agency Details:</h6>
                    <span class="gray-color d-block mb-8">
                    </span>
                    <span class="gray-color d-block mb-8">{{ selectedAgency.name }}</span>
                    <span class="gray-color d-block mb-8">{{ selectedAgency.address }} , {{ selectedAgency.postcode }} {{ selectedAgency.city }} </span>
                    <span class="gray-color d-block mb-8">{{ selectedAgency.country }}</span>
                    <span class="gray-color d-block mb-8">IBAN: {{ selectedAgency.bank_number }}</span>
                    <span class="gray-color d-block">BIC Code: {{ selectedAgency.bic_code }}</span>
                </div>
                <div class="text-end">
                    <h6 class="mb-15 mt-25">Location Details:</h6>
                    <span class="gray-color d-block">{{ bookingDetails.location.name }}</span>
                    <span class="gray-color d-block">{{ bookingDetails.location.address }} , {{ bookingDetails.location.postcode }} {{ bookingDetails.location.city }}</span>
                    <span class="gray-color d-block">{{ bookingDetails.location.website }}</span>
                    <span class="gray-color d-block mb-8">
                    </span>
                    <h6 class="mb-15 mt-25">Event Details:</h6>
                    <span class="gray-color d-block">Name: {{ bookingDetails.booking.event_name }}</span>
                    <span class="gray-color d-block">Date: {{ bookingDetails.booking.event_date }}</span>
                    <span class="gray-color d-block">Status: {{ bookingDetails.booking.status }}</span>

                    <h6 class="mb-15 mt-25">Performers:</h6>
                    <ul *ngFor="let performer of bookingDetails.performers" class="text-end">
                        <li class="d-flex justify-content-end">
                            <div>
                                <span class="gray-color d-block">{{ performer.name }} ({{ performer.start_time }} - {{ performer.end_time }})</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="mb-25 create-project-card phobos-card">
        <mat-card-content>
            <form [formGroup]="contractFormGroup">
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="d-block mb-12 fw-semibold">Contract Expiration Days</label>
                    <div class="phobos-form-group">
                        <mat-form-field appearance="fill">
                            <i class="ri-folder-info-line"></i>
                            <mat-label>
                                Amount of days
                            </mat-label>
                            <input matInput type="number" formControlName="contractExpirationDays">
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="bookingDetails.products.length > 0" class="row mt-20">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div>
                            <label class="d-block mb-12 fw-semibold">Products Overview</label>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th class="fw-semibold main-color fw-semibold text-start">Product</th>
                                        <th class="fw-semibold main-color fw-semibold text-start">Description</th>
                                        <th class="fw-semibold main-color fw-semibold text-start">Category</th>
                                        <th class="fw-semibold main-color fw-semibold text-start">Sub-Category</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <label *ngIf="bookingDetails.products.length < 1" class="d-block mb-12 fw-semibold">No product added yet</label>
                                    <tr *ngFor="let product of bookingDetails.products">
                                        <td class="fw-semibold text-start">
                                            {{product.name}}
                                        </td>
                                        <td class="fw-semibold text-start">{{product.description}}</td>
                                        <td class="fw-semibold text-start">{{product.category}}</td>
                                        <td class="fw-semibold text-start">{{product.subcategory}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <mat-divider></mat-divider>
                <br>
                <div class="row mt-20">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div>
                            <label class="d-block mb-12 fw-semibold">Payment Information</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Payment instructions here..</mat-label>
                                <textarea matInput rows="4" formControlName="paymentInstructions"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div>
                            <label class="d-block mb-12 fw-semibold">Additional Information</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Additional information here...</mat-label>
                                <textarea matInput rows="4" formControlName="additionalInformation"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div>
                            <label class="d-block mb-12 fw-semibold">Terms & Conditions</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Terms & Conditions here...</mat-label>
                                <textarea matInput rows="4" formControlName="termsAndConditions"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
