import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {UserRole} from "../common/model/model";

@Injectable()
export class EmailService {
    constructor(private supabaseService: SupabaseService) {
    }

    public async sendInvoiceEmail(invoiceId: number) {
        const {data, error} = await this.supabaseService.supabaseClient.functions.invoke('handleInvoiceStatusChange',
            {
                body: JSON.stringify({invoiceId, status: 'ready'})
            });

        if (error) throw error;
        return data;
    }

    public async sendBookingEmail(bookingId: number) {
        const { data, error } = await this.supabaseService.supabaseClient.functions.invoke('handleBookingStatusChange', {
            body: JSON.stringify({ bookingId, status: 'optional' })
        });

        if (error) throw error;
        return data;
    }

    public async sendCustomEmail(entityType: string, entityId: number, templateName: string) {
        const { data, error } = await this.supabaseService.supabaseClient.functions.invoke('sendCustomEmail', {
            body: JSON.stringify({ entityType, entityId, templateName })
        });

        if (error) throw error;
        return data;
    }

    public async testEmail() {
        const { data, error } = await this.supabaseService.supabaseClient.functions.invoke('email-function', {
            body: { action: 'sendEmail' }
        });

        if (error) {
            console.error('Function invocation error:', error);
            throw error;
        }
        return data;
    }

    public async testGreeting(name: string) {
        const { data, error } = await this.supabaseService.supabaseClient.functions.invoke('email-function', {
            body: { action: 'greet', name: name }
        });

        if (error) {
            console.error('Function invocation error:', error);
            throw error;
        }
        return data;
    }

    public async sendInvitationEmail(email: string, role: UserRole, token: string) {
        const { data, error } = await this.supabaseService.supabaseClient.functions.invoke('email-function', {
            body: {
                action: 'sendInvitationEmail',
                email: email,
                token: token,
                role: role
            }
        });

        if (error) {
            throw new Error("Failed to send invitation email, because: " + error.message);
        }

        return data;
    }
}
