import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {BookingDetails, BookingStatus} from "../../../../common/model/model";
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";
import {MatPaginator} from "@angular/material/paginator";
import {Observable, Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
    selector: 'bookings-list-component',
    templateUrl: 'bookings-list.component.html',
    styleUrls: ['bookings-list.component.scss']
})
export class BookingsListComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() bookingsDetails: Observable<BookingDetails[]>;
    private bookingsDetailsSubscription: Subscription;

    dataSource: MatTableDataSource<BookingDetails>;
    displayedColumns: string[] = ['name', 'date', 'performers', 'customer', 'location', 'status', 'actions'];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    public autosave: BookingDetails | null = null;


    constructor(public themeService: CustomizerSettingsService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<BookingDetails>([]);

        const autosaveData = localStorage.getItem('autosavedBooking');
        if (autosaveData) {
            //this.autosave = JSON.parse(autosaveData) as BookingDetails;
            this.autosave = this.mapAutosaveToBookingDetails(JSON.parse(autosaveData));
        }


        this.bookingsDetailsSubscription = this.bookingsDetails.subscribe(data => {
            this.dataSource.data = data;
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy(): void {
        this.bookingsDetailsSubscription.unsubscribe();
    }

    private mapAutosaveToBookingDetails(autosaveData: any): BookingDetails {
        return {
            booking: {
                id: undefined,  // Use `undefined` for optional fields
                event_name: autosaveData.event_name || '',
                event_website: autosaveData.event_website || '',
                event_budget: parseFloat(autosaveData.event_budget) || 0,
                event_date: autosaveData.event_date ? new Date(autosaveData.event_date) : new Date(0), // Default date if `null`
                opening_time: autosaveData.opening_time || null,
                begin_time: autosaveData.begin_time || null,
                end_time: autosaveData.end_time || null,
                booking_description: autosaveData.booking_description || '',
                line_up_event: autosaveData.line_up_event || '',
                internal_notes: autosaveData.internal_notes || '',
                construction_start_time: autosaveData.construction_start_time || null,
                sound_check_time: autosaveData.sound_check_time || null,
                podium_ready_time: autosaveData.podium_ready_time || null,
                performance_floor: autosaveData.performance_floor || '',
                distance_to_stage: autosaveData.distance_to_stage || '',
                elevator_availability: autosaveData.elevator_availability || '',
                visitor_expectation: autosaveData.visitor_expectation || '',
                available_tickets: autosaveData.available_tickets || '',
                call_sheet_memo: autosaveData.call_sheet_memo || '',
                status: 'DRAFT',
                agency_id: '',
                team_lead_id: '',
                location_id: autosaveData.location?.id || undefined, // Use `undefined` for optional ID fields
                customer_id: autosaveData.customer?.id || undefined, // Use `undefined` for optional ID fields
                currency_code: 'USD',
                currency_symbol: '$'
            },
            contacts: autosaveData.bookingContacts || [],
            attachments: autosaveData.selectedFiles || [],
            performers: autosaveData.bookingPerformers || [],
            products: autosaveData.bookingProducts || [],
            performer_details: autosaveData.performerProducts || [],
            location: autosaveData.location || { name: '' }, // Default location object
            customer: autosaveData.customer || { name: '' }, // Default customer object
            booking_performer_flights: autosaveData.bookingPerformerFlights || [],
            booking_performer_hotels: autosaveData.bookingPerformerHotels || [],
            booking_guests: autosaveData.bookingGuests || [],
            invoices: [],
            contract: undefined
        };
    }

    public removeAutosave() {
        localStorage.removeItem('autosavedBooking');
        this.autosave = null;
    }

    public continueEditing() {
        this.router.navigate(['addbooking']);
    }

    public applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    public openBookingDetails(allBookingDetails: BookingDetails) {
        this.router.navigate(['booking-details', allBookingDetails.booking.id]);
    }

    protected readonly BookingStatus = BookingStatus;
}
