import {Component, OnDestroy, OnInit} from "@angular/core";
import {CustomizerSettingsService} from "../../../customizer-settings/customizer-settings.service";
import {Router} from "@angular/router";
import {BookingService} from "../../../services/booking.service";
import {Agency, Booking, BookingDetails} from "../../../common/model/model";
import {AgencyService} from "../../../services/agency.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {SearchService} from "../../../services/search.service";
import {EmailService} from "../../../services/email.service";
import {InvitationService} from "../../../services/invitation.service";

@Component({
    selector: 'bookings-component',
    templateUrl: 'bookings.component.html',
    styleUrls: ['bookings.component.scss']
})
export class BookingsComponent implements OnInit, OnDestroy {

    public selectedAgency: Agency;
    public bookings: Booking[] = [];
    //public bookingDetails: BookingDetails[] = [];
    public bookingDetails: BehaviorSubject<BookingDetails[]> = new BehaviorSubject<BookingDetails[]>([]);
    private originalBookingDetails: BookingDetails[] = [];

    public selectedTabIndex = 0;
    public searchSubscription: Subscription;

    constructor(public themeService: CustomizerSettingsService,
                private bookingService: BookingService,
                private agencyService: AgencyService,
                private searchService: SearchService,
                private emailService: EmailService,
                private invitationService: InvitationService,
                public router: Router) {
    }

    ngOnInit(): void {
        this.agencyService.selectedAgency$.subscribe(agency => {
            if(agency) {
                this.selectedAgency = agency;
                this.getAllBookingDetails(agency);
            }
        });

        this.searchSubscription = this.searchService.searchObservable.subscribe(searchTerm => {
            this.filterBookings(searchTerm);
        });
    }

    ngOnDestroy(): void {
        this.searchSubscription.unsubscribe();
    }

    public getAllBookingDetails(agency: Agency) {
        this.bookingService.getAllBookingDetails(agency).then(bookingDetails => {
            //this.bookingDetails = bookingDetails;
            this.bookingDetails.next(bookingDetails);
            this.originalBookingDetails = bookingDetails;
        });
    }

    public onTabChange(index: number) {
        this.selectedTabIndex = index;
    }

    public filterBookings(searchTerm: string) {
        if (searchTerm) {
            const filteredBookings = this.originalBookingDetails.filter(bookingDetail => {
                const term = searchTerm.toLowerCase();
                return bookingDetail.booking.event_name.toLowerCase().includes(term) ||
                    bookingDetail.location.name.toLowerCase().includes(term) ||
                    bookingDetail.customer.name.toLowerCase().includes(term) ||
                    bookingDetail.performer_details.some(performer => performer.name.toLowerCase().includes(term));
            });
            this.bookingDetails.next(filteredBookings);
        } else {
            this.bookingDetails.next(this.originalBookingDetails);
        }
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    public addBooking() {
        this.router.navigate(['/addbooking'])
    }

    public testGreeting() {
        // this.emailService.testGreeting('vahid').then(result => {
        //     console.log('greeting result: ' + JSON.stringify(result));
        // }).catch(error => {
        //     console.log('greeting error: ' + JSON.stringify(error));
        // });

        this.invitationService.handlerTest2().then(result => {
            console.log('handler 2 result: ' + JSON.stringify(result));
        });
    }

    public testEmail() {
        this.emailService.testEmail().then(result => {
            console.log('email result: ' + JSON.stringify(result));
        }).catch(error => {
            console.log('email error: ' + JSON.stringify(error));
        });
    }

    public testFunctions() {
        // this.invitationService.checkUserAvailabilityAndRegister('vahid@vafaeitc1.com').then(result => {
        //     console.log('result: ' + JSON.stringify(result));
        // }).catch(error => {
        //     console.log('error: ' + JSON.stringify(error));
        // });

        this.invitationService.handlerTest().then(result => {
            console.log('handler result: ' + JSON.stringify(result));
    });
    }
}
