<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">{{data.bookingContact ? 'Update Contact' : 'Add Contact'}}</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <mat-card class="mb-25 create-project-card phobos-card">
        <form [formGroup]="addContactFormGroup">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Name</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addContactFormGroupControls['contact_name'].errors && addContactSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addContactFormGroup.get('contact_name')?.hasError('required') && addContactSubmitted) ? '#d73a49' : 'inherit'}">
                                    Name
                                </mat-label>
                                <input matInput formControlName="contact_name">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                    <div class="form-group">
                        <label class="d-block fw-semibold">Role</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addContactFormGroupControls['contact_role'].errors && addContactSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addContactFormGroup.get('contact_role')?.hasError('required') && addContactSubmitted) ? '#d73a49' : 'inherit'}">
                                    Role
                                </mat-label>
                                <input matInput formControlName="contact_role">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                    <div class="form-group">
                        <label class="d-block fw-semibold">Phone</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addContactFormGroupControls['contact_phone'].errors && addContactSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addContactFormGroup.get('contact_phone')?.hasError('required') && addContactSubmitted) ? '#d73a49' : 'inherit'}">
                                    Phone
                                </mat-label>
                                <input matInput formControlName="contact_phone">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                    <div class="form-group">
                        <label class="d-block fw-semibold">E-Mail</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addContactFormGroupControls['contact_email'].errors && addContactSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addContactFormGroup.get('contact_email')?.hasError('required') && addContactSubmitted) ? '#d73a49' : 'inherit'}">
                                    E-Mail
                                </mat-label>
                                <input matInput formControlName="contact_email">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                    <div class="form-group">
                        <label class="d-block fw-semibold">Company</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addContactFormGroupControls['contact_company'].errors && addContactSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addContactFormGroup.get('contact_company')?.hasError('required') && addContactSubmitted) ? '#d73a49' : 'inherit'}">
                                    Company
                                </mat-label>
                                <input matInput formControlName="contact_company">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                    <div class="text-end">
                        <button mat-flat-button class="gray" (click)="close()">Cancel</button>

                        <button mat-flat-button class="phobos" (click)="addContact()">
                            {{data.bookingContact ? 'Update Guest' : 'Add Guest'}}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </mat-card>
</div>
