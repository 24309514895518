import {Component, ViewChild} from "@angular/core";
import {CalendarOptions} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import {FullCalendarComponent} from "@fullcalendar/angular";

@Component({
    selector: 'app-upcoming-dashboard-calendar',
    templateUrl: './upcoming-dashboard-calendar.component.html',
    styleUrls: ['./upcoming-dashboard-calendar.component.scss']
})
export class UpcomingDashboardCalendarComponent {

    @ViewChild('bookingCalendar', {static: false}) calendarComponent: FullCalendarComponent;

    public calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        headerToolbar: {
            left: 'prev,next',
            right:'today',
            center: 'title'
        },
        events: [],
        plugins: [dayGridPlugin,interactionPlugin, timeGridPlugin, multiMonthPlugin],
        eventClick: this.handleEventClick.bind(this),
        eventDidMount: this.handleEventDidMount.bind(this)
    };

    constructor() {
    }

    public handleEventDidMount(info: any) {
    }

    handleEventClick(arg: any) {
    }
}
