import {Component} from '@angular/core';
import {CustomizerSettingsService} from '../../customizer-settings/customizer-settings.service';
import {Router} from "@angular/router";

@Component({
    selector: 'app-confirm-mail',
    templateUrl: './confirm-mail.component.html',
    styleUrls: ['./confirm-mail.component.scss']
})
export class ConfirmMailComponent {

    public userName: any;
    public userEmail: any;
    public userRole: any;

    constructor(public themeService: CustomizerSettingsService,
                private router: Router) {
        this.userName = this.router.getCurrentNavigation()?.extras.state?.['name'];
        this.userEmail = this.router.getCurrentNavigation()?.extras.state?.['email'];
        this.userRole = this.router.getCurrentNavigation()?.extras.state?.['role'];
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }
}
