import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {
    Agency,
    FileTypes,
    Member,
    Performer,
    Performer_Product,
    Performer_With_Categories
} from "../common/model/model";
import {TABLE} from "../common/model/tables";
import {SupabaseAuthService} from "./supabase.auth.service";

@Injectable()
export class PerformerService {

    constructor(private supabaseService: SupabaseService,
                private supabaseAuthService: SupabaseAuthService) {
    }

    public async getAllPerformers(agencyOrId: Agency | string): Promise<Performer[]> {
        let agencyId: string;

        if (typeof agencyOrId === 'string') {
            agencyId = agencyOrId;
        } else if (agencyOrId && 'id' in agencyOrId && agencyOrId.id) {
            agencyId = agencyOrId.id;
        } else {
            throw new Error("Invalid input: expected Agency object with a valid id or agency ID string");
        }

        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.PERFORMERS)
            .select('*')
            .eq('agency_id', agencyId);

        if (error) {
            throw new Error("Failed to retrieve performers, because: " + error.message);
        }
        return data as Performer[];
    }

    public async addPerformerFiles(file: File, agency: Agency, fileType: FileTypes, performerId: string): Promise<string> {
        const filePath = `${agency.id}/${performerId}/${fileType}/${file.name}`;

        const { data, error } = await this.supabaseService.supabaseClient
            .storage
            .from('performer_riders')
            .upload(filePath, file,{upsert: true});

        if(error) {
            throw new Error("Failed to upload performer rider file, because: " + error.message);
        }

        return data.path;
    }

    public async getPerformerFile(agencyId: string, performerId: string | undefined, fileType: string): Promise<string[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .storage
            .from('performer_riders')
            .list(agencyId + '/' + performerId + '/' + fileType);

        if(error) {
            throw new Error("Failed to retrieve performer rider files, because: " + error.message);
        }

        return data.map((file: any) => file.name);
    }

    public async getPerformerFileMetadata(agencyId: string, performerId: string | undefined, fileType: FileTypes, fileName: string): Promise<any> {
        const {data, error} = await this.supabaseService.supabaseClient
            .schema('storage')
            .from('objects')
            .select('metadata')
            .eq('name', agencyId + '/' + performerId + '/' + fileType + '/' + fileName);

        if(error) {
            throw new Error("Failed to retrieve performer rider files metadata, because: " + error.message);
        }

        return data[0];
    }

    public async updatePerformerAttachments(performer: Performer): Promise<Boolean> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.PERFORMERS)
            .update({
                performerrider: performer.performerrider,
                presskit: performer.presskit,
                var: performer.var
            })
            .eq('id', performer.id);

        if(error) {
            throw new Error("Failed to update performer, because: " + error.message);
        }

        return true;
    }

    public async addPerformer(performer: Performer, categoryIds: string[],
                              performerProducts: Performer_Product[] | null,
                              members: Member[] | null, currentAgency: Agency | null): Promise<Performer> {
        const performerDetails = {
            ...performer,
            playtimes: performer.playtimes.join(',')
        };

        const {data, error} = await this.supabaseService.supabaseClient.rpc('add_performer', {
            category_ids: categoryIds,
            createdby: this.supabaseAuthService.getCurrentUserInfo().id,
            current_agency: currentAgency,
            members: members,
            performer_details: performerDetails,
            performer_products: performerProducts
        });

        if(error) {
            console.log(error.message);
            throw new Error("Failed to add performer, because: " + error.message);
        }

        return data as Performer;
    }

    public async getPerformerDetails(performerId: string) {
        const { data, error } = await this.supabaseService.supabaseClient
            .rpc('get_performer_details', { requested_performer_id: performerId });

        if (error) {
            throw new Error('Error fetching performer details: ' + error.message);
        }
        return data;
    }

    public async getPerformerProducts(performerId: string): Promise<Performer_Product[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.PERFORMER_PRODUCTS)
            .select('*')
            .eq('performer_id', performerId);

        if (error) {
            throw new Error('Error fetching performer products:' + error.message);
        }
        return data as Performer_Product[];
    }

    public async updatePerformer(performer: Performer) {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.PERFORMERS)
            .update(performer)
            .eq('id', performer.id)
            .select('*');

        if (error) {
            throw new Error("Failed to update performer, because: " + error.message);
        }

        return data;
    }

    public async addPerformerProduct(performerProduct: Performer_Product) {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.PERFORMER_PRODUCTS)
            .insert(performerProduct)
            .select('*');

        if (error) {
            throw new Error("Failed to add performer product, because: " + error.message);
        }

        return data;
    }

    public async deletePerformerProduct(performerProduct: Performer_Product) {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.PERFORMER_PRODUCTS)
            .delete()
            .eq('id', performerProduct.id);

        if (error) {
            throw new Error("Failed to delete performer product, because: " + error.message);
        }
        return data;
    }

    public async updatePerformerProduct(performerProduct: Performer_Product) {
        //console.log('performerProduct received: ' + JSON.stringify(performerProduct));
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.PERFORMER_PRODUCTS)
            .update(performerProduct)
            .eq('id', performerProduct.id)
            .select('*');

        if (error) {
            console.log('Error: ' + JSON.stringify(error));
            throw new Error("Failed to update performer product, because: " + error.message);
        }

        return data;
    }

    public async getPerformerAttachments(performerId: string) {

    }

    public async getPerformerWithCategories(agency: Agency): Promise<Performer_With_Categories[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .rpc('get_performers', {
                requested_agency_id: agency.id
            });

        if (error) {
            throw new Error("Failed to retrieve performers, because: " + error.message);
        }

        return data as Performer_With_Categories[];
    }
}
