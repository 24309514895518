<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">{{ !isCurrency(data) ? 'Edit' : 'Add New' }} Product</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <form [formGroup]="addProductFormGroup">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Product Name</label>
                    <input type="text" formControlName="name" class="input-control" placeholder="Name *"
                           [ngClass]="{ 'is-invalid': addProductSubmitted && addProductFormGroupControls['name'].errors}"
                           [ngStyle]="{'border-color': addProductSubmitted && addProductFormGroupControls['name'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Description</label>
                    <textarea matInput rows="6" formControlName="description" class="input-control custom-textarea" type="text" required
                              [ngClass]="{ 'is-invalid': addProductSubmitted && addProductFormGroupControls['description'].errors}"
                              [ngStyle]="{'border-color': addProductSubmitted && addProductFormGroupControls['description'].errors?.['required'] ? 'red' : 'grey'}">>
                    </textarea>
<!--                    <input type="text" formControlName="description" class="input-control" placeholder="Name *"-->
<!--                           [ngClass]="{ 'is-invalid': addProductSubmitted && addProductFormGroupControls['description'].errors}"-->
<!--                           [ngStyle]="{'border-color': addProductSubmitted && addProductFormGroupControls['description'].errors?.['required'] ? 'red' : 'grey'}">-->
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Price ({{data.currency_code}})</label>
                    <input type="number" formControlName="price" class="input-control" placeholder="Price *"
                           [ngClass]="{ 'is-invalid': addProductSubmitted && addProductFormGroupControls['price'].errors}"
                           [ngStyle]="{'border-color': addProductSubmitted && addProductFormGroupControls['price'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Cost ({{data.currency_code}})</label>
                    <input type="number" formControlName="cost" class="input-control" placeholder="Cost *"
                           [ngClass]="{ 'is-invalid': addProductSubmitted && addProductFormGroupControls['cost'].errors}"
                           [ngStyle]="{'border-color': addProductSubmitted && addProductFormGroupControls['cost'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <mat-card class="mb-25 phobos-card">
                    <mat-card-header>
                        <h5 class="mb-0">Product Picture</h5>
                    </mat-card-header>
                    <mat-card-content>
                        <ngx-dropzone (change)="onSelect($event)">
                            <ngx-dropzone-label class="fw-semibold" *ngIf="!picture.length && !existingPicture">Drop files here or click to upload.</ngx-dropzone-label>
                            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of picture" [file]="f" [removable]="true" (removed)="onRemove()">
                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                            </ngx-dropzone-image-preview>
                            <ngx-dropzone-preview *ngIf="existingPicture" [removable]="true" (removed)="onRemove()">
                                <ngx-dropzone-label>Existing Image</ngx-dropzone-label>
                                <img [src]="existingPicture" style="max-width: 100%; max-height: 100%;" />
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                    </mat-card-content>
                    <div class="align-items-center" *ngIf="addProductSubmitted && addProductFormGroupControls['picture'].errors" style="color:red;font-size:small">
                        <div *ngIf="addProductFormGroupControls['picture'].errors?.['required']">Please add your product picture</div>
                    </div>
                </mat-card>
            </div>

        </div>

        <div class="text-end">
            <button type="button" class="gray" (click)="close()"><i class="ri-close-fill"></i> Cancel</button>

            <button mat-flat-button class="phobos" (click)="saveProduct()">Save</button>
        </div>
    </form>
</div>
