import {Component, Input, OnInit} from "@angular/core";
import {Booking_Performer_Flight, Booking_Performer_Hotel, BookingDetails} from "../../../../common/model/model";
import {MatDialog} from "@angular/material/dialog";
import {
    AddPerformerFlightComponentDialog
} from "../../add-booking/add-performerflight/add-performer-flight-component-dialog.component";
import {NotificationService} from "../../../../services/notification.service";
import {BookingService} from "../../../../services/booking.service";
import {
    AddPerformerHotelDialogComponent
} from "../../add-booking/add-performerhotel/add-performer-hotel-dialog.component";

@Component({
    selector: 'travel-info-component',
    templateUrl: './travel-info.component.html',
    styleUrls: ['./travel-info.component.scss']
})
export class TravelInfoComponent implements OnInit {

    @Input() bookingDetails: BookingDetails;

    constructor(private matDialog: MatDialog,
                private notificationService: NotificationService,
                private bookingService: BookingService) {
    }

    ngOnInit() {
    }

    public getPerformerName(performerId: string): string {
        const performer = this.bookingDetails.performer_details.find(p => p.id === performerId);
        return performer ? performer.name : 'Not found';
    }

    public openAddPerformerHotelDialog(enterAnimationDuration: string, exitAnimationDuration: string) {

        const addPerformerHotelDialogRef = this.matDialog.open(AddPerformerHotelDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                performers: this.bookingDetails.performers
            }
        });

        addPerformerHotelDialogRef.afterClosed().subscribe(result => {
            if(result != null && typeof result === 'object') {
                let bookingPerformerHotel = result as Booking_Performer_Hotel;
                bookingPerformerHotel.booking_id = this.bookingDetails.booking.id;

                this.bookingService.addBookingPerformerHotel(bookingPerformerHotel).then(newBookingPerformerHotel => {
                    this.bookingDetails.booking_performer_hotels.push(newBookingPerformerHotel[0]);
                });
            }
        });
    }

    public editAddPerformerHotelDialog(bookingPerformerHotel: Booking_Performer_Hotel, enterAnimationDuration: string, exitAnimationDuration: string) {
        const addPerformerHotelDialogRef = this.matDialog.open(AddPerformerHotelDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                performers: this.bookingDetails.performers,
                bookingPerformerHotel: bookingPerformerHotel
            }
        });

        addPerformerHotelDialogRef.afterClosed().subscribe(result => {
            if(result != null && typeof result === 'object') {
                let bookingPerformerHotel = result as Booking_Performer_Hotel;
                bookingPerformerHotel.booking_id = this.bookingDetails.booking.id;

                this.bookingService.updateBookingPerformerHotel(bookingPerformerHotel).then(updatedBookingPerformerHotel => {
                    this.bookingDetails.booking_performer_hotels = this.bookingDetails.booking_performer_hotels.map(bph => {
                        if(bph.id === updatedBookingPerformerHotel[0].id) {
                            return updatedBookingPerformerHotel[0];
                        } else {
                            return bph;
                        }
                    });
                });
            }
        });
    }

    public openAddPerformerFlightDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        const addPerformerFlightDialogRef = this.matDialog.open(AddPerformerFlightComponentDialog, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                performers: this.bookingDetails.performers
            }
        });

        addPerformerFlightDialogRef.afterClosed().subscribe(result => {
            if(result != null && typeof result === 'object') {
                let bookingPerformerFlight = result as Booking_Performer_Flight;
                bookingPerformerFlight.booking_id = this.bookingDetails.booking.id;

                this.bookingService.addBookingPerformerFlight(bookingPerformerFlight).then(newBookingPerformerFlight => {
                    this.bookingDetails.booking_performer_flights.push(newBookingPerformerFlight[0]);
                });
            }
        });
    }

    public editAddPerformerFlightDialog(bookingPerformerFlight: Booking_Performer_Flight, enterAnimationDuration: string, exitAnimationDuration: string) {
        const addPerformerFlightDialogRef = this.matDialog.open(AddPerformerFlightComponentDialog, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                performers: this.bookingDetails.performers,
                bookingPerformerFlight: bookingPerformerFlight
            }
        });

        addPerformerFlightDialogRef.afterClosed().subscribe(result => {
            if(result != null && typeof result === 'object') {
                let bookingPerformerFlight = result as Booking_Performer_Flight;
                bookingPerformerFlight.booking_id = this.bookingDetails.booking.id;

                this.bookingService.updateBookingPerformerFlight(bookingPerformerFlight).then(updatedBookingPerformerFlight => {
                    this.bookingDetails.booking_performer_flights = this.bookingDetails.booking_performer_flights.map(bpf => {
                        if(bpf.id === updatedBookingPerformerFlight[0].id) {
                            return updatedBookingPerformerFlight[0];
                        } else {
                            return bpf;
                        }
                    });
                });
            }
        });
    }

    public removeBookingFlight(bookingPerformerFlight: Booking_Performer_Flight) {
        this.bookingService.deleteBookingPerformerFlight(bookingPerformerFlight).then(deleted => {
            if(deleted) {
                this.bookingDetails.booking_performer_flights = this.bookingDetails.booking_performer_flights.filter(bpf => bpf.id !== bookingPerformerFlight.id);
                this.notificationService.openSuccessfulNotification('Flight removed successfully');
            }
        });
    }

    public removeBookingHotel(bookingPerformerHotel: Booking_Performer_Hotel) {
        this.bookingService.deleteBookingPerformerHotel(bookingPerformerHotel).then(deleted => {
            if(deleted) {
                this.bookingDetails.booking_performer_hotels = this.bookingDetails.booking_performer_hotels.filter(bph => bph.id !== bookingPerformerHotel.id);
                this.notificationService.openSuccessfulNotification('Hotel removed successfully');
            }
        });
    }
}
