import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Agency, BookingDetails, Customer, Location} from "../../../common/model/model";
import {BookingService} from "../../../services/booking.service";
import {LocationService} from "../../../services/location.service";
import {CustomerService} from "../../../services/customer.service";
import {AgencyService} from "../../../services/agency.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'bookingdetails-component',
    templateUrl: './booking-details.component.html',
    styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent implements OnInit {
    public selectedTabIndex = 0;
    public bookingDetails: BookingDetails;
    public locations: Location[] = [];
    public customers: Customer[] = [];
    public selectedAgency: Agency;

    constructor(private activatedRoute: ActivatedRoute,
                private bookingService: BookingService,
                private locationService: LocationService,
                private customerService: CustomerService,
                private agencyService: AgencyService,
                private notificationService: NotificationService,
                private router: Router) {
    }

    ngOnInit(): void {
        const bookingId = this.activatedRoute.snapshot.paramMap.get('bookingId') || '';
        this.loadBookingDetails(bookingId);

        this.agencyService.selectedAgency$.subscribe(agency => {
            if (agency) {
                this.selectedAgency = agency;
                this.checkAgencyAndLoadData();
            }
        });
    }

    private loadBookingDetails(bookingId: string): void {
        this.bookingService.getSingleBooking(bookingId).then(bookingDetails => {
            this.bookingDetails = bookingDetails;
            this.checkAgencyAndLoadData();
        }).catch(error => {
            this.notificationService.openFailureNotitication('Failed to load booking details, because: ' + error.message);
            console.log('error: ' + JSON.stringify(error));
            this.router.navigate(['/bookings']);
        });
    }

    private checkAgencyAndLoadData(): void {
        if (this.bookingDetails && this.selectedAgency) {
            if (this.bookingDetails.booking.agency_id === this.selectedAgency.id) {
                this.getLocations(this.selectedAgency);
                this.getCustomers(this.selectedAgency);
            } else {
                this.router.navigate(['/bookings']);
            }
        }
    }

    public onTabChange(index: number) {
        this.selectedTabIndex = index;
    }

    public getLocations(agency: Agency) {
        this.locationService.getAllLocations(agency).then(locations => {
            this.locations = locations;
        });
    }

    public getCustomers(agency: Agency) {
        this.customerService.getAllCustomers(agency).then(customers => {
            this.customers = customers;
        });
    }
}
