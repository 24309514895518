import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Performer_Booking_Details} from "../../../../common/model/model";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";

@Component({
    selector: 'performer-booking-info',
    templateUrl: './performer-booking-info.html',
    styleUrls: ['./performer-booking-info.scss']
})
export class PerformerBookingInfo implements OnInit {

    @Input() performerBookingDetails: Performer_Booking_Details;

    public performerBookingFormGroup: FormGroup;

    constructor(public themeService: CustomizerSettingsService,
                private performerBookingFormBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.enablePerformerBookingForm();
    }

    public enablePerformerBookingForm() {
        this.performerBookingFormGroup = this.performerBookingFormBuilder.group({
            eventname: [{value: this.performerBookingDetails?.booking.event_name, disabled: true}, ],
            eventdate: [{value: this.performerBookingDetails?.booking.event_date, disabled: true}, ],
            bookingstatus: [{value: this.performerBookingDetails?.booking.status, disabled: true}, ],
            eventwebsite: [{value: this.performerBookingDetails?.booking.event_website, disabled: true}, ],
            openingtime: [{value: this.performerBookingDetails?.booking.opening_time, disabled: true}, ],
            begintime: [{value: this.performerBookingDetails?.booking.begin_time, disabled: true}, ],
            endtime: [{value: this.performerBookingDetails?.booking.end_time, disabled: true}, ],
            visitorexpectation: [{value: this.performerBookingDetails?.booking.visitor_expectation, disabled: true}, ],
            bookingdescription: [{value: this.performerBookingDetails?.booking.booking_description, disabled: true}, ],
            lineupevent: [{value: this.performerBookingDetails?.booking.line_up_event, disabled: true}, ],
            soundchecktime: [{value: this.performerBookingDetails?.booking.sound_check_time, disabled: true}, ],
            callsheetmemo: [{value: this.performerBookingDetails?.booking.call_sheet_memo, disabled: true}, ],
            location: [{value: this.performerBookingDetails?.location.name, disabled: true}, ],
            customer: [{value: this.performerBookingDetails?.customer.name, disabled: true}, ]
        });
    }
}
