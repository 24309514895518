<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Locations</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Locations</li>
    </ol>
</div>

<mat-card
    class="mb-25 phobos-card"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <h5 class="mb-0">All Locations</h5>
        <a mat-flat-button class="default-btn phobos" (click)="openAddLocationDialog('300ms','100ms')" *ngIf="selectedAgency">
            <i class="ri-add-fill"></i> Add Location
        </a>
    </mat-card-header>

    <mat-card-content>
        <div class="mat-elevation-z8 phobos-mat-table">
            <table mat-table [dataSource]="dataSource">
                <!-- Order ID Column -->
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Order ID</th>
                    <td mat-cell *matCellDef="let element" class="fw-semibold text-start">{{element.position}}</td>
                </ng-container>

                <!-- Product Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Name</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <a routerLink="/" class="d-flex align-items-center product-info">
                            <img [src]="element.logo" alt="product">
                            {{element.name}}
                        </a>
                    </td>
                </ng-container>

                <!-- Customer Column -->
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef>Address</th>
                    <td mat-cell *matCellDef="let element">{{element.address}}</td>
                </ng-container>

                <!-- Price Column -->
                <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let element">{{element.city}}</td>
                </ng-container>

                <!-- Vendor Column -->
                <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>Country</th>
                    <td mat-cell *matCellDef="let element">{{element.country}}</td>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef="occupation">
                    <th mat-header-cell *matHeaderCellDef>Occupation</th>
                    <td mat-cell *matCellDef="let element">{{element.occupation}}</td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone</th>
                    <td mat-cell *matCellDef="let element">{{element.phone}}</td>
                </ng-container>

                <!-- Rating Column -->
                <ng-container matColumnDef="website">
                    <th mat-header-cell *matHeaderCellDef>Website</th>
                    <td mat-cell *matCellDef="let element">{{element.website}}</td>
                </ng-container>

                <!-- Action Menu Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="position-relative dot-btn p-0 bg-transparent border-none"
                             (click)="trigger.openMenu()"
                             #trigger="matMenuTrigger"
                             [matMenuTriggerFor]="actionMenu">
                            <i class="ri-more-fill"></i>
                        </div>
                        <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative"
                                    (click)="editAddLocationDialog('300ms','100ms', element)">
                                <i class="ri-edit-line"></i> Edit
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative"
                                    (click)="deleteLocation(element)">
                                <i class="ri-delete-bin-line"></i> Delete
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
            >
            </mat-paginator>
        </div>
    </mat-card-content>

</mat-card>


