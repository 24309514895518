<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Add Flight</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <mat-card class="mb-25 create-project-card phobos-card">
        <form [formGroup]="addPerformerFlightFormGroup">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Performer</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFlightFormGroupControls['performer'].errors && addPerformerFlightSubmitted}">
                                <i class="ri-account-circle-line"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerFlightFormGroup.get('performer')?.hasError('required') && addPerformerFlightSubmitted) ? '#d73a49' : 'inherit'}">
                                    Select Performer
                                </mat-label>
                                <mat-select formControlName="performer" (selectionChange)="onPerformerSelected($event)" [compareWith]="comparePerformers">
                                    <mat-option *ngFor="let bookingPerformer of bookingPerformers" [value]="bookingPerformer">{{bookingPerformer.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Description</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFlightFormGroupControls['description'].errors && addPerformerFlightSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerFlightFormGroup.get('description')?.hasError('required') && addPerformerFlightSubmitted) ? '#d73a49' : 'inherit'}">
                                    Flight description
                                </mat-label>
                                <input matInput formControlName="description">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Flight Number</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFlightFormGroupControls['flightnumber'].errors && addPerformerFlightSubmitted}">
                                <i class="ri-hashtag"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerFlightFormGroup.get('flightnumber')?.hasError('required') && addPerformerFlightSubmitted) ? '#d73a49' : 'inherit'}">
                                    Flight number
                                </mat-label>
                                <input matInput formControlName="flightnumber">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">From Departure</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFlightFormGroupControls['fromdeparture'].errors && addPerformerFlightSubmitted}">
                                <i class="ri-flight-takeoff-line"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerFlightFormGroup.get('fromdeparture')?.hasError('required') && addPerformerFlightSubmitted) ? '#d73a49' : 'inherit'}">
                                    From departure
                                </mat-label>
                                <input matInput formControlName="fromdeparture">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">To Destination</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFlightFormGroupControls['todestination'].errors && addPerformerFlightSubmitted}">
                                <i class="ri-flight-land-line"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerFlightFormGroup.get('todestination')?.hasError('required') && addPerformerFlightSubmitted) ? '#d73a49' : 'inherit'}">
                                    To destination
                                </mat-label>
                                <input matInput formControlName="todestination">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Flight Date</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFlightFormGroupControls['flightdate'].errors && addPerformerFlightSubmitted}">
                                <i class="ri-calendar-line"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerFlightFormGroup.get('flightdate')?.hasError('required') && addPerformerFlightSubmitted) ? '#d73a49' : 'inherit'}">
                                    Flight date
                                </mat-label>
                                <input matInput formControlName="flightdate" [matDatepicker]="flightDatePicker">
                                <mat-datepicker-toggle matIconSuffix [for]="flightDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #flightDatePicker></mat-datepicker>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Flight Time</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFlightFormGroupControls['flighttime'].errors && addPerformerFlightSubmitted}">
                                <i class="ri-time-line"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerFlightFormGroup.get('flighttime')?.hasError('required') && addPerformerFlightSubmitted) ? '#d73a49' : 'inherit'}">
                                    Flight time
                                </mat-label>
                                <input matInput type="time" formControlName="flighttime">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Arrival Date</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFlightFormGroupControls['arrivaldate'].errors && addPerformerFlightSubmitted}">
                                <i class="ri-calendar-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addPerformerFlightFormGroup.get('arrivaldate')?.hasError('required') && addPerformerFlightSubmitted) ? '#d73a49' : 'inherit'}">
                                    Arrival date
                                </mat-label>
                                <input matInput formControlName="arrivaldate" [matDatepicker]="arrivalDatePicker">
                                <mat-datepicker-toggle matIconSuffix [for]="flightDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #arrivalDatePicker></mat-datepicker>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Arrival Time</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFlightFormGroupControls['arrivaltime'].errors && addPerformerFlightSubmitted}">
                                <i class="ri-time-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addPerformerFlightFormGroup.get('arrivaltime')?.hasError('required') && addPerformerFlightSubmitted) ? '#d73a49' : 'inherit'}">
                                    Arrival time
                                </mat-label>
                                <input matInput type="time" formControlName="arrivaltime">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Transfer Details</label>
                    <mat-card-content>
                        <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFlightFormGroupControls['transferdetails'].errors && addPerformerFlightSubmitted}">
                            <i class="ri-sticky-note-line"></i>
                            <mat-label
                                    [ngStyle]="{'color': (addPerformerFlightFormGroup.get('transferdetails')?.hasError('required') && addPerformerFlightSubmitted) ? '#d73a49' : 'inherit'}">
                                Flight description
                            </mat-label>
                            <textarea matInput formControlName="transferdetails" rows="3"></textarea>
                        </mat-form-field>
                    </mat-card-content>
                </div>
            </div>
            <div class="text-end">
                <button mat-flat-button class="gray" (click)="close()">Cancel</button>

                <button mat-flat-button class="phobos" (click)="addPerformerFlight()">Save</button>
            </div>

        </form>
    </mat-card>

</div>
