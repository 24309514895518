<ng-template #customLoadingTemplate>
    <div class="custom-class">
    </div>
</ng-template>

<mat-card class="mb-25 phobos-card">
    <ngx-loading
            [show]="isLoading"
            [config]="{ backdropBorderRadius: '4px' }"
            [template]="customLoadingTemplate"
    ></ngx-loading>

    <mat-card-content>
        <div class="recent-files-table table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th class="fw-semibold main-color">File Name</th>
                    <th class="fw-semibold main-color">Document Type</th>
                    <th class="fw-semibold main-color">File Type</th>
                    <th class="fw-semibold main-color">File Size (KB)</th>
                    <th class="fw-semibold main-color">Last Modified</th>
                    <th class="fw-semibold main-color">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let performerFile of performerFiles">
                    <td>
                        <div class="name-info d-flex align-items-center">
                            <img src="assets/img/icon/folder.png" alt="folder">
                            <h5 class="fw-semibold mb-0">{{performerFile.name}}</h5>
                        </div>
                    </td>
                    <td>{{performerFile.performerFileType}}</td>
                    <td>{{performerFile.type}}</td>
                    <td>{{performerFile.size}}</td>
                    <td>{{performerFile.lastModified}}</td>
                    <td>
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                            <i class="ri-more-fill"></i>
                        </button>
                        <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-edit-line"></i> Modify
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-download-cloud-line"></i> Download
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>
                        </mat-menu>
                    </td>
                </tr>

                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>
