<div *ngIf="verifiedContract" class="contract-container">
    <!-- Contract Component -->
    <div class="contract-component" *ngIf="verifiedContract">
        <mat-card class="mb-25 contract-card phobos-card mt-4">
            <!-- Header Section with Logo and Contact Details -->
            <div class="header d-flex justify-content-space-between align-items-center">
                <!-- Agency Logo -->
                <div class="logo">
                    <img src="https://d2bzx2vuetkzse.cloudfront.net/unshoppable_producs/07aa8bf3-7d07-4ef2-a120-358501990001.png" alt="J-Music Logo" width="150">
                </div>

                <!-- Agency Contact Information -->
                <div class="agency-info text-right">
                    <p><strong>{{ verifiedContract.agency.name }}</strong></p>
                    <p>{{ verifiedContract.agency.address }},  {{ verifiedContract.agency.postcode }} {{ verifiedContract.agency.city }} </p>
                    <p>{{ verifiedContract.agency.country }}</p>
                    <p>KvK: {{ verifiedContract.agency.chamber_of_commerce }} | VAT {{ verifiedContract.agency.vat_number }}</p>
                    <p>📞 {{ verifiedContract.agency.phone }}</p>
                    <p>✉️ {{ verifiedContract.agency.email }}</p>
                </div>
            </div>
            <br>

            <mat-card-title class="text-center">
                <h5>CONTRACT AGREEMENT</h5>
            </mat-card-title>

            <mat-card-content>
                <div class="contract-details">
                    <p><strong>Contract number:</strong> {{ verifiedContract.contract.contract_number }}</p>
                    <p>On {{ verifiedContract.contract.contract_generated_date }} between:</p>
                    <p><strong>CLIENT Contract Party 1:</strong> <br>
                        {{ verifiedContract.bookingDetails.customer.name }}<br>
                        {{ verifiedContract.bookingDetails.customer.address }} <br>
                        {{ verifiedContract.bookingDetails.customer.postcode }} {{ verifiedContract.bookingDetails.customer.city }}</p>
                    <p>and <strong>CONTRACTOR Contracting Party 2:</strong> <br>
                        {{ verifiedContract.agency.name }}<br>
                        {{ verifiedContract.agency.address }}<br>
                        {{ verifiedContract.agency.postcode }} {{ verifiedContract.agency.city }}</p>
                </div>
                <mat-divider></mat-divider>

                <div class="event-details mt-3">
                    <p>The following has been agreed upon:</p>
                    <ul *ngFor="let performer of verifiedContract.bookingDetails.performers">
                        <li><strong>Artist:</strong> {{performer.name}}</li>
                        <li><strong>Name Event:</strong> {{ verifiedContract.bookingDetails.booking.event_name }}</li>
                        <li><strong>Date Event:</strong> {{ verifiedContract.bookingDetails.booking.event_date }}</li>
                        <li><strong>Performance Time:</strong> between {{ performer.start_time }} and {{ performer.end_time }}</li>
                        <li><strong>Location:</strong> {{ verifiedContract.bookingDetails.location.name }},
                            - {{ verifiedContract.bookingDetails.location.address }}
                            {{ verifiedContract.bookingDetails.location.postcode }} {{ verifiedContract.bookingDetails.location.city }}</li>
                        <br>
                    </ul>
                </div>
                <mat-divider></mat-divider>
                <div class="payment-details mt-3">
                    <p>For this, Contracting Party 1 pays Contracting Party 2 a non-refundable total amount of:</p>
                    <p><strong>Total amount:</strong> {{verifiedContract.bookingDetails.booking.currency_symbol}} {{bookingFinancials.financialsTotal}} excl. 9% VAT.</p>
                    <p>{{verifiedContract.contract.payment_instructions}}</p>
                </div>
                <mat-divider></mat-divider>
                <div *ngIf="verifiedContract.bookingDetails.products.length > 0" class="row mt-20">
                    <p><strong>Products:</strong><br>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th class="fw-semibold main-color fw-semibold text-start">Product</th>
                                        <th class="fw-semibold main-color fw-semibold text-start">Description</th>
                                        <th class="fw-semibold main-color fw-semibold text-start">Category</th>
                                        <th class="fw-semibold main-color fw-semibold text-start">Sub-Category</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <br>
                                    <label *ngIf="verifiedContract.bookingDetails.products.length < 1" class="d-block mb-12 fw-semibold">No product added yet</label>
                                    <tr *ngFor="let product of verifiedContract.bookingDetails.products">
                                        <td class="fw-semibold text-start">
                                            {{product.name}}
                                        </td>
                                        <td class="fw-semibold text-start">{{product.description}}</td>
                                        <td class="fw-semibold text-start">{{product.category}}</td>
                                        <td class="fw-semibold text-start">{{product.subcategory}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="technical-details mt-3">
                    <p><strong>Terms & Conditions:</strong><br>
                        {{verifiedContract.contract.terms_conditions}}</p>
                </div>
                <mat-divider></mat-divider>
                <br>
                <div class="additional-details mt-3">
                    <p><strong>Addiitonal information:</strong><br>
                        {{verifiedContract.contract.additional_information}}</p>
                </div>
                <mat-divider></mat-divider>
                <div class="signatures-section">
                    <div class="signature-container">
                        <div class="signature-box">
                            <div class="signature-header">
                                <p><strong>Signature Contracting Party 1:</strong><br>
                                    {{verifiedContract.bookingDetails.customer.name}}<br>
                                    André Santra</p>
                            </div>
                            <div class="signature-pad">
                                <canvas #signaturePadClient></canvas>
                            </div>
                            <div class="signature-actions">
                                <button mat-raised-button color="warn" (click)="clearClientSignature()">Clear</button>
                                <button mat-raised-button class="phobos" (click)="saveClientSignature()">Save</button>
                            </div>
                        </div>

                        <div class="signature-box">
                            <div class="signature-header">
                                <p><strong>Signature Contracting Party 2:</strong><br>
                                    {{ verifiedContract.agency.name }}<br>
                                    Johnny Carvalho da Cunha</p>
                            </div>
                            <div class="signature-pad">
                                <canvas #signaturePadContractor></canvas>
                            </div>
                            <div class="signature-actions">
                                <button mat-raised-button color="warn" (click)="clearContractorSignature()">Clear</button>
                                <button mat-raised-button class="phobos" (click)="saveContractorSignature()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="comment-component">
        <mat-card class="mb-25 create-project-card phobos-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">New Comment</h5>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <div class="mb-20">
                        <label class="d-block mb-12 fw-semibold">Any questions you want to submit?</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-information-line"></i>
                            <mat-label>Comment</mat-label>
                            <textarea matInput rows="8" ></textarea>
                        </mat-form-field>
                    </div>
                    <div class="btn-box">
                        <button mat-flat-button class="phobos">Submit</button>
                        <button mat-flat-button color="warn">Discard</button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>
