import {Component, Input, OnInit} from "@angular/core";
import {ContractService} from "../../../../services/contract.service";
import {
    Agency,
    Booking_Financials,
    Booking_Performer,
    BookingDetails,
    Contract,
    Performer
} from "../../../../common/model/model";
import {AgencyService} from "../../../../services/agency.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FinanceService} from "../../../../services/finance.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
    selector: 'contracts-info-component',
    templateUrl: './contracts-info.component.html',
    styleUrls: ['./contracts-info.component.scss']
})
export class ContractsInfoComponent implements OnInit {

    @Input() bookingDetails: BookingDetails;
    public bookingFinancials: Booking_Financials;
    public selectedAgency: Agency;

    public combinedPerformers: (Booking_Performer & Partial<Performer>)[] = [];
    public contractFormGroup: FormGroup;

    constructor(private contractService: ContractService,
                private agencyService: AgencyService,
                private financeService: FinanceService,
                private notificationService: NotificationService,
                private contractFormBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.agencyService.selectedAgency$.subscribe(agency => {
            this.selectedAgency = agency;
        });

        this.combinedPerformers = this.combinePerformers();
        this.bookingFinancials = this.financeService.calculateBookingFinancialTotals(this.bookingDetails, this.combinedPerformers);
        this.enableContractForm();

        this.getContract();
    }

    public enableContractForm() {
        this.contractFormGroup = this.contractFormBuilder.group({
            contractExpirationDays: ['', Validators.required],
            paymentInstructions: ['', Validators.required],
            additionalInformation: ['', Validators.required],
            termsAndConditions: ['', Validators.required]
        });
    }

    public combinePerformers(): (Booking_Performer & Partial<Performer>)[] {
        if (!this.bookingDetails || !this.bookingDetails.performers || !this.bookingDetails.performer_details) {
            return [];
        }

        return this.bookingDetails.performers.map(performer => {
            const details = this.bookingDetails.performer_details.find(detail => detail.id === performer.performer_id);
            return {
                ...performer,
                ...details
            };
        });
    }

    public getContract() {
        if(this.bookingDetails.contract) {
            this.contractFormGroup.patchValue({
                contractExpirationDays: this.bookingDetails.contract.days_to_expire,
                paymentInstructions: this.bookingDetails.contract.payment_instructions,
                additionalInformation: this.bookingDetails.contract.additional_information,
                termsAndConditions: this.bookingDetails.contract.terms_conditions
            });
        }
    }

    public saveContract() {
        if(this.bookingDetails.contract) {
            this.bookingDetails.contract.days_to_expire = this.contractFormGroup.controls['contractExpirationDays'].value;
            this.bookingDetails.contract.payment_instructions = this.contractFormGroup.controls['paymentInstructions'].value;
            this.bookingDetails.contract.additional_information = this.contractFormGroup.controls['additionalInformation'].value;
            this.bookingDetails.contract.terms_conditions = this.contractFormGroup.controls['termsAndConditions'].value;

            this.contractService.updateContract(this.bookingDetails.contract).then(contract => {
                this.notificationService.openSuccessfulNotification('Contract updated successfully');
            });
        } else {
            if(this.bookingDetails.booking.id && this.bookingDetails.booking.agency_id) {
                let newContract: Contract = {
                    booking_id: this.bookingDetails.booking.id,
                    agency_id: this.bookingDetails.booking.agency_id,
                    contract_number: '',
                    days_to_expire: this.contractFormGroup.controls['contractExpirationDays'].value,
                    payment_instructions: this.contractFormGroup.controls['paymentInstructions'].value,
                    additional_information: this.contractFormGroup.controls['additionalInformation'].value,
                    terms_conditions: this.contractFormGroup.controls['termsAndConditions'].value,
                    signature_agency: 'Signature agency',
                    signature_customer: 'Signature customer',
                    status: 'DRAFT'
                };

                this.contractService.saveContract(newContract).then(contract => {
                    this.bookingDetails.contract = contract[0] as Contract;
                    this.notificationService.openSuccessfulNotification('Contract saved successfully');
                }).catch(error => {
                    this.notificationService.openFailureNotitication(error.message);
                });
            }
        }
    }

    public generateContract() {
        if(this.bookingDetails.contract) {
            const currentDate = new Date().toISOString().replace(/[:.Z-]/g, '').slice(0, 14);
            const uniqueToken = `${currentDate}-${crypto.randomUUID()}`;

            const year = new Date().getFullYear().toString().slice(-2);
            const month = (new Date().getMonth() + 1).toString().padStart(2, '0');
            const randomPart = Math.random().toString(36).substring(2, 8).toUpperCase();
            const contractNumber = `${year}${month}${randomPart}`;

            this.bookingDetails.contract.status = 'GENERATED';
            this.bookingDetails.contract.token = uniqueToken;
            this.bookingDetails.contract.client_email = this.bookingDetails.customer.email;
            this.bookingDetails.contract.contract_generated_date = new Date();
            this.bookingDetails.contract.contract_number = contractNumber;

            console.log('contract: ' + JSON.stringify(this.bookingDetails.contract));

            this.contractService.updateContract(this.bookingDetails.contract).then(contract => {
                this.bookingDetails.contract = contract[0] as Contract;
                this.notificationService.openSuccessfulNotification('Contract generated successfully');
            });
        }
    }

    public cancelContract() {

    }
}
