import {Component, OnInit} from "@angular/core";
import {BookingService} from "../../../services/booking.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {SearchService} from "../../../services/search.service";
import {Performer_Booking_Details, BookingDetails} from "../../../common/model/model";
import {CustomizerSettingsService} from "../../../customizer-settings/customizer-settings.service";

@Component({
    selector: 'performer-bookings-component',
    templateUrl: './performer-bookings.component.html',
    styleUrls: ['./performer-bookings.component.scss']
})
export class PerformerBookingsComponent implements OnInit {

    public selectedTabIndex = 0;
    public searchSubscription: Subscription;
    public bookingPerformerDetails: BehaviorSubject<Performer_Booking_Details[]> = new BehaviorSubject<Performer_Booking_Details[]>([]);
    public originalPerformerBookingDetails: Performer_Booking_Details[] = [];

    constructor(public themeService: CustomizerSettingsService,
                private bookingService: BookingService,
                private searchService: SearchService) {
    }

    ngOnInit(): void {
        //this.testPerformerBookings();
        this.getPerformerBookings();

    }

    public onTabChange(index: number) {
        this.selectedTabIndex = index;
    }

    public getPerformerBookings() {
        this.bookingService.getBookingsForPerformer().then(bookings => {
            this.bookingPerformerDetails.next(bookings);
            this.originalPerformerBookingDetails = bookings;
        });
    }


    public testPerformerBookings() {
        this.bookingService.getBookingsForPerformer().then(bookings => {
            console.log('bookings all: ' + JSON.stringify(bookings));
        });

        this.bookingService.testHotels().then(locations => {
            console.log('hotels: ' + JSON.stringify(locations));
        });

        this.bookingService.testFlights().then(flights => {
            console.log('flights: ' + JSON.stringify(flights));
        });

        this.bookingService.testBooking().then(booking => {
            console.log('booking: ' + JSON.stringify(booking));
        });
    }
}
