import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LocationService} from "../../../services/location.service";
import {AgencyService} from "../../../services/agency.service";
import {Location} from "../../../common/model/model";
import {DomSanitizer} from "@angular/platform-browser";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'add-location',
    templateUrl: './add-location-dialog.component.html',
    styleUrls: ['./add-location-dialog.component.scss']
})
export class AddLocationDialogComponent implements OnInit {

    public addLocationFormGroup: FormGroup;
    public addLocationSubmitted = false;
    public logo: File[] = [];
    public isLoading = false;

    public existingPicture: string | null = null;
    public updateDisabled: boolean = true;

    constructor(public dialogRef: MatDialogRef<AddLocationDialogComponent>,
                private addLocationFormBuilder: FormBuilder,
                private locationService: LocationService,
                private agencyService: AgencyService,
                private notificationService: NotificationService,
                private sanitizer: DomSanitizer,
                @Inject(MAT_DIALOG_DATA) public data: Location) {
    }

    ngOnInit(): void {
        this.enableAddLocationDialogForm();

        if(this.data) {
            this.populateForm(this.data);

            if(this.data.logo) {
                this.existingPicture = this.data.logo;
                this.addLocationFormGroup.patchValue({
                    logo: this.existingPicture
                });
            }
        }
    }

    get addLocationFormGroupControls() {
        return this.addLocationFormGroup.controls;
    }

    public enableAddLocationDialogForm() {
        this.addLocationFormGroup = this.addLocationFormBuilder.group({
            name: ['', Validators.required],
            address: ['',Validators.required],
            postcode: ['',Validators.required],
            city: ['',Validators.required],
            country: ['',Validators.required],
            phone: ['',Validators.required],
            occupation: ['',Validators.required],
            website: ['',Validators.required],
            logo: ['',Validators.required],
            notes: ['',Validators.required]
        });
    }

    public populateForm(location: Location) {
        this.addLocationFormGroup.patchValue({
            name: location.name,
            address: location.address,
            postcode: location.postcode,
            city: location.city,
            country: location.country,
            phone: location.phone,
            occupation: location.occupation,
            website: location.website,
            logo: location.logo,
            agency_id: location.agency_id,
            notes: location.notes
        });
    }

    public close(){
        this.dialogRef.close(true);
    }

    public onSelect(event:any) {
        this.logo = [];
        this.existingPicture = null;

        if (event.addedFiles.length > 0) {
            this.logo.push(event.addedFiles[0]);
        }

        this.addLocationFormGroup.controls['logo'].setValue(this.logo[0]);
        this.markFormAsDirty();
    }

    public onRemove() {
        this.existingPicture = null;
        this.addLocationFormGroup.controls['logo'].setValue(null);
        this.markFormAsDirty();
    }

    private markFormAsDirty() {
        this.addLocationFormGroup.markAsDirty();
        this.updateDisabled = false;
    }

    public getSafeUrl(base64String: string) {
        return this.sanitizer.bypassSecurityTrustUrl(base64String);
    }

    public saveOrUpdateLocation() {
        if(this.data) {
            console.log('updating');
            this.updateLocation();
        } else {
            console.log('saving');
            this.saveLocation()
        }
    }

    public updateLocation() {
        this.addLocationSubmitted = true;
        if(this.addLocationFormGroup.invalid) {

        } else {
            this.isLoading = true;
            let location = this.data as Location;

            let locationName = this.addLocationFormGroup.controls['name'].value;
            let locationAddress = this.addLocationFormGroup.controls['address'].value;
            let locationPostcode = this.addLocationFormGroup.controls['postcode'].value;
            let locationCity = this.addLocationFormGroup.controls['city'].value;
            let locationCountry = this.addLocationFormGroup.controls['country'].value;
            let locationPhone = this.addLocationFormGroup.controls['phone'].value;
            let locationOccupation = this.addLocationFormGroup.controls['occupation'].value;
            let locationWebsite = this.addLocationFormGroup.controls['website'].value;
            let locationNotes = this.addLocationFormGroup.controls['notes'].value;

            const updateLocation = () => {
                location.name = locationName;
                location.address = locationAddress;
                location.postcode = locationPostcode;
                location.city = locationCity;
                location.country = locationCountry;
                location.phone = locationPhone;
                location.occupation = locationOccupation;
                location.website = locationWebsite;
                location.notes = locationNotes;

                this.locationService.updateLocation(location).then(editedLocation => {
                    this.dialogRef.close(editedLocation[0]);
                }).catch(error => {
                    this.notificationService.openFailureNotitication(error.message);
                }).finally(() => {
                    this.isLoading = false;
                });

                this.dialogRef.close(location);
            }

            if(this.logo.length > 0) {
                const reader = new FileReader();
                reader.readAsDataURL(this.logo[0]);
                reader.onload = () => {
                    if(reader.result) {
                        location.logo = reader.result.toString();
                        updateLocation();
                    }
                }
            } else if(this.existingPicture) {
                location.logo = this.existingPicture;
                updateLocation();
            } else {
                location.logo = '';
                updateLocation();
            }
        }
    }

    public saveLocation() {
        this.addLocationSubmitted = true;
        if(this.addLocationFormGroup.invalid) {
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(this.logo[0]);
            reader.onload = () => {
                if(reader.result) {
                    let location: Location = {
                        name: this.addLocationFormGroup.controls['name'].value,
                        address: this.addLocationFormGroup.controls['address'].value,
                        postcode: this.addLocationFormGroup.controls['postcode'].value,
                        city: this.addLocationFormGroup.controls['city'].value,
                        country: this.addLocationFormGroup.controls['country'].value,
                        phone: this.addLocationFormGroup.controls['phone'].value,
                        occupation: this.addLocationFormGroup.controls['occupation'].value,
                        website: this.addLocationFormGroup.controls['website'].value,
                        logo: reader.result.toString(),
                        agency_id: this.agencyService.getSelectedAgency().id,
                        notes: this.addLocationFormGroup.controls['notes'].value
                    }

                    this.locationService.addLocation(location).then(location => {
                        this.dialogRef.close(location[0]);
                    }).catch(error => {
                        //TODO handle error if it doesn't go well
                        console.error('Failed to add location:', error.message);
                    });
                }
            }
        }
    }
}

