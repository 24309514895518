import {Component, ViewChild} from "@angular/core";
import {ApexChart, ApexFill, ApexNonAxisChartSeries, ApexPlotOptions, ChartComponent} from "ng-apexcharts";
import {CustomizerSettingsService} from "../../customizer-settings/customizer-settings.service";


export type ChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    labels: string[];
    colors: any;
    plotOptions: ApexPlotOptions;
    fill: ApexFill;
};

@Component({
    selector: 'app-target-dashboard',
    templateUrl: './target-dashboard.component.html',
    styleUrls: ['./target-dashboard.component.scss']
})
export class TargetDashboardComponent {

    @ViewChild("chart") chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;

    constructor(public themeService: CustomizerSettingsService) {
        this.chartOptions = {
            series: [30],
            chart: {
                width: 120,
                height: 130,
                type: "radialBar",
                sparkline: {
                    enabled: true,
                }
            },
            colors: [
                "#0c2b3b"
            ],
            plotOptions: {
                radialBar: {
                    startAngle: -120,
                    endAngle: 120,
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: 3,
                            fontSize: "15px",
                            fontWeight: "600"
                        }
                    }
                }
            }
        };
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }
}
