import {Component, Input, OnInit} from "@angular/core";
import {Performer_Booking_Details} from "../../../../common/model/model";

@Component({
    selector: 'performer-booking-travel',
    templateUrl: './performer-booking-travel.html',
    styleUrls: ['./performer-booking-travel.scss']
})
export class PerformerBookingTravel implements OnInit {

    @Input() performerBookingDetails: Performer_Booking_Details;

    constructor() {
    }

    ngOnInit() {

    }

    public getPerformerName(performerId: string): string {
        const performer = this.performerBookingDetails.performers.find(p => p.performer_id === performerId);
        return performer ? performer.name : 'Not found';
    }
}
