<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Performers</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Performers</li>
    </ol>
</div>

<mat-card
    class="mb-25 phobos-card"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <h5 class="mb-0">All Performers</h5>
        <a mat-flat-button class="default-btn phobos" (click)="addPerformer()" *ngIf="selectedAgency">
            <i class="ri-add-fill"></i> Add Performer
        </a>
    </mat-card-header>

    <mat-card-content>
        <div class="mat-elevation-z8 phobos-mat-table">
            <table mat-table [dataSource]="dataSource">
                <!-- Order ID Column -->
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Performer ID</th>
                    <td mat-cell *matCellDef="let element" class="fw-semibold text-start">{{element.position}}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Name</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <a (click)="openPerformerDetails(element)" class="d-flex align-items-center product-info cursor-pointer">
                            <img src="assets/img/icon/performer.png" alt="performer">
                            {{element.performer.name}}
                        </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="categories">
                    <th mat-header-cell *matHeaderCellDef>Category</th>
                    <td mat-cell *matCellDef="let element">{{ getCategoryNames(element.categories) }}</td>
                </ng-container>

                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Price</th>
                    <td mat-cell *matCellDef="let element">{{element.performer.currency_symbol}}
                        {{element.performer.price}} ({{element.performer.currency_code}})</td>
                </ng-container>

                <ng-container matColumnDef="cost">
                    <th mat-header-cell *matHeaderCellDef>Cost</th>
                    <td mat-cell *matCellDef="let element">{{element.performer.currency_symbol}}
                        {{element.performer.cost}} ({{element.performer.currency_code}})</td>
                </ng-container>

                <ng-container matColumnDef="playtimes">
                    <th mat-header-cell *matHeaderCellDef>Playtimes</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.performer.playtimes?.length ? element.performer.playtimes.join(', ') : 'N/A' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element">
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                            <i class="ri-more-fill"></i>
                        </button>
                        <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-edit-line"></i> Edit
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Delete
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
            >
            </mat-paginator>
        </div>
    </mat-card-content>

</mat-card>
