import {Component, Input, OnInit} from "@angular/core";
import {Performer_Booking_Details, BookingDetails} from "../../../../common/model/model";
import {Observable, Subscription} from "rxjs";
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";
import {MatTableDataSource} from "@angular/material/table";
import {Router} from "@angular/router";
import {state} from "@angular/animations";

@Component({
    selector: 'performer-bookings-list-component',
    templateUrl: './performer-bookings-list.component.html',
    styleUrls: ['./performer-bookings-list.component.scss']
})
export class PerformerBookingsListComponent implements OnInit {

    @Input() bookingPerformerDetails: Observable<Performer_Booking_Details[]>;
    public bookingsDetailsSubscription: Subscription;

    dataSource: MatTableDataSource<Performer_Booking_Details>;
    displayedColumns: string[] = ['name', 'date', 'customer', 'location', 'status'];

    constructor(public themeService: CustomizerSettingsService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<Performer_Booking_Details>([]);
        this.bookingsDetailsSubscription = this.bookingPerformerDetails.subscribe(data => {
            this.dataSource.data = data;
        });
    }

    public openPerformerBookingDetails(performerBookingDetails: Performer_Booking_Details) {
        this.router.navigate(['performer-booking-details', performerBookingDetails.booking.id],
            {state: {performerBookingDetails: performerBookingDetails}});
    }
}
