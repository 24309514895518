<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0" *ngIf="performerBookingDetails">Booking Details: {{performerBookingDetails.booking.event_name}}</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/bookings">
                Bookings
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Booking Details</li>
    </ol>
</div>

<mat-card class="mb-25 enrolled-created-box phobos-card">
    <mat-card-content>
        <mat-tab-group (selectedIndexChange)="onTabChange($event)" mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" class="ps-0 mt-0">
            <mat-tab label="General Info"></mat-tab>
            <mat-tab label="Travel"></mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<div *ngIf="selectedTabIndex === 0">
    <performer-booking-info [performerBookingDetails]="performerBookingDetails"
    ></performer-booking-info>
</div>

<div *ngIf="selectedTabIndex === 1">
    <performer-booking-travel [performerBookingDetails]="performerBookingDetails"
    ></performer-booking-travel>
</div>
